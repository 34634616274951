import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  height: auto;
  text-align: center;
  // padding: 5rem 0;

  }
`;

export const MainWrapper = styled.div``;

export const CardContainer = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  /* justify-content: space-evenly; */
  padding: 2rem;
`;

export const Card = styled.div`
  display: flex;
  height: 300px;
  background-color: #f2fbff;
  border-radius: 3px;
  position: relative;
  margin: 0px 60px 40px;

  @media (min-width: 600px) {
    height: 160px;
    margin: 0px 30px 40px;
  }
  @media (min-width: 992px) {
    height: 250px;
    margin: 0px 50px 40px;
  }
  @media (min-width: 1200px) {
    height: 300px;
    margin: 0px 60px 40px;
  }

  ::before {
    content: '';
    position: absolute;
    bottom: -30px;
    left: 25px;
    right: 0;
    width: 30px;
    height: 30px;
    background-color: #f2fbff;
    transform: rotate(180deg);
    border-radius: 3px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }

  .message-box {
    width: 50%;
    position: relative;
  }

  .btn {
    font-size: 12px !important;
    width: 168px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #d2a614; */
    color: white;
    border-radius: 50px;
    background: #3d85c6;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.12);
  }

  .curve {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .circle-line {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .quote-start {
    position: absolute;
    top: 59px;
    left: 40px;
  }
  .quote-end {
    position: absolute;
    bottom: 125px;
    right: 20px;
  }
  .logo {
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
  .main-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0px 40px 0px;
  }

  // @media (max-width: 1310px) {
  //   display: flex;
  //   height: 503px;
  //   width: 930px;
  //   background-color: #ffffff;
  //   border: 0.5px solid #4780d1;
  //   border-radius: 20px;

  //   .main-content {
  //     display: flex;
  //     align-items: center;
  //     flex-direction: column;
  //     padding: 90px 65px 0px;
  //   }
  // }
`;

export const Model = styled.div`
  z-index: ${({ show }) => (show ? 100 : -1)};
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  top: 0rem;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* display: flex;
align-items: center;
justify-content: center; */
`;

export const ModelContainer = styled.div`
  /* background-color: red; */
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  position: relative;
  min-width: 20rem;
  width: 65%;
  /* overflow: scroll; */
  z-index: 2;
  /* min-height: 12rem; */
  height: auto;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  Button {
    margin 0 0.5rem;
    background-color: #c59e2d;
    color: white;
    font-size: 20px;
    margin-top: 1rem;
    border-radius: 30px;
    background: #d2a614;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    /* padding: 12px; */
    padding: 0.5rem 2rem;
    border-radius: 50px;
    background: #d2a614;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    @media (max-width: 800px) {
      font-size: 15px;
    }
    :hover {
      background: #d2a614;
      scale: 1.1;
      cursor: pointer;
    }
  }

  h2 {
    font-size: 1.7rem;
    margin-top: 1rem;
    color: #c59e2d;
    border-bottom: 1px solid grey;
    margin-bottom: 1rem;
  }

  form {
    width: 80%;
    font-size: 1rem;
    input {
      width: 100%;
      padding: 5px 10px;
    }
  }

  @media (max-width: 600px) {
    /* min-width: 12rem; */
    /* width: 90%; */
    /* height: 40%; */
  }

  .custom-input {
    border-radius: 5px;
    outline: none;
    padding: 11px 10px;
    margin: 5px 0px;
    font-size: 15px;
    color: #808080;
    border: 1px solid;
    font-family: 'Poppins', 'DM Sans', sans-serif;
  }
`;

// export const Div = styled.div`

//  140 145 152
//   width: 100%;
//   height: 70%;
//   background-color: red;
//   border-radius: 10px 10px 30px 30px;
//   background: url('assets/client.png');
// `;
