import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 60vh;
  background-color: #18c;
  justify-content: center;
  @media (max-width: 800px) {
    height: 40vh;
  }
  @media (max-width: 600px) {
    height: 30vh;
  }
  @media (max-width: 400px) {
    height: 20vh;
  }
`;

export const MainWrapper = styled.div`
  display: flex;
`;

export const InnerWrapper = styled.div`
  background: url('./assets/Asset-9.png') no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  width: 100%;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  align-items: center;
  h2 {
    font-size: 2rem;
    font-weight: 400;
  }

  @media (max-width: 1024px) {
    h1 {
      font-size: 2rem;
    }
    h2 {
      font-size: 1.5rem;
    }
  }
  @media (max-width: 800px) {
    h1 {
      font-size: 1.7rem;
    }
    h2 {
      font-size: 1rem;
    }
  }
  @media (max-width: 600px) {
    h1 {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 0.8rem;
    }
  }
  @media (max-width: 400px) {
    h1 {
      font-size: 1rem;
    }
    h2 {
      font-size: 0.5rem;
    }
  }
`;
