import React from 'react';
import ReactDOM from 'react-dom';
import GlobalStyle from './globalStyles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Navbar, ContactUs } from './components';
import App from './App';
import Terms from './terms';
import Privacy from './privacy';
import PageNotFound from './404-page';
import About from './about';
import Faqpage from './faqpage';
import Down from './download';
// import Footer from './container/footer2';
import LogRocket from 'logrocket';

LogRocket.init('unlock-fit/tata-unlock-fit');

let name = window.localStorage.getItem('nameId');
let email = window.localStorage.getItem('emailId');

console.log(name, email);

LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
  name: name,
  email: email
});

if (process.env.REACT_APP_ENV === 'production') {
  console.log = console.warn = console.error = () => {};
  console.clear();
} else {
  console.log('%c Development Mode', 'background: #222; color: #bada55');
}

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <Navbar />
    <Router>
      <Switch>
        <Route exact path="/" component={App} />
        <Route path="/terms" component={Terms} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/about" component={About} />
        <Route path="/contactForm" component={ContactUs} />
        {/* <Route path="/thankyou" component={Download} /> */}
        <Route path="/thankyou" component={Down} />
        <Route path="/faq" component={Faqpage} />
        <Route component={PageNotFound} />
      </Switch>
    </Router>
    {/* <Footer /> */}
  </React.StrictMode>,
  document.getElementById('root')
);
