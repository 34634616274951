/* eslint-disable */
import React, { useState, Fragment } from 'react';
import styled from 'styled-components/macro';
import Button from '../button';
import { ContactModal } from '../index';
const MenuLink = styled.a`
  padding: 1rem 2rem;
  cursor: pointer;
  text-align: center;

  text-decoration: none;
  color: #d2a614;
  font-weight: 600;
  transition: all 0.3s ease-in;
  font-size: 1.125rem;
  &:hover {
    color: #d2a614;
  }
  @media (max-width: 1024px) {
    padding: 1rem;
    font-size: 0.7rem;
  }
`;

const Nav = styled.div`
  /* padding: 1rem 0rem; */
  display: flex;
  z-index: 100;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  background: white;
  position: fixed;
  max-width: 100%;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  @media (max-width: 700px) {
    // background-color: #fff;
    // background-color: rgba(0, 0, 0, 0.5);
    /* min-width: 40%; */
    // padding: 0.7rem;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  width: 45%;
  // align-item: center;
  justify-content: flex-start;
  @media (max-width: 1200px) {
    // width: 65.5%;
  }
  @media (max-width: 1024px) {
    // width: 63%;
  }
  @media (max-width: 800px) {
    // width: 68%;
  }
  @media (max-width: 700px) {
    /* width: 85%; */
  }

  Button {
    background-color: #d2a72b;
  }
`;

const Logo = styled.a`
  display: flex;
  margin-right: 8vw;
  align-items: center;
  color: #d2a72b;
  text-decoration: none;
  font-weight: 800;
  /* font-size: 1.2rem; */

  @media (max-width: 1200px) {
    margin-right: 6vw;
  }
  @media (max-width: 1024px) {
    margin-left: -2rem;
  }
  @media (max-width: 800px) {
    margin-left: 0;
  }
  @media (max-width: 700px) {
    // margin-left: -4rem;
  }
  @media (max-width: 450px) {
    // margin-left: -2rem;
  }
  p {
    font-weight: 600;
    font-size: 1.5625rem;

    @media (max-width: 1024px) {
      font-size: 1rem;
    }
    @media (max-width: 450px) {
      font-size: 0.7rem;
    }
  }
  img {
    // padding: 0 1rem;

    @media (max-width: 1024px) {
      /* padding: 0.5rem; */
      width: 230px;
      height: 50px;
    }
    @media (max-width: 600px) {
      width: 190px;
      height: 40px;
    }
    @media (max-width: 450px) {
      width: 150px;
      height: 30px;
      // padding: 0 0.5rem;
    }
  }
`;

const Menu = styled.div`
  display: flex;
  /* width: 100%; */
  justify-content: space-between;
  align-items: center;
  position: relative;
  /* padding-left: 18rem; */
  Button {
    font-size: 1rem;
    background-color: #d2a614;
    color: white;
    font-weight: 600;
    :hover {
      background-color: #e4be02;
    }
  }
  @media (max-width: 700px) {
    overflow: hidden;
    flex-direction: column;
    max-height: ${({ isOpen }) => (isOpen ? '300px' : '0')};
    transition: max-height 0.3s ease-in;
    width: 100%;
    background-color: #ffffff;
  }
  @media (max-width: 1024px) {
    Button {
      font-size: 12px;
    }
  }
`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;

  button {
    color: white;
    background: #d2a614;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  }
  cursor: pointer;
  span {
    height: 2px;
    width: 25px;
    // background: #3139d1;
    background: #000;
    margin-bottom: 4px;
    border-radius: 5px;
  }
  @media (max-width: 700px) {
    display: flex;
  }
`;

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [colorChange, setColorchange] = useState(false);
  const [show, setShow] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 100) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener('scroll', changeNavbarColor);
  return (
    <Fragment>
      <Nav className={colorChange ? 'navbar colorChange' : 'navbar'}>
        <LogoWrapper>
          <Logo href="/">
            <img
              src="./assets/Tata comm-unlock fit.png"
              width="300"
              height="65"
              alt="unlock.fit"
              href="/"
            />
          </Logo>
        </LogoWrapper>
        <Hamburger>
          <Button
            onClick={() => {
              setShow(true);
            }}
            style={{
              background: '#0D85C3',
              padding: '10px 30px',
              borderRadius: 5,
              color: '#fff',
              cursor: 'pointer'
            }}>
            Enquire Now
          </Button>
        </Hamburger>
        <Menu isOpen={isOpen}>
          <MenuLink href="./#download">App</MenuLink>
          <MenuLink href="./#product">Plans</MenuLink>
          <MenuLink href="./about">About</MenuLink>
          <div
            onClick={() => {
              setShow(true);
            }}
            style={{
              background: '#0D85C3',
              padding: '10px 30px',
              borderRadius: 5,
              fontWeight: 600,
              color: '#fff',
              cursor: 'pointer'
            }}>
            Enquire Now
          </div>
          {/* <MenuLink href="">Contact</MenuLink> */}
        </Menu>
      </Nav>
      {show ? <ContactModal show={true} setModalShow={setShow} /> : null}
    </Fragment>
  );
};

export default Navbar;
