import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #19d;
  color: white;
`;

export const WrapperContainer = styled.div`
  max-width: 80%;
  margin: auto;
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column-reverse;
    max-width: 95%;
    align-items: center;
  }
`;

export const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  a {
    text-decoration: none;
    color: white;
  }
  p {
    color: white;
    font-size: 1.1rem;
    margin: 2px;
    padding: 5px;
    /* border-right: 1px solid white; */
    word-spacing: 3px;
    @media (max-width: 800px) {
      /* padding: 0;
      margin: 1px; */
      font-size: 0.8rem;
    }
  }
`;

export const RightContainer = styled.div`
  flex: 1;

  p {
    color: white;
    font-size: 1.1rem;
    @media (max-width: 800px) {
      padding: 0;
      font-size: 0.8rem;
    }
  }
  text-align: center;
`;

export const TechPartner = styled.div`
  width: 100%;
  margin: auto;
  background-color: black;
  p {
    color: white;
    font-size: 11px;
    text-align: center;
  }

  a {
    text-decoration: none;
    color: red;
  }
`;
