/* eslint-disable */

import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, Redirect, useHistory } from 'react-router-dom';
import {
  Wrapper,
  InnerWrapper,
  Card,
  // ButtonWrapper,
  Form,
  Input,
  // Input1,
  Input2,
  // Heading,
  // Button
  Side,
  Textarea
} from './styles';
// import { useRouter } from 'next/router';
// import { CircularProgress } from '@material-ui/core';

const initialstate = {
  name: localStorage.getItem('nameId') !== 'null' ? localStorage.getItem('nameId') : '',
  full_name: localStorage.getItem('nameId') !== 'null' ? localStorage.getItem('nameId') : '',
  phone: localStorage.getItem('phone') !== 'null' ? localStorage.getItem('phone') : '',
  email: localStorage.getItem('emailId') !== 'null' ? localStorage.getItem('emailId') : '',
  address_name: '',
  state: '',
  city: '',
  pincode: '',
  billing_address: '',
  billing_state: '',
  billing_city: '',
  billing_pincode: '',
  line_A: '',
  line_B: '',
  landmark: '',
  billing_line_A: '',
  billing_line_B: '',
  billing_country: '',
  country: '',
  billing_landmark: '',

  user_id: 'e038d28d-70ff-4db0-8500-08bd7f1489b0'
};
var Promise = require('promise');

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
const ContactUs = () => {
  let router = useLocation().state;
  const history = useHistory();
  const [state, setState] = useState(initialstate);
  const [show] = useState(true);
  const [checked, setChecked] = useState(true);
  // const router = useRouter();
  const [loading, setLoading] = useState(false);

  const handleInput = (e) => {
    const inputName = e.currentTarget.name;
    const value = e.currentTarget.value;

    setState((prev) => ({ ...prev, [inputName]: value }));
  };

  const click = () => {
    setChecked(!checked);
    state.billing_address = state.permanent_address;
    state.billing_state = state.permanent_state;
    state.billing_city = state.permanent_city;
    state.billing_pincode = state.permanent_post_code;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log('submitted!');
    console.log(state);
    state.user_id = localStorage.getItem('userId');
    state.email = localStorage.getItem('emailId');
    state.phone = localStorage.getItem('phone');
    if (checked) {
      state.billing_address = state.permanent_address;
      state.billing_state = state.permanent_state;
      state.billing_city = state.permanent_city;
      state.billing_pincode = state.permanent_post_code;
    }
    (state.address_name = state.permanent_address),
      (state.pincode = state.permanent_post_code),
      (state.city = state.permanent_city),
      (state.state = state.permanent_state),
      (state.name = state.full_name);
    state.billing_country = state.country;
    state.billing_landmark = state.landmark;
    state.line_A = state.permanent_address;
    state.billing_line_A = state.line_A;
    state.billing_line_B = state.line_B;
    axios
      .post(`${process.env.REACT_APP_BASEURL}tata/website/user/saveAddress`, state)
      .then((res) => {
        console.log('UserData: ', res.status);
        if (res.status === 200) {
          history.push('/thankyou');
        } else {
          alert(res?.data?.message ? res?.data?.message : 'Something went wrong');
          history.push('/thankyou');
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  async function displayRazorpay() {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }
    // const data = { product_id: '611aa02157d4e80016577518' };
    // const result = await axios.post(
    //   `https://adamgenetics.herokuapp.com/transactions/razorpay-payment`,
    //   data
    // );
    // .then((res) => res.json());

    console.log('razorpay');
    // console.log(result);
    // console.log(result.data.data.);
    // question
    const options = {
      // key: process.env.React_APP_API_KEY,
      // amount: result.data.data.amount.toString(),
      // order_id: 'sd',
      // phone: result.data.phone,

      // image: 'http://localhost:2337/logo.svg',
      handler: async function (response) {
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
        // alert('Successfully Paid ');
        history.push('/thankyou');

        // alert(this.phone);
        console.log(response);
        try {
          console.log('hello');
          const data = {
            // product_id: '611aa02157d4e80016577518',
            user_id: state.user_id
          };
          console.log('2nd result');
          console.log(data, 'datatest');
          axios
            .post(`${process.env.REACT_APP_BASEURL}tata/website/user/buySubcriptionPlan`, {
              userId: state.user_id,
              token: otp
            })
            .then((val) => {
              console.log('successfully purchased', val);
            });
          // const result = await axios.post(
          //   `https://adamgenetics.herokuapp.com/transactions/new`,
          //   data
          // );
          // console.log(result);
          // const paymentId = response.razorpay_payment_id;
          // const captureURL = `http://localhost:2337/capture/${paymentId}`;
          // const captureResponse = await Axios.post(captureURL, { amount: totalPrice });
          // eslint-disable-next-line no-unused-vars
          // const captureResponse = await fetch(captureURL + '?value=' + value, {
          //   method: 'POST'
          // }).then((t) => t.json());
        } catch (err) {
          console.log(err);
        }
      },
      prefill: {
        name
      }
    };
    console.log(options.product_id);

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    console.log(paymentObject);
  }

  // if (!router || !router.product_id || !router.quantity) {
  //   return <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>Page not found</div>;
  // }

  console.log(router);

  return (
    <Wrapper bg={checked ? 'up' : ''}>
      <InnerWrapper>
        <Card>
          <Form onSubmit={handleSubmit}>
            <h1>User Details</h1>
            <label htmlFor="name">What should we call you?</label>
            <Input
              type="text"
              name="full_name"
              onChange={handleInput}
              value={show ? state.full_name : ''}
              placeholder="Full Name"
              required
            />
            <label htmlFor="number">Your Email</label>
            <Input
              type="email"
              name="email"
              onChange={handleInput}
              value={show ? state.email : ''}
              placeholder="Email"
              pattern="[0-9]{10}"
              maxlength="10"
              required
              disabled={state.email === '' ? false : true}
            />
            <label htmlFor="number">Your phone number</label>
            <Input
              type="tel"
              name="phone"
              onChange={handleInput}
              value={show ? state.phone : ''}
              placeholder="Phone Number (10 digit)"
              pattern="[0-9]{10}"
              maxlength="10"
              required
              disabled={state.phone === '' ? false : true}
            />
            {/* <label htmlFor="email">Enter your email</label>
            <Input
              type="email"
              name="email"
              onChange={handleInput}
              value={show ? state.email : ''}
              placeholder="John@mail.com"
              required
            /> */}
            {/* <label htmlFor="name">Your Address</label>
            <Textarea
              type="text"
              name="permanent_address"
              onChange={handleInput}
              value={show ? state.permanent_address : ''}
              placeholder="Line A"
              rows="5"
              cols="5"
              required
            />
             <Textarea
              type="text"
              name="line_B"
              onChange={handleInput}
              value={show ? state.line_B : ''}
              placeholder="Line B"
              rows="5"
              cols="5"
              required
            /> */}
            {/* <Input1
                  type="text"
                  name="permanent_address"
                  onChange={handleInput}
                  value={show ? state.permanent_address : ''}
                  placeholder="Address"
                  rows="20"
                  required
                /> */}
            {/* <label htmlFor="pincode"> Pincode</label>
            <Input
              type="pin number"
              name="permanent_post_code"
              onChange={handleInput}
              pattern="[0-9]{6}"
              // pattern="^[1-9][0-9]{5}$"
              maxlength="6"
              minLength="6"
              value={show ? state.permanent_post_code : ''}
              placeholder="Pincode"
              required
            />
            <label htmlFor="city">
              City
              <Input2
                type="text"
                name="permanent_city"
                onChange={handleInput}
                value={show ? state.permanent_city : ''}
                placeholder="City"
                required
              />
            </label>
             <label htmlFor="city">
              Landmark
              <Input2
                type="text"
                name="landmark"
                onChange={handleInput}
                value={show ? state.landmark : ''}
                placeholder="Landmark"
                required
              />
            </label>
            <label htmlFor="city">
              Country
              <Input2
                type="text"
                name="country"
                onChange={handleInput}
                value={show ? state.country : ''}
                placeholder="Country"
                required
              />
            </label>
            <label htmlFor="state">
              State
              <Input
                type="text"
                name="permanent_state"
                onChange={handleInput}
                value={show ? state.permanent_state : ''}
                placeholder="State"
                required
              />
            </label>

            <input
              type="checkbox"
              name="check"
              value="terms"
              // onClick={clicked}
              // checked
              // required
              defaultChecked={checked}
              // checked="checked"
              // onChange={() => setChecked(!checked)}
              onChange={click}
            />
            <label htmlFor="billing">Billing Address same as above Address</label>
            <br />
            <br />
            {checked ? null : (
              <>
                <label htmlFor="name">Billing Address</label>
                <Textarea
                  type="text"
                  name="billing_address"
                  onChange={handleInput}
                  value={show ? state.billing_address : ''}
                  placeholder="Billing Address"
                  rows="5"
                  cols="5"
                  required
                />

                <label htmlFor="name"> Postcode</label>

                <Input
                  type="pin number"
                  name="billing_post_code"
                  onChange={handleInput}
                  pattern="[0-9]{6}"
                  maxlength="6"
                  value={show ? state.billing_post_code : ''}
                  placeholder="Pincode"
                  required
                />

                <label htmlFor="city">
                  City
                  <Input2
                    type="name"
                    name="billing_city"
                    onChange={handleInput}
                    value={show ? state.billing_city : ''}
                    placeholder="City"
                    required
                  />
                </label>

                <label htmlFor="postcode">
                  State
                  <Input
                    type="text"
                    name="billing_state"
                    onChange={handleInput}
                    value={show ? state.billing_state : ''}
                    placeholder="State"
                    required
                  />
                </label>
              </>
            )} */}

            {!loading && <button>Confirm Purchase</button>}
          </Form>
        </Card>
      </InnerWrapper>
    </Wrapper>
  );
};

export default ContactUs;
