import { React, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
  Div,
  Wrapper,
  LeftWrapper,
  RightWrapper,
  Image,
  ImageContainer,
  ImageContainer1
} from './styles';
// import { Link } from 'react-router-dom';
// import { Button } from '../../components';
const Section7 = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <Div>
      <Wrapper>
        <LeftWrapper>
          <h1>unlock wellness in your pocket</h1>
          <br />
          <br />
          <h3>Get the App Now</h3>
          <br />
          <Image data-aos="fade-up">
            <a
              href="https://apps.apple.com/in/app/unlock-fit-dna-based-wellness/id1573454926"
              target="_blank"
              rel="noopener noreferrer">
              <img src="/assets/App_store.png" alt="logo" width="160" height="50" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.unlock.fit"
              target="_blank"
              rel="noopener noreferrer">
              <img src="/assets/play_store.png" alt="logo" width="160" height="50" />
            </a>
          </Image>
        </LeftWrapper>
        <RightWrapper>
          <ImageContainer>
            <img src="/assets/phone1.png" alt="phone" />
          </ImageContainer>
          <ImageContainer1>
            <img src="/assets/phone2.png" alt="phone" />
          </ImageContainer1>
        </RightWrapper>
      </Wrapper>
    </Div>
  );
};

export default Section7;
