/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { Content, Logo, Model, ModelContainer } from './styles';
import { Button } from '../../components';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import axios from 'axios';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Tooltip } from '@mui/material';
import ReactLoading from 'react-loading';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import { CountryCode } from './CountyCode';
import LinearProgress from '@mui/material/LinearProgress';
import './style.css';

const Modal = ({ ...restProps }) => {
  const idOfDNA = restProps.productList.find((obj) => obj.package_title === 'DNA Pro');
  const idOfMe = restProps.productList.find(
    (obj) => obj.package_title.toLowerCase() === 'unlock me'
  );
  const [show, setShow] = useState(restProps.show);
  const [familyModal, setFamilyModal] = useState(false);
  const [SuccessMsg, setSuccessMsg] = useState('');
  const [WarnMsg, setWarnMsg] = useState('');
  const [otpcheck, setOtpcheck] = useState(false);
  const [PrimaryPhoneValid, setPrimaryPhoneValid] = useState(false);
  const [AlredaySubscribed, setAlredaySubscribed] = useState(false);
  const [Purchase, setPurchase] = useState({ planid: '', duration: '', phone: '' });
  const [PlanPurchase, setPlanPurchase] = useState({
    planName: '',
    duration: null,
    planPrice: null
  });

  const showFamily = false;
  const [token, setToken] = useState('');

  const [dep, setDep] = useState([]);
  const [email, setEmail] = useState(localStorage.getItem('emailId'));
  const [name, setName] = useState(localStorage.getItem('nameId'));

  // const [email, setEmail] = useState(localStorage.getItem('emailId'));
  // const [name, setName] = useState(localStorage.getItem('nameId'));
  // const [email, setEmail] = useState('');

  const [emailCheck, setEmailCheck] = useState(restProps.emailCheck);
  const [id, setuserId] = useState('');
  const [otp, setOtp] = useState('');
  const history = useHistory();
  const [familyArr, setFamilyArr] = useState([]);
  const [GrandTotal, setGrandTotal] = useState();
  const [Selected, setSelected] = useState(false);
  const [arr, setArr] = useState([]);
  const [durationId, setDurationId] = useState(restProps.durationId);
  const [duration, setDuration] = useState(restProps.duration);
  const [userPrice, setUserPrice] = useState(restProps.price);
  const [trigger, setTrigger] = useState(1);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [ShowError, setShowError] = useState(false);
  const [RazorError, setRazorError] = useState(false);
  const [RedeemSuccess, setRedeemSuccess] = useState(false);
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [PhoneError, setPhoneError] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState({ code: '+91', name: 'India' });

  const [showTermsAndCondition, setShowTermsAndCondition] = useState(false);
  const [acceptTermsAndCondition, setAcceptTermsAndCondition] = useState(false);
  const [_enteredMobileno, setEnteredMobileNo] = useState();
  const [emiDuration, setEmiDuration] = useState(-1);
  const [IsThisIndex, setIsThisIndex] = useState(null);
  const [Count, setCount] = useState(0);
  const [TriggeredByApi, setTriggeredByApi] = useState(false);
  const [Loader, setLoader] = useState(0);

  const [userStatus, setUserStatus] = useState(false);

  useEffect(() => {
    console.log('show', show);
  }, [show]);

  // const enableConfirmButton = useMemo(() => {
  //   if (!acceptTermsAndCondition || !_enteredMobileno || !userStatus) {
  //     return false;
  //   }
  //   if (_enteredMobileno && _enteredMobileno.length !== 10) {
  //     return false;
  //   }
  //   return true;
  // }, [acceptTermsAndCondition, _enteredMobileno, userStatus]);

  const Alert = React.forwardRef((props, ref) => (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  ));

  const [product, setProduct] = useState([
    {
      package_id: 18,
      package_title: 'unlock Life',
      standard_price: '77900',
      pricewithgst: '29450',
      remarks: '2',
      pricewithgst: 34751
    },
    {
      package_id: 19,
      package_title: 'unlock Health',
      standard_price: '49500',
      pricewithgst: '22500',
      remarks: '2',
      pricewithgst: 26550
    },
    {
      package_id: 20,
      package_title: 'unlock DNA',
      standard_price: '25000',
      pricewithgst: '12850',
      remarks: '2',
      pricewithgst: 15163
    },
    {
      package_id: 21,
      package_title: 'unlock 21',
      standard_price: '9500',
      pricewithgst: '5440',
      remarks: '2',
      pricewithgst: 6419
    }
  ]);

  // console.log("React_APP_Dependents",process.env.REACT_APP_Dependents/employee-token);

  const [planPurchased, setPlanPurchased] = useState('');
  const [includeUser, setIncludeUser] = useState(true);

  const [toastMessage, setToastMessage] = useState('');
  const [toastSeverity, setToastSeverity] = useState('success');

  const success = (message) => {
    setToastSeverity('success');
    setToastMessage(message);
    setOpen(true);
  };

  const CloseSucess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const errorToast = (message) => {
    setToastSeverity('error');
    setToastMessage(message);
    setOpen(true);
  };

  var totalCnt = 0;
  var totalAmmount = 0;
  useEffect(() => {
    setEmail(localStorage.getItem('emailId'));
  }, []);

  useEffect(() => {
    if (email === null || email === 'null') restProps.setModalShow(false);
  }, [email]);
  // Use Effect To Call Plan List

  useEffect(() => {
    const cnt = localStorage.getItem('noOfPeople');

    for (var i = 0; i < cnt - 2; i++) {
      const value = localStorage.getItem(`family${i + 1}`);

      setArr((prev) => [
        ...prev,
        {
          name: value,
          email: '',
          plan_id: '',
          select: true
        }
      ]);
    }
  }, []);

  // useEffect(async () => {
  //   setFamilyArr([]);
  //   axios
  //     .get(`${process.env.REACT_APP_BASEURL}tata/website/user/nList`, {
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     })
  //     .then((res) => {
  //       console.log('product', res.data.result);
  //       setProduct(res.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    let progress = 0;
    const interval = setInterval(() => {
      progress += 20;
      setLoader(progress);
      if (progress >= 100) {
        restProps.setLoading(false);
        restProps.Loading.current = false;
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [setLoader]);

  useEffect(() => {
    const getFamilyMembers = async () => {
      const members = await axios.post(
        `${process.env.REACT_APP_BASEURL}tata/website/user/v1/getFamilyMembers`,
        {
          // const members = await axios.post(`http://localhost:4002/tata/website/user/getFamilyMembers`, {
          email_id: email
        }
      );
      const newfamilyArr = familyArr;
      const dataForPrimary = members.data.data.find(
        (member) => member.relationship === 'Primary User'
      );
      if (dataForPrimary?.relationship === 'Primary User') {
        setPhoneNumber(dataForPrimary.phone);
        setPrimaryPhoneValid(true);
        const selectedCode = CountryCode.find((item) => item.code === dataForPrimary.country_code);
        if (dataForPrimary) {
          setIncludeUser(dataForPrimary.plan_id ? true : false);
        }
        if (selectedCode) {
          setSelectedCountryCode(selectedCode);
        }
        if (dataForPrimary.has_subscribed) {
          setPrimaryPhoneValid(true);
          const nameofPlan = dataForPrimary.plan_name
            ? dataForPrimary.plan_name
            : restProps.productList
                .filter((dur) => dur.package_id === dataForPrimary.plan_id)
                .map((dur) => dur.package_title);

          setPlanPurchase({
            planName: nameofPlan,
            duration: dataForPrimary.duration_id,
            planPrice: dataForPrimary.plan_price
          });
        }
        if (
          restProps.product === idOfMe?.package_id &&
          restProps.IsValid &&
          (!dataForPrimary || dataForPrimary.plan_id === null)
        ) {
          restProps.setOpenMessage(true);
        }
        if (dataForPrimary && dataForPrimary.can_redeem_offer) {
          restProps.setOpenMessage(true);
          setTriggeredByApi(true);
        }
        setAlredaySubscribed(dataForPrimary.has_subscribed);
        setPurchase({
          planid: dataForPrimary.plan_id ? dataForPrimary.plan_id : '',
          duration: dataForPrimary.duration_id ? dataForPrimary.duration_id : '',
          phone: dataForPrimary.phone
        });
        setuserId(dataForPrimary.id);
        localStorage.setItem('userId', dataForPrimary.id);
        localStorage.setItem('phone', dataForPrimary.country_code + dataForPrimary.phone);
        setEmailCheck(false);
        // verifyOtp(dataForPrimary.id);
      }
      const hasMemberWithPlan258 = members.data.data.some(
        (check) => check.plan_id === idOfDNA.package_id
      );

      members.data.data.map((data) => {
        const selectedCode = CountryCode.find((item) => item.code === data.country_code);
        if (data.relationship !== 'Primary User') {
          newfamilyArr.push({
            name: data.name,
            email: data.email,
            isValidMail: data.email ? true : false,
            isValidPhone: data.phone ? true : false,
            plan: data.plan_name ? data.plan_name : '',
            plan_id: data.plan_id ? data.plan_id : undefined,
            select: !data.plan_id ? true : false,
            duration_id: data.duration_id ? `${data.duration_id}` : undefined,
            relation: data.relationship ? data.relationship : '',
            planPurchased: data.plan_id ? true : false,
            planPurchasedMessage: 'Plan Already Purchased',
            plan_price: data.plan_price ? data.plan_price : '0',
            phone: data.phone ? data.phone : '',
            countrycode: selectedCode
          });
        }
      });

      const lastIndexWithPlan167 = newfamilyArr.reduceRight((acc, obj, index) => {
        if (obj.plan_id === idOfMe.package_id && acc === -1) {
          return index;
        }
        return acc;
      }, -1);
      setIsThisIndex(lastIndexWithPlan167);
      setCount(dataForPrimary ? dataForPrimary.redeem_count : 0);
      setFamilyArr(newfamilyArr);
      setTrigger(trigger + 1);
    };
    getFamilyMembers();
  }, [email]);

  useEffect(() => {
    const Depndrr = [];

    axios
      .get(`${process.env.REACT_APP_Dependents}get/token-employee-details`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${restProps.familyToken}`
        }
      })
      .then((res) => {
        setDep(res.data.data[0]);
        res.data.data[0].map((data) => {
          Depndrr.push({
            name: data.first_name,
            email: data.email,
            plan_id: '',
            select: 'false'
          });
        });
        Depndrr.shift();
        // setArr(Depndrr);
        setFamilyArr(Depndrr);
      })
      .catch((err) => {
        console.log(err);
      });

    // dep.shift();
    // dep.map((item, index) => {
    //   Depndrr.push({
    //     name: item.first_name + '' + item.last_name, email: item.email, plan: '', select: 'false'
    //   });
    // });

    const cnt = localStorage.getItem('noOfPeople');
    for (var i = 0; i < cnt - 2; i++) {
      const value = localStorage.getItem(`family${i + 1}`);
      setArr((prev) => [
        ...prev,
        {
          name: value,
          email: '',
          plan_id: '',
          select: true
        }
      ]);
    }
  }, []);

  useEffect(() => {
    arr.map((value) => {
      setFamilyArr((prev) => [...prev, { name: value.name, email: '', plan: '', select: true }]);
    });
  }, [arr]);

  useEffect(() => {
    console.log('Family Arr', FamilyArrFormated());
  }, [familyArr]);

  var Promise = require('promise');

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const payment = () => {
    displayRazorpay();
  };

  const checkUser = async (phoneNumber) => {
    try {
      const isMobileAvailable = await axios.get(
        `${process.env.REACT_APP_BASEURL}tata/website/user/checkUser?phone=${phoneNumber}&email=${email}`
      );

      if (isMobileAvailable.status !== 200) {
        throw 'Something went wrong';
      } else {
        setUserStatus(true);
      }
    } catch (err) {
      setUserStatus(false);
      if (err.response.status === 400) {
        errorToast(
          'This number is already registered with us.Please provide alternative mobile number.'
        );
        return;
      }
    }
  };

  const buyThroughEMI = async () => {
    try {
      if (!PhoneNumber) {
        errorToast('Please Enter Mobile Number');
        return;
      }

      if (!acceptTermsAndCondition) {
        errorToast('Please accept terms and conditions');
        return;
      }

      if (PhoneNumber.length !== 10) {
        errorToast('Please enter valid mobile number');
        return;
      }

      if (!userStatus) {
        errorToast(
          'This number is already registered with us.Please provide alternative mobile number.'
        );
        return;
      }

      // if (emiDuration === -1) {
      //   errorToast('Please select duration');
      //   return;
      // }
      setShowTermsAndCondition(false);
      setLoading(true);
      // console.log('emiDuration', {
      //   plan_id: restProps.product,
      //   plan_name: restProps.planName,
      //   plan_price: restProps.price,
      //   user_id: id,
      //   duration_id: durationId,
      //   duration: duration,
      //   organization: 'Tata Communications',
      //   emi_duration: emiDuration + ' Months',
      //   consent: acceptTermsAndCondition ? 'Yes' : 'No'
      // });
      const result = await axios.post(
        `${process.env.REACT_APP_BASEURL}tata/website/user/buySubcriptionPlanThroughEMI`,
        // `${process.env.REACT_APP_BASEURL}tata/website/user/buySubcriptionPlanThroughEMI`,
        // .post(`http://localhost:4002/tata/website/user/buySubcriptionPlanThroughEMI`,
        {
          plan_id: restProps.product,
          plan_name: restProps.planName,
          plan_price: restProps.price,
          user_id: id,
          duration_id: durationId,
          duration: duration,
          organization: 'Tata Communications',
          emi_duration: emiDuration + ' Months',
          consent: acceptTermsAndCondition ? 'Yes' : 'No',
          phone: PhoneNumber
        }
      );
      if (result.data.result === 'success') {
        success(result.data.message);
        setTimeout(() => {
          setLoading(false);
          history.push('/thankyou');
          window.location.reload();
        }, 2000);
      } else {
        errorToast(result.data.message);
        setLoading(false);
      }
    } catch (err) {
      // debugger;
      setLoading(false);
      errorToast('Something went wrong');
    }
  };

  async function displayRazorpay() {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
    if (!res) {
      setRazorError(true);
      return;
    }
    restProps.setModalShow(false);

    const isDevTcUnlockFit = window.location.href.includes('dev-tc.unlock.fit');
    const options = {
      key: process.env.REACT_APP_KEY,
      currency: 'INR',

      amount: !isDevTcUnlockFit ? totalAmmount * 100 : 100,

      handler: async function (response) {
        // alert(this.phone);
        console.log(response);
        try {
          const data = {
            user_id: id
          };
          // console.log('fam', {
          //   plan_id: restProps.product,
          //   include_primary_user: includeUser,
          //   userId: id,
          //   token: otp,
          //   duration_id: restProps.durationId,
          //   plan_price: restProps.userPrice,
          //   orderId: response.razorpay_payment_id,
          //   allpurchase: totalCnt ? 1 : 0,
          //   family: FamilyArrFormated()
          // });
          axios
            .post(`${process.env.REACT_APP_BASEURL}tata/website/user/v1/buySubcriptionPlan2`, {
              // .post(`http://localhost:4002/tata/website/user/buySubcriptionPlan2`, {
              plan_id: restProps.product,
              include_primary_user: includeUser,
              userId: id,
              token: otp,
              duration_id: restProps.durationId,
              plan_price: restProps.userPrice,
              orderId: response.razorpay_payment_id,
              is_reedem_plan: false,
              allpurchase: totalCnt ? 1 : 0,
              family: FamilyArrFormated()
            })
            .then((val) => {
              console.log('successfully purchased', val);
            });
          restProps.setSuccess(true);
        } catch (err) {
          console.log(err);
          errorToast('Something went wrong');
        }
      },
      prefill: {
        name,
        email
      }
    };
    console.log(options.product_id);

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    console.log(paymentObject);
  }

  let called = false;

  const redeemOffer = () => {
    if (called) {
      return;
    }
    called = true;
    axios
      .post(`${process.env.REACT_APP_BASEURL}tata/website/user/v1/buySubcriptionPlan2`, {
        plan_id: restProps.product,
        include_primary_user: includeUser,
        userId: id,
        token: otp,
        duration_id: restProps.durationId,
        plan_price: restProps.userPrice,
        orderId: 'TC DNA Pro Offer',
        is_reedem_plan: true,
        allpurchase: totalCnt ? 1 : 0,
        family: FamilyArrFormated()
      })
      .then((val) => {
        setRedeemSuccess(true);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        called = false;
      });
  };

  const checkPhone = (phone) => {
    axios
      .post(`${process.env.REACT_APP_BASEURL}tata/website/user/verify_phone`, {
        phone: String(phone),
        email: email,
        country_code: selectedCountryCode?.code
      })
      .then((response) => {
        const data = response.data;
        if (data.is_valid === false) {
          setPrimaryPhoneValid(false);
          setWarnMsg(data.message);
        } else {
          localStorage.setItem('phone', selectedCountryCode?.code + phone);
          setPrimaryPhoneValid(true);
          setSuccessMsg(data.message);
          registerNewUser(phone);
        }
      })
      .catch((error) => {
        const data = error.response.data;
        setPrimaryPhoneValid(false);
        setWarnMsg(data.message);
      });
  };

  const registerNewUser = (phone) => {
    axios
      .post(`${process.env.REACT_APP_BASEURL}tata/website/user/v1/register`, {
        email: email,
        country_code: selectedCountryCode?.code,
        phone: String(phone)
      })
      .then((response) => {
        const data = response.data;
        if (data.result === 'success') {
          setEmailCheck(false);
          setuserId(data.data.userId ? data.data.userId : data.userId);
          localStorage.setItem('userId', data.data.userId ? data.data.userId : data.userId);
          // verifyOtp(data.data.userId);
        } else if (data.message === 'Email is already registered and verified.') {
          console.log('verified');
        } else {
          // restProps.setModalShow(false);
        }
      })
      .catch((err) => {
        console.log('err', err);
        // restProps.setModalShow(false);
      });
  };

  const verifyOtp = (userId) => {
    axios
      .post(`${process.env.REACT_APP_BASEURL}tata/website/user/verify_user`, {
        userId: userId,
        token: '101010'
      })
      .then(async (data) => {
        if (data.data.result == 'failure') {
          alert('Invalid OTP');
        } else {
          await axios
            .get(
              `${process.env.REACT_APP_BASEURL}tata/website/user/getUserSubcriptionPlan?userId=${userId}`
            )
            .then((emailvalue) => {
              if (emailvalue?.data?.data?.length >= 1) {
                setPlanPurchased('plan already purchased');
                setIncludeUser(false);
              } else {
                setFamilyModal((prev) => true);
                setOtpcheck(true);
              }
            })
            .catch((err) => {
              alert('Error');
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        // setShow(false);
        // restProps.setModalShow(false);
      })
      .finally(() => {
        restProps.setLoading(false);
        restProps.Loading.current = true;
      });
  };

  console.log('famse', id);

  const submitFamily = (e) => {
    var tot = 0;
    familyArr.map((arr) => {
      if (arr.select && arr.relation !== 'Primary User') {
        if (arr.plan_price) tot += Number(arr.plan_price);
      }
    });

    if (includeUser) tot += Number(userPrice);
    const fArr = familyArr.filter((arr) => arr.select == true);
    setFamilyArr(fArr);
    FamilyArrFormated();
    totalAmmount = tot;
    localStorage.setItem('FamilyPurchase', JSON.stringify(fArr));
    payment();
    const fadata = localStorage.getItem('FamilyPurchase');
  };

  useEffect(() => {
    let tot = 0;
    familyArr.map((arr) => {
      if (arr.select && arr.relation !== 'Primary User') {
        if (arr.plan_price) tot += Number(arr.plan_price);
      }
    });

    if (includeUser) tot += Number(userPrice);

    setGrandTotal(tot);
  }, [includeUser, familyArr]);

  const selectedFamilyCount = () => {
    let _cnt = 0;
    familyArr.forEach((arr) => {
      if (arr.select && arr.relation !== 'Primary User') {
        _cnt++;
      }
    });
    return _cnt;
  };

  const checkFilled = () => {
    let _familyArr = familyArr;
    let _flag = true;
    if (!PrimaryPhoneValid) {
      _flag = false;
    }
    _familyArr.forEach((arr) => {
      if (arr.select) {
        if (
          !arr.name ||
          !arr.email ||
          !arr.plan_id ||
          !arr.relation ||
          arr.relation === '-1' ||
          arr.plan_id === '-1' ||
          arr.isValidMail === false
        ) {
          _flag = false;
        }
        if (arr.relation !== 'Child' && (arr.isValidPhone === false || !arr.phone)) {
          _flag = false;
        }
        if (hasDuration(arr.plan_id) && (!arr.duration_id || arr.duration_id === '-1')) {
          _flag = false;
        }
      }
    });
    return _flag;
  };

  const checkFilledUpdate = () => {
    if (PrimaryPhoneValid === false) {
      setPhoneError(true);
      setShowError(true);
    }
    setFamilyArr((prevFamilyArr) => {
      return prevFamilyArr.map((person) => {
        let updatedPerson = { ...person };
        if (person.select) {
          if (!person.name) {
            updatedPerson.borderForName = true;
          }
          if (!person.email || !person.isValidMail) {
            updatedPerson.borderForEmail = true;
          }
          if (!person.phone || person.isValidPhone === false) {
            updatedPerson.borderForPhone = true;
          }
          if (!person.relation || person.relation === '-1') {
            updatedPerson.borderForRelation = true;
          }
          if (!person.plan_id || person.plan_id === '-1') {
            updatedPerson.borderForPlan = true;
          }
          if (hasDuration(person.plan_id) && (!person.duration_id || person.duration_id === '-1')) {
            updatedPerson.borderForDuration = true;
          }
          if (
            !person.name ||
            !person.email ||
            !person.isValidMail ||
            !person.phone ||
            person.isValidPhone === false ||
            !person.relation ||
            person.relation === '-1' ||
            !person.plan_id ||
            person.plan_id === '-1' ||
            (hasDuration(person.plan_id) && (!person.duration_id || person.duration_id === '-1'))
          ) {
            setShowError(true);
          }
        }
        return updatedPerson;
      });
    });
  };

  const FamilyArrFormated = () => {
    let _familyArr = [];
    familyArr.forEach((arr) => {
      if (arr.select && arr.relation !== 'Primary User') {
        if (arr.duration_id) {
          let formattedArr = {
            name: arr.name,
            email: arr.email,
            plan_id: arr.plan_id,
            plan_price: arr.plan_price,
            duration_id: arr.duration_id,
            relation: arr.relation
          };

          if (arr.relation !== 'Child') {
            formattedArr.phone = `${arr?.countrycode?.code}${arr.phone}`;
          }

          _familyArr.push(formattedArr);
        } else {
          let formattedArr = {
            name: arr.name,
            email: arr.email,
            plan_id: arr.plan_id,
            plan_price: arr.plan_price,
            relation: arr.relation
          };

          if (arr.relation !== 'Child') {
            formattedArr.phone = `${arr?.countrycode?.code}${arr.phone}`;
          }

          _familyArr.push(formattedArr);
        }
      }
    });
    return _familyArr;
  };

  const verifyEmail = async (e) => {
    // localStorage.setItem('userEmail', email);
    axios
      .post(`${process.env.REACT_APP_BASEURL}tata/website/user/register`, {
        email: email
        // email: "testunlock14@yopmail.com",
      })
      .then((data) => {
        console.log(data);
        if (data.data.result === 'success') {
          setEmailCheck(false);
          console.log('result', data.data.result);
          setuserId(data.data.data.userId);
          localStorage.setItem('userId', data.data.data.userId);
          console.log(data.data.data.userId);
          verifyOtp(data.data.data.userId);
        } else if (data.data.message === 'Email is already registered and verified.') {
          console.log('verified');
        } else {
          restProps.setModalShow(false);
        }
      })
      .catch((err) => {
        console.log('err', err);

        restProps.setModalShow(false);
      });
  };

  function isValidEmail(index, email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const checkEmailExist = (index, email) => {
    console.log('email', email);
    if (isValidEmail(index, email)) {
      axios
        .post(`${process.env.REACT_APP_BASEURL}tata/website/user/check_user_subscription`, {
          email: email
        })
        .then((response) => {
          if (response.data.has_active_plan === false && response.data.has_registered === false) {
            console.log('response', response);
            familyArr[index]['planPurchased'] = false;
            familyArr[index]['planPurchasedMessage'] = response.data.message;
            setFamilyArr((prev) => {
              prev[index]['planPurchased'] = false;
              console.log('valid', true);
              prev[index]['isValidMail'] = true;
              prev[index]['errorForEmail'] = '';
              prev[index]['planPurchasedMessage'] = response.data.message;
              return [...prev];
            });
            setTrigger(trigger + 1);
          } else if (response.data.has_registered === true) {
            setFamilyArr((prev) => {
              console.log('valid', true);
              prev[index]['isValidMail'] = false;
              prev[index]['errorForEmail'] = 'This email is already registered with us.';
              return [...prev];
            });
          }
        })
        .catch((err) => {
          console.log('err', err.response);
          if (err.response.data.result === 'failure') {
            familyArr[index]['planPurchased'] = true;
            familyArr[index]['planPurchasedMessage'] = err.response.data.message;
            setFamilyArr((prev) => {
              prev[index]['planPurchased'] = true;
              prev[index]['isValidMail'] = false;
              prev[index]['planPurchasedMessage'] = err.response.data.message;
              return [...prev];
            });
            setTrigger(trigger + 1);
          }
        });
    } else {
      familyArr[index]['planPurchased'] = false;
      setFamilyArr((prev) => {
        prev[index]['errorForEmail'] = 'Enter a Valid Email';
        prev[index]['planPurchased'] = false;
        prev[index]['isValidMail'] = false;
        return [...prev];
      });
    }
  };

  const checkPhoneExist = (index, phone, country, email) => {
    axios
      .post(`${process.env.REACT_APP_BASEURL}tata/website/user/verify_phone`, {
        phone: String(phone),
        email: email,
        country_code: country
      })
      .then((response) => {
        const data = response.data;
        console.log('Number', data);
        if (data.is_valid === false) {
          // setWarnMsg(data.message);
          setFamilyArr((prev) => {
            prev[index]['fail'] = data.message;
            prev[index]['isValidPhone'] = false;
            return [...prev];
          });
        } else {
          console.log('response', response);
          familyArr[index]['isValidPhone'] = true;
          setFamilyArr((prev) => {
            prev[index]['isValidPhone'] = true;
            prev[index]['sucess'] = data.message;
            return [...prev];
          });
          // setSuccessMsg(data.message)
        }
      })
      .catch((error) => {
        const data = error.response.data;
        setFamilyArr((prev) => {
          prev[index]['fail'] = data.message;
          prev[index]['isValidPhone'] = false;
          return [...prev];
        });
      });
  };

  const formattingForOffer = () => {
    let _familyArr = [];
    familyArr.forEach((arr) => {
      if (
        arr.relation !== 'Primary User' &&
        arr.planPurchased === false &&
        !(arr.plan_id === String(idOfMe.package_id) && !arr.select)
      ) {
        let formattedArr = {
          name: arr.name,
          email: arr.email,
          plan_id: arr.plan_id,
          plan_price: arr.plan_price,
          relation: arr.relation
        };
        _familyArr.push(formattedArr);
      }
    });

    _familyArr.sort((a, b) => {
      if (a.plan_id === String(idOfDNA.package_id) && a.plan_price === 0) return -1;
      if (b.plan_id === String(idOfDNA.package_id) && b.plan_price === 0) return 1;
      return 0;
    });
    return _familyArr;
  };

  const checkSepcialPrice = () => {
    let body = {};
    if (Purchase.planid || Purchase.duration) {
      body = { userId: id, family: formattingForOffer() };
    } else {
      body = {
        plan_id: restProps.product,
        is_primary_user: true,
        userId: id,
        token: '',
        duration_id: durationId,
        plan_price: userPrice,
        orderId: '',
        allpurchase: 0,
        family: formattingForOffer()
      };
    }
    axios
      .post(`${process.env.REACT_APP_BASEURL}tata/website/user/get_plan_pricing`, body)
      .then((response) => {
        const newPrice = response.data;
        const newFamilyPrice = newPrice.data[0].details.family;
        const updatedFamilyArr = familyArr.map((familyMember) => {
          const matchingFamilyPrice = newFamilyPrice.find(
            (priceInfo) =>
              priceInfo.email === familyMember.email && priceInfo.plan_id === familyMember.plan_id
          );

          if (matchingFamilyPrice) {
            return {
              ...familyMember,
              plan_price: matchingFamilyPrice.plan_price
            };
          }

          return familyMember;
        });

        setFamilyArr(updatedFamilyArr);
      })
      .catch((err) => {
        console.log('err', err.response);
      });
  };

  const filterChange = (index, key, value) => {
    // set
    console.log('FInal', index, key, value);
    if (key == 'email') {
      const arr = familyArr.filter((prev) => prev.email == value);
      const isDuplicateInArray = familyArr
        .filter((_, i) => i !== index)
        .some((prev) => prev.email === value);
      const isDuplicateWithPhoneNumber = email === value;
      if (isDuplicateInArray || isDuplicateWithPhoneNumber) {
        setFamilyArr((prev) => {
          const updatedArr = [...prev];
          updatedArr[index] = {
            ...updatedArr[index],
            isValidMail: false,
            errorForEmail: 'Please use a different email ID for registration'
          };
          return updatedArr;
        });
      } else {
        checkEmailExist(index, value);
      }
      // if (arr.length == 1) {
      //   familyArr[index]['isValidMail'] = false;
      //   familyArr[index]['errorForEmail'] = 'Please use a different email ID for registration';
      // } else {
      //   familyArr[index]['errorForEmail'] = '';
      //   checkEmailExist(index, value);
      // }
    }
    if (key === 'plan_id') {
      if (!hasDuration(value)) {
        familyArr[index]['plan_price'] = Number(getPriceForProduct(value));
      } else {
        familyArr[index]['plan_price'] = 0;
      }

      if (value === String(idOfMe.package_id) && restProps.IsValid) {
        restProps.setOpenMessage(true);
        setIsThisIndex(index);
      }

      familyArr[index][key] = value;
      setFamilyArr((prev) => {
        prev[index][key] = value;
        return [...prev];
      });
      if (value === String(idOfDNA.package_id) && restProps.IsValid) checkSepcialPrice();
    } else if (key === 'duration_id') {
      familyArr[index][key] = value;
      setFamilyArr((prev) => {
        prev[index][key] = value;
        return [...prev];
      });
      familyArr[index]['plan_price'] = Number(getDurationPrice(familyArr[index]['plan_id'], value));
    } else {
      familyArr[index][key] = value;
      setFamilyArr((prev) => {
        prev[index][key] = value;
        return [...prev];
      });
    }
    setTrigger(trigger + 1);
    // console.log('FInal', familyArr);
  };

  async function executeInBackground(e) {
    restProps.setLoading(false);
    restProps.Loading.current = false;
    // await verifyEmail();
    // await verifyOtp();
  }

  useEffect(() => {
    restProps.setLoading(true);
    restProps.Loading.current = true;
    console.log('start');
    // setTimeout(async () => {
    //   await executeInBackground();
    // }, 5000);
    // axios
    //   .post(`${process.env.REACT_APP_BASEURL}tata/website/user/check_user_subscription`, {
    //     email: email
    //   })
    //   .then((response) => {
    //     if (response.data.has_active_plan) {
    //       setPlanPurchased(response.data.message);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log('err', err.response);
    //     if (err.response.data.result === 'failure') {
    //       setPlanPurchased('');
    //     }
    //   });
    // console.log('end');
  }, [email]);

  const getPriceForProduct = (id) => {
    let price = 0;
    restProps.productList.map((product) => {
      if (product.package_id == id) {
        price = product.special_price;
      }
    });
    return price;
  };

  const hasDuration = (id) => {
    console.log(restProps.productList, 'prolist');
    let flag = false;
    restProps.productList.map((product) => {
      if (product.package_id == id) {
        if (product.has_durations) {
          flag = true;
        }
      }
    });
    return flag;
  };

  const getDurations = (id) => {
    let dur = [];
    restProps.productList.map((product) => {
      if (product.package_id == id && product.has_durations) {
        dur = product.durations;
      }
    });
    return dur;
  };

  const getDurationPrice = (id, durationId) => {
    let price = 0;
    restProps.productList.map((product) => {
      if (product.package_id == id && product.has_durations) {
        product.durations.map((duration) => {
          if (duration.id == durationId) {
            price = duration.special_price;
          }
        });
      }
    });
    return price;
  };

  const getPrice = (id) => {
    restProps.productList.map((product) => {
      if (product.package_id == id) {
        return product.special_price;
      }
    });
  };

  const handleUserDurationChange = (e) => {
    setDurationId(e.target.value);
    console.log(e.target.value, 'durationId');
    getDurations(restProps.product).map((duration) => {
      console.log(duration, 'duration');
      if (duration.id == e.target.value) {
        console.log(duration, 'duration matched');
        setDuration(duration.life + ' ' + duration.duration_type);
      }
    });
    setUserPrice(getDurationPrice(restProps.product, e.target.value));
  };

  const [EMIOption, setEMIOption] = useState(false);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_BASEURL}tata/website/v1/check_pay_now`,
        { plan_id: restProps.product, duration_id: parseInt(durationId) },
        { 'Content-Type': 'application/json' }
      )
      .then((response) => {
        setEMIOption(response.data.has_emi_option);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [restProps.product, durationId]);

  useEffect(() => {
    if (
      WarnMsg ||
      SuccessMsg ||
      ShowError ||
      familyArr.some(
        (item) =>
          item.fail ||
          item.sucess ||
          item.errorForEmail ||
          item.borderForName ||
          item.borderForEmail ||
          item.borderForPhone ||
          item.borderForRelation ||
          item.borderForPlan ||
          item.borderForDuration
      )
    ) {
      const timeoutId = setTimeout(() => {
        setWarnMsg('');
        setSuccessMsg('');
        setShowError(false);
        setFamilyArr((prev) => {
          const updatedArr = prev.map((item) => {
            return {
              ...item,
              fail: '',
              sucess: '',
              errorForEmail: ''
            };
          });
          return updatedArr;
        });
      }, 4000);

      return () => clearTimeout(timeoutId);
    }
  }, [WarnMsg, SuccessMsg, familyArr, ShowError]);

  const allFamilyMembersPurchased = familyArr.every((member) => member.planPurchased === false);
  const allFamilyMembersSelected = familyArr.every((member) => member.select === false);

  const CheckVersion =
    !Purchase.planid || !Purchase.duration || allFamilyMembersPurchased || allFamilyMembersSelected;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener on component mount
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // console.log('test case',Number(restProps.product), Purchase.planid, CheckVersion);

  return (
    <>
      {
        <Model show={true}>
          <ModelContainer>
            <Content>
              {!restProps.loading && (
                <Button
                  style={{
                    borderRadius: '20px',
                    position: 'absolute',
                    top: '0',
                    right: '1rem',
                    height: '2.3rem',
                    width: '2.3rem',
                    color: 'black',
                    backgroundColor: 'white',
                    padding: ' 0.4px 0.5px'
                  }}
                  onClick={() => restProps.setModalShow(false)}>
                  X
                </Button>
              )}
              <Logo
                src="/assets/Tata comm-unlock fit.png"
                style={{ height: '3rem', width: '14rem', marginTop: 0 }}
                alt=""
              />

              {restProps.loading ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#eef1f4'
                  }}>
                  <h3
                    style={{
                      color: '#4990e0',
                      paddingTop: '30px',
                      fontWeight: '100',
                      position: 'absolute',
                      top: '135px'
                    }}>
                    Verifying Your Account...
                  </h3>{' '}
                  <div style={{ width: 350, height: 120 }}>
                    <LinearProgress
                      variant="determinate"
                      value={Loader}
                      sx={{
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: '#abd17a'
                        },
                        backgroundColor: '#e4e7ea',
                        width: '65%',
                        margin: 'auto',
                        top: '45px'
                      }}
                    />
                  </div>
                  {/* <img style={{ height: '100px', width: '500px' }} src="assets/Loading-Bar.gif" />{' '} */}
                </div>
              ) : !showTermsAndCondition ? (
                screenWidth < 800 ? (
                  <div>
                    <h2>My Cart</h2>
                    <InputWrapper>
                      {console.log('PR', product)}
                      <CardHolder style={{ width: familyArr.length === 0 ? '120%' : '100%' }}>
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'start'
                          }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                              flexDirection: 'column'
                            }}>
                            <input
                              type="checkbox"
                              id="value"
                              name="value"
                              checked={includeUser}
                              style={{ marginRight: 20, width: '10%' }}
                              disabled={Purchase.planid || Purchase.duration}
                              onChange={(e) => {
                                setIncludeUser(e.target.checked);
                                if (
                                  !e.target.checked &&
                                  restProps.IsValid &&
                                  restProps.product === idOfMe.package_id
                                ) {
                                  let removedOne = false;

                                  const newArr = familyArr.filter((object, index) => {
                                    if (
                                      index === 0 &&
                                      object.plan_id === String(idOfDNA.package_id) &&
                                      object.isAddedByOffer &&
                                      !removedOne
                                    ) {
                                      removedOne = true;
                                      return false;
                                    }
                                    return true;
                                  });
                                  setFamilyArr(newArr);
                                }
                                if (
                                  restProps.product === idOfMe.package_id &&
                                  e.target.checked &&
                                  restProps.IsValid
                                ) {
                                  setIsThisIndex(null);
                                  restProps.setOpenMessage(true);
                                }
                              }}
                            />
                            {Purchase.planid || Purchase.duration ? (
                              <p
                                style={{
                                  fontWeight: '0',
                                  fontSize: '10px',
                                  margin: '0',
                                  padding: '0',
                                  overflow: 'visible',
                                  position: 'relative',
                                  marginLeft: 10
                                }}>
                                Plan Already Purchased
                              </p>
                            ) : (
                              <></>
                            )}
                            <div className="label" style={{ marginTop: 10 }}>
                              <label for="name" style={{ fontSize: '10px', marginLeft: '10px' }}>
                                Name
                              </label>
                              <input
                                type="text"
                                placeholder="name"
                                id="name"
                                value={name}
                                disabled
                                onChange={(e) =>
                                  filterChange(
                                    index,
                                    'name',
                                    e.target.value.replace(/[^a-zA-Z0-9]/g, '')
                                  )
                                }></input>
                            </div>
                            <div className="label" style={{ marginTop: 10 }}>
                              <label for="name" style={{ fontSize: '10px', marginLeft: '10px' }}>
                                Email
                              </label>
                              <input type="text" placeholder="email" disabled value={email}></input>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                marginTop: 10
                              }}>
                              <div className="label" style={{ width: '23%' }}>
                                <div className="label1">
                                  <label
                                    for="name"
                                    style={{ fontSize: '10px', marginLeft: '10px' }}>
                                    Phone
                                  </label>
                                  <Autocomplete
                                    id="phone"
                                    autoHighlight={true}
                                    options={CountryCode}
                                    getOptionLabel={(option) => option.code}
                                    value={selectedCountryCode}
                                    disabled={Purchase.planid || Purchase.duration}
                                    onChange={(event, newValue) => {
                                      setSelectedCountryCode(newValue);
                                    }}
                                    filterOptions={(options, state) => {
                                      return options.filter(
                                        (option) =>
                                          option.name
                                            .toLowerCase()
                                            .includes(state.inputValue.toLowerCase()) ||
                                          option.code
                                            .replace(/\D/g, '')
                                            .toLowerCase()
                                            .includes(state.inputValue.toLowerCase()) ||
                                          option.code
                                            .toLowerCase()
                                            .includes(state.inputValue.toLowerCase())
                                      );
                                    }}
                                    PopperComponent={({ children, ...other }) => (
                                      <Popper {...other}>
                                        <Paper style={{ width: '300px' }}>{children}</Paper>
                                      </Popper>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label=""
                                        variant="outlined"
                                        InputProps={{
                                          ...params.InputProps,
                                          style: {
                                            height: '44px',
                                            padding: '0px',
                                            '&:hover fieldset': {
                                              borderColor: '#c4c4c4'
                                            }
                                          },
                                          endAdornment: null
                                        }}
                                      />
                                    )}
                                    renderOption={(props, option) => (
                                      <li style={{ fontSize: 15 }} {...props}>
                                        <img src={option.flag} alt={option.name} />
                                        {`${option.code} ${option.name}`}
                                      </li>
                                    )}
                                    disableClearable={true}
                                    style={{
                                      backgroundColor: '#fff',
                                      marginTop: 4,
                                      borderRadius: 5
                                    }}>
                                    <option value="" key="placeholder">
                                      Select a country
                                    </option>
                                  </Autocomplete>
                                  <p
                                    style={{
                                      position: 'absolute',
                                      fontWeight: '0',
                                      fontSize: '10px',
                                      margin: '0',
                                      padding: '0',
                                      overflow: 'visible',
                                      color: SuccessMsg ? '#07d507' : 'red'
                                    }}>
                                    {SuccessMsg || WarnMsg}
                                  </p>
                                </div>
                              </div>
                              <div className="label" style={{ width: '80%' }}>
                                <div className="label1">
                                  <input
                                    type="text"
                                    placeholder="Phone"
                                    value={PhoneNumber}
                                    disabled={Purchase.planid || Purchase.duration}
                                    onChange={(e) => {
                                      const phoneNumber = e.target.value.replace(/\D/g, '');
                                      setPrimaryPhoneValid(false);
                                      setSuccessMsg('');
                                      setWarnMsg('');
                                      setPhoneNumber(phoneNumber);
                                      setPhoneError(false);
                                      if (selectedCountryCode?.code?.length === 0) {
                                        setWarnMsg('Select a Country Code');
                                      } else if (
                                        phoneNumber?.length >= 8 &&
                                        selectedCountryCode?.code?.length !== 0
                                      ) {
                                        checkPhone(phoneNumber);
                                      }
                                    }}
                                    style={{
                                      marginTop: 24.33,
                                      marginLeft: 5,
                                      width: '88%',
                                      border: PhoneError ? '1px solid red' : '1px solid #fff0'
                                    }}></input>
                                </div>
                              </div>
                            </div>
                            <div className="label" style={{ marginTop: 10 }}>
                              <label for="name" style={{ fontSize: '10px', marginLeft: '10px' }}>
                                Relation
                              </label>
                              <input
                                id="relation"
                                style={{ minWidth: '119.01px' }}
                                disabled
                                value={'Primary User'}></input>
                            </div>
                            <div className="label" style={{ marginTop: 10 }}>
                              <label for="name" style={{ fontSize: '10px', marginLeft: '10px' }}>
                                Plan
                              </label>
                              <input
                                id="plan"
                                disabled
                                value={
                                  PlanPurchase.planName ? PlanPurchase.planName : restProps.planName
                                }></input>
                            </div>
                            <div className="label" style={{ marginTop: 10 }}>
                              {hasDuration(
                                PlanPurchase.duration
                                  ? Purchase.planid
                                  : Purchase.planid || restProps.product
                              ) && (
                                <div>
                                  <label
                                    for="name"
                                    style={{ fontSize: '10px', marginLeft: '10px' }}>
                                    Duration
                                  </label>
                                  <select
                                    name="duration"
                                    id="duration"
                                    disabled={
                                      PlanPurchase.duration ? PlanPurchase.duration : !includeUser
                                    }
                                    value={
                                      PlanPurchase.duration ? PlanPurchase.duration : durationId
                                    }
                                    onChange={handleUserDurationChange}>
                                    {getDurations(
                                      PlanPurchase.duration ? Purchase.planid : restProps.product
                                    ).map((dur) => {
                                      return (
                                        <option value={dur.id}>
                                          {dur.life + ' ' + dur.duration_type}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              )}
                            </div>
                            <div className="label2">
                              <label
                                for="name"
                                style={{
                                  fontSize: '10px',
                                  marginLeft: '10px'
                                }}>
                                Price
                              </label>
                              <p style={{ fontSize: '16px', marginLeft: 10 }}>
                                ₹ {PlanPurchase.planPrice ? PlanPurchase.planPrice : userPrice}
                              </p>
                            </div>
                          </div>
                          {/* <p
                          style={{
                            fontWeight: '0',
                            fontSize: '10px',
                            margin: '0',
                            padding: '0',
                            overflow: 'visible',
                            color: SuccessMsg ? '#07d507' : 'red'
                          }}>
                          {SuccessMsg || WarnMsg}
                        </p> */}
                        </div>
                      </CardHolder>
                      {familyArr.map((val, index) => {
                        if (val.relation === 'Primary User') {
                          return null;
                        }
                        console.log('val', val);
                        return (
                          <CardHolder style={{ width: '100%' }}>
                            <div
                              style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'start'
                              }}>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                  flexDirection: 'column'
                                }}>
                                {val.planPurchased ? (
                                  <input
                                    type="checkbox"
                                    id="value"
                                    name="value"
                                    value="Bike"
                                    style={{ marginRight: 20, width: '10%' }}
                                    defaultChecked
                                    disabled={true}
                                    checked={true}
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    id="value"
                                    name="value"
                                    value="Bike"
                                    defaultChecked
                                    style={{ marginRight: 20, width: '10%' }}
                                    disabled={val.planPurchased ? true : false}
                                    checked={val.select}
                                    onChange={(e) => {
                                      if (!e.target.checked) {
                                        setFamilyArr((prev) => {
                                          const updatedArr = [...prev];
                                          updatedArr[index] = {
                                            ...updatedArr[index],
                                            borderForName: val.name ? false : true,
                                            borderForEmail: val.email ? false : true,
                                            borderForRelation:
                                              !val.relation || val.relation === '-1' ? true : false
                                          };
                                          return updatedArr;
                                        });
                                        if (
                                          !val.name ||
                                          !val.email ||
                                          !val.relation ||
                                          val.relation === '-1'
                                        ) {
                                          setShowError(true);
                                          return;
                                        }
                                      }
                                      filterChange(index, 'select', e.target.checked);
                                      setFamilyArr((prev) => {
                                        const updatedArr = [...prev];
                                        updatedArr[index] = {
                                          ...updatedArr[index],
                                          borderForDuration: false,
                                          borderForEmail: false,
                                          borderForName: false,
                                          borderForPhone: false,
                                          borderForRelation: false,
                                          borderForPlan: false
                                        };
                                        return updatedArr;
                                      });
                                      if (
                                        val.plan_id === String(idOfMe.package_id) &&
                                        !e.target.checked &&
                                        restProps.IsValid
                                      ) {
                                        const selectedIndex = index;
                                        if (
                                          selectedIndex !== -1 &&
                                          selectedIndex < familyArr.length - 1 &&
                                          familyArr[selectedIndex + 1].plan_id ===
                                            String(idOfDNA.package_id) &&
                                          (familyArr[selectedIndex + 1].isAddedByOffer ||
                                            familyArr[selectedIndex + 1].plan_price === 0)
                                        ) {
                                          const newArr = [...familyArr];
                                          const indexToRemove = selectedIndex + 1;

                                          newArr.splice(indexToRemove, 1);
                                          setFamilyArr(newArr);
                                        }
                                      }
                                      if (
                                        val.plan_id === String(idOfMe.package_id) &&
                                        e.target.checked &&
                                        restProps.IsValid
                                      ) {
                                        restProps.setOpenMessage(true);
                                        setIsThisIndex(index);
                                      }
                                      if (e.target.checked) {
                                        totalCnt += 1;
                                      } else totalCnt -= 1;
                                    }}
                                  />
                                )}
                                {val.planPurchased && (
                                  <p
                                    style={{
                                      fontWeight: '0',
                                      fontSize: '10px',
                                      margin: '0',
                                      padding: '0',
                                      overflow: 'visible',
                                      position: 'relative',
                                      marginLeft: 10
                                    }}>
                                    {val.planPurchasedMessage}
                                  </p>
                                )}
                                <div className="label" style={{ marginTop: 10 }}>
                                  <label
                                    for="name"
                                    style={{ fontSize: '10px', marginLeft: '10px' }}>
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="name"
                                    id="name"
                                    value={val?.name}
                                    style={{
                                      border: familyArr[index].borderForName && '1px solid red'
                                    }}
                                    disabled={!familyArr[index]?.select}
                                    onChange={(e) => {
                                      filterChange(
                                        index,
                                        'name',
                                        e.target.value.replace(/[^a-zA-Z0-9 ]/g, '')
                                      );
                                      if (
                                        val.plan_id === String(idOfMe.package_id) &&
                                        restProps.IsValid
                                      ) {
                                        const selectedIndex = index;
                                        if (
                                          selectedIndex !== -1 &&
                                          selectedIndex < familyArr.length - 1 &&
                                          familyArr[selectedIndex + 1].plan_id ===
                                            String(idOfDNA.package_id) &&
                                          familyArr[selectedIndex + 1].isAddedByOffer
                                        ) {
                                          const newArr = [...familyArr];
                                          const indexToRemove = selectedIndex + 1;

                                          newArr.splice(indexToRemove, 1);
                                          setFamilyArr(newArr);
                                        }
                                      }
                                      setFamilyArr((prev) => {
                                        const updatedArr = [...prev];
                                        updatedArr[index] = {
                                          ...updatedArr[index],
                                          borderForName: false,
                                          plan_id: val.isAddedByOffer
                                            ? String(idOfDNA.package_id)
                                            : '-1',
                                          plan_price: 0
                                        };
                                        return updatedArr;
                                      });
                                    }}></input>
                                </div>
                                <div className="label" style={{ marginTop: 10 }}>
                                  <label
                                    for="name"
                                    style={{ fontSize: '10px', marginLeft: '10px' }}>
                                    Email
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="email"
                                    value={val?.email}
                                    style={{
                                      border: familyArr[index].borderForEmail && '1px solid red'
                                    }}
                                    onChange={(e) => {
                                      filterChange(index, 'email', e.target.value.toLowerCase());
                                      if (
                                        val.plan_id === String(idOfMe.package_id) &&
                                        restProps.IsValid
                                      ) {
                                        const selectedIndex = index;
                                        if (
                                          selectedIndex !== -1 &&
                                          selectedIndex < familyArr.length - 1 &&
                                          familyArr[selectedIndex + 1].plan_id ===
                                            String(idOfDNA.package_id) &&
                                          familyArr[selectedIndex + 1].isAddedByOffer
                                        ) {
                                          const newArr = [...familyArr];
                                          const indexToRemove = selectedIndex + 1;

                                          newArr.splice(indexToRemove, 1);
                                          setFamilyArr(newArr);
                                        }
                                      }
                                      setFamilyArr((prev) => {
                                        const updatedArr = [...prev];
                                        updatedArr[index] = {
                                          ...updatedArr[index],
                                          plan_id: val.isAddedByOffer
                                            ? String(idOfDNA.package_id)
                                            : '-1',
                                          plan_price: 0,
                                          planPurchased: false,
                                          borderForEmail: false,
                                          planPurchasedMessage: ''
                                        };
                                        return updatedArr;
                                      });
                                    }}
                                    disabled={!familyArr[index]?.select}></input>
                                  <div
                                    style={{
                                      fontWeight: '0',
                                      fontSize: '10px',
                                      margin: '0',
                                      padding: '0',
                                      overflow: 'visible',
                                      color: 'red'
                                    }}>
                                    {familyArr[index]?.errorForEmail}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    marginTop: 10
                                  }}>
                                  <div className="label" style={{ width: '25%' }}>
                                    {familyArr[index]?.relation !== 'Child' &&
                                      familyArr[index]?.relation !== 'child' && (
                                        <div className="label1">
                                          <label
                                            for="name"
                                            style={{ fontSize: '10px', marginLeft: '10px' }}>
                                            Phone
                                          </label>
                                          <Autocomplete
                                            id="phone"
                                            autoHighlight={true}
                                            disabled={!familyArr[index]?.select}
                                            options={CountryCode}
                                            getOptionLabel={(option) => option.code}
                                            value={val?.countrycode}
                                            onChange={(event, newValue) => {
                                              filterChange(index, 'countrycode', newValue);
                                              setFamilyArr((prev) => {
                                                const updatedArr = [...prev];
                                                updatedArr[index] = {
                                                  ...updatedArr[index],
                                                  fail: '',
                                                  success: ''
                                                };
                                                return updatedArr;
                                              });
                                            }}
                                            filterOptions={(options, state) => {
                                              return options.filter(
                                                (option) =>
                                                  option.name
                                                    .toLowerCase()
                                                    .includes(state.inputValue.toLowerCase()) ||
                                                  option.code
                                                    .replace(/\D/g, '')
                                                    .toLowerCase()
                                                    .includes(state.inputValue.toLowerCase()) ||
                                                  option.code
                                                    .toLowerCase()
                                                    .includes(state.inputValue.toLowerCase())
                                              );
                                            }}
                                            PopperComponent={({ children, ...other }) => (
                                              <Popper {...other}>
                                                <Paper style={{ width: '300px' }}>{children}</Paper>
                                              </Popper>
                                            )}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label=""
                                                variant="outlined"
                                                InputProps={{
                                                  ...params.InputProps,
                                                  style: {
                                                    height: '44px',
                                                    padding: '0px',
                                                    '&:hover fieldset': {
                                                      borderColor: '#c4c4c4'
                                                    }
                                                  },
                                                  endAdornment: null
                                                }}
                                              />
                                            )}
                                            renderOption={(props, option) => (
                                              <li style={{ fontSize: 15 }} {...props}>
                                                <img src={option.flag} alt={option.name} />
                                                {`${option.code} ${option.name}`}
                                              </li>
                                            )}
                                            disableClearable={true}
                                            style={{
                                              backgroundColor: '#fff',
                                              marginTop: 4,
                                              borderRadius: 5
                                            }}>
                                            <option value="" key="placeholder">
                                              Select a country
                                            </option>
                                          </Autocomplete>
                                          <p
                                            style={{
                                              position: 'absolute',
                                              fontWeight: '0',
                                              fontSize: '10px',
                                              margin: '0',
                                              padding: '0',
                                              overflow: 'visible',
                                              color: familyArr[index].sucess ? '#07d507' : 'red'
                                            }}>
                                            {familyArr[index].sucess || familyArr[index].fail}
                                          </p>
                                        </div>
                                      )}
                                  </div>
                                  <div className="label" style={{ width: '80%' }}>
                                    {familyArr[index]?.relation !== 'Child' &&
                                      familyArr[index]?.relation !== 'child' && (
                                        <div className="label1">
                                          <input
                                            type="text"
                                            placeholder="Phone"
                                            value={val?.phone}
                                            style={{
                                              border:
                                                familyArr[index].borderForPhone && '1px solid red',
                                              marginTop: 24.33,
                                              width: '88%',
                                              marginLeft: 5
                                            }}
                                            onPaste={(e) => {
                                              e.preventDefault();
                                            }}
                                            onChange={(e) => {
                                              const phoneNumber = e.target.value.replace(/\D/g, '');
                                              if (
                                                val.plan_id === String(idOfMe.package_id) &&
                                                restProps.IsValid
                                              ) {
                                                const selectedIndex = index;
                                                if (
                                                  selectedIndex !== -1 &&
                                                  selectedIndex < familyArr.length - 1 &&
                                                  familyArr[selectedIndex + 1].plan_id ===
                                                    String(idOfDNA.package_id) &&
                                                  familyArr[selectedIndex + 1].isAddedByOffer
                                                ) {
                                                  const newArr = [...familyArr];
                                                  const indexToRemove = selectedIndex + 1;

                                                  newArr.splice(indexToRemove, 1);
                                                  setFamilyArr(newArr);
                                                }
                                              }
                                              setFamilyArr((prev) => {
                                                const updatedArr = [...prev];
                                                updatedArr[index] = {
                                                  ...updatedArr[index],
                                                  borderForPhone: false,
                                                  isValidPhone: false,
                                                  plan_id: val.isAddedByOffer
                                                    ? String(idOfDNA.package_id)
                                                    : '-1',
                                                  plan_price: 0
                                                };
                                                return updatedArr;
                                              });
                                              if (!val?.isValidMail || !val?.name) {
                                                setShowError(true);
                                                setFamilyArr((prev) => {
                                                  const updatedArr = [...prev];
                                                  updatedArr[index] = {
                                                    ...updatedArr[index],
                                                    phone: '',
                                                    borderForEmail:
                                                      !val?.email || !val?.isValidMail
                                                        ? true
                                                        : false,
                                                    borderForName: !val?.name ? true : false
                                                  };
                                                  return updatedArr;
                                                });
                                                return;
                                              } else {
                                                if (val?.countrycode?.code?.length === 0) {
                                                  setFamilyArr((prev) => {
                                                    const updatedArr = [...prev];
                                                    updatedArr[index] = {
                                                      ...updatedArr[index],
                                                      fail: 'Select a Country Code'
                                                    };
                                                    return updatedArr;
                                                  });
                                                } else {
                                                  setFamilyArr((prev) => {
                                                    const updatedArr = [...prev];
                                                    updatedArr[index] = {
                                                      ...updatedArr[index],
                                                      phone: phoneNumber,
                                                      fail: '',
                                                      sucess: ''
                                                    };
                                                    return updatedArr;
                                                  });
                                                }
                                                const isDuplicateInArray = familyArr
                                                  .filter((_, i) => i !== index)
                                                  .some((prev) => prev.phone === phoneNumber);
                                                const isDuplicateWithPhoneNumber =
                                                  PhoneNumber === phoneNumber;
                                                if (
                                                  isDuplicateInArray ||
                                                  isDuplicateWithPhoneNumber
                                                ) {
                                                  setFamilyArr((prev) => {
                                                    const updatedArr = [...prev];
                                                    updatedArr[index] = {
                                                      ...updatedArr[index],
                                                      fail: 'Phone number already registered with us. Please provide an alternative phone number.'
                                                    };
                                                    return updatedArr;
                                                  });
                                                } else {
                                                  setFamilyArr((prev) => {
                                                    const updatedArr = [...prev];
                                                    updatedArr[index] = {
                                                      ...updatedArr[index],
                                                      fail: ''
                                                    };
                                                    return updatedArr;
                                                  });
                                                  if (
                                                    phoneNumber.length >= 8 &&
                                                    val?.countrycode?.code?.length !== 0
                                                  ) {
                                                    checkPhoneExist(
                                                      index,
                                                      phoneNumber,
                                                      val?.countrycode.code,
                                                      val?.email
                                                    );
                                                  }
                                                }
                                              }
                                            }}
                                            disabled={!familyArr[index]?.select}></input>
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="label" style={{ marginTop: 10 }}>
                                  <label
                                    for="name"
                                    style={{ fontSize: '10px', marginLeft: '10px' }}>
                                    Relation
                                  </label>
                                  <select
                                    id="relation"
                                    disabled={!familyArr[index]?.select}
                                    value={val?.relation}
                                    style={{
                                      border: familyArr[index].borderForRelation && '1px solid red'
                                    }}
                                    onChange={(e) => {
                                      if (e.target.value === 'Child') {
                                        delete familyArr[index].isValidPhone;
                                        delete familyArr[index].countrycode;
                                        delete familyArr[index].phone;
                                      } else {
                                        familyArr[index].countrycode = val.countrycode
                                          ? val.countrycode
                                          : {
                                              code: '+91',
                                              name: 'India'
                                            };
                                        if (
                                          val.plan_id === String(idOfMe.package_id) &&
                                          restProps.IsValid
                                        ) {
                                          const selectedIndex = index;
                                          if (
                                            selectedIndex !== -1 &&
                                            selectedIndex < familyArr.length - 1 &&
                                            familyArr[selectedIndex + 1].plan_id ===
                                              String(idOfDNA.package_id) &&
                                            familyArr[selectedIndex + 1].isAddedByOffer
                                          ) {
                                            const newArr = [...familyArr];
                                            const indexToRemove = selectedIndex + 1;

                                            newArr.splice(indexToRemove, 1);
                                            setFamilyArr(newArr);
                                          }
                                        }
                                        familyArr[index].phone = val.phone ? val.phone : '';
                                        if (!val?.isValidMail || !val.name || !val.isValidPhone) {
                                          setShowError(true);
                                          setFamilyArr((prev) => {
                                            const updatedArr = [...prev];
                                            updatedArr[index] = {
                                              ...updatedArr[index],
                                              relation: '',
                                              borderForName: !val?.name ? true : false,
                                              borderForEmail:
                                                !val?.email || !val?.isValidMail ? true : false,
                                              borderForPhone:
                                                !val?.phone || !val.isValidPhone ? true : false
                                            };
                                            return updatedArr;
                                          });
                                          return;
                                        }
                                      }
                                      filterChange(index, 'relation', e.target.value);
                                      setFamilyArr((prev) => {
                                        const updatedArr = [...prev];
                                        updatedArr[index] = {
                                          ...updatedArr[index],
                                          borderForRelation: false,
                                          plan_price: 0
                                        };
                                        return updatedArr;
                                      });
                                      familyArr[index].plan_id = val.isAddedByOffer
                                        ? String(idOfDNA.package_id)
                                        : '-1';
                                    }}>
                                    <option value="" disabled>
                                      Choose
                                    </option>
                                    <option value="Parent">Parent</option>
                                    <option value="Sibling">Sibling</option>
                                    <option value="Child">Child</option>
                                    <option value="Friend">Friend</option>
                                    <option value="Cousin">Cousin</option>
                                    <option value="Spouse">Spouse</option>
                                  </select>
                                </div>
                                <div className="label" style={{ marginTop: 10 }}>
                                  <label
                                    for="name"
                                    style={{ fontSize: '10px', marginLeft: '10px' }}>
                                    Plan
                                  </label>
                                  <select
                                    id="plan"
                                    style={{
                                      border: familyArr[index].borderForPlan && '1px solid red'
                                    }}
                                    disabled={!familyArr[index]?.select || val.isAddedByOffer}
                                    value={val?.plan_id}
                                    onChange={(e) => {
                                      if (val?.relation === 'Child') {
                                        if (!val?.isValidMail || !val.name || !val.relation) {
                                          setShowError(true);
                                          setFamilyArr((prev) => {
                                            const updatedArr = [...prev];
                                            updatedArr[index] = {
                                              ...updatedArr[index],
                                              plan_id: '-1',
                                              plan: '',
                                              borderForName: !val?.name ? true : false,
                                              borderForRelation: !val?.relation ? true : false,
                                              borderForEmail:
                                                !val?.email || !val?.isValidMail ? true : false
                                            };
                                            return updatedArr;
                                          });
                                          return;
                                        }
                                        if (
                                          (e.target.value === String(idOfDNA.package_id) ||
                                            e.target.value !== String(idOfMe.package_id)) &&
                                          restProps.IsValid &&
                                          val.plan_id === String(idOfMe.package_id)
                                        ) {
                                          const selectedIndex = index;
                                          if (
                                            selectedIndex !== -1 &&
                                            selectedIndex < familyArr.length - 1 &&
                                            familyArr[selectedIndex + 1].plan_id ===
                                              String(idOfDNA.package_id) &&
                                            familyArr[selectedIndex + 1].isAddedByOffer
                                          ) {
                                            const newArr = [...familyArr];
                                            const indexToRemove = selectedIndex + 1;

                                            newArr.splice(indexToRemove, 1);
                                            setFamilyArr(newArr);
                                          }
                                          familyArr[index].plan_price = Number(
                                            getPriceForProduct(e.target.value)
                                          );
                                          familyArr[index].plan_id = e.target.value;
                                        } else {
                                          filterChange(index, 'plan_id', e.target.value);
                                        }
                                        setFamilyArr((prev) => {
                                          const updatedArr = [...prev];
                                          updatedArr[index] = {
                                            ...updatedArr[index],
                                            borderForPlan: false,
                                            borderForDuration: false,
                                            duration_id: '-1'
                                          };
                                          return updatedArr;
                                        });
                                      } else {
                                        if (
                                          !val?.isValidMail ||
                                          !val.name ||
                                          !val.isValidPhone ||
                                          !val.relation
                                        ) {
                                          setShowError(true);
                                          setFamilyArr((prev) => {
                                            const updatedArr = [...prev];
                                            updatedArr[index] = {
                                              ...updatedArr[index],
                                              plan_id: '-1',
                                              plan: '',
                                              borderForName: !val?.name ? true : false,
                                              borderForRelation: !val?.relation ? true : false,
                                              borderForEmail:
                                                !val?.email || !val?.isValidMail ? true : false,
                                              borderForPhone:
                                                !val?.phone || !val.isValidPhone ? true : false
                                            };
                                            return updatedArr;
                                          });
                                          return;
                                        }
                                        if (
                                          (e.target.value === String(idOfDNA.package_id) ||
                                            e.target.value !== String(idOfMe.package_id)) &&
                                          restProps.IsValid &&
                                          val.plan_id === String(idOfMe.package_id)
                                        ) {
                                          const selectedIndex = index;
                                          if (
                                            selectedIndex !== -1 &&
                                            selectedIndex < familyArr.length - 1 &&
                                            familyArr[selectedIndex + 1].plan_id ===
                                              String(idOfDNA.package_id) &&
                                            familyArr[selectedIndex + 1].isAddedByOffer
                                          ) {
                                            const newArr = [...familyArr];
                                            const indexToRemove = selectedIndex + 1;

                                            newArr.splice(indexToRemove, 1);
                                            setFamilyArr(newArr);
                                          }
                                          familyArr[index].plan_price = Number(
                                            getPriceForProduct(e.target.value)
                                          );
                                          familyArr[index].plan_id = e.target.value;
                                        } else {
                                          filterChange(index, 'plan_id', e.target.value);
                                        }
                                        setFamilyArr((prev) => {
                                          const updatedArr = [...prev];
                                          updatedArr[index] = {
                                            ...updatedArr[index],
                                            borderForPlan: false,
                                            borderForDuration: false,
                                            duration_id: '-1'
                                          };
                                          return updatedArr;
                                        });
                                      }
                                    }}>
                                    <option value="-1" selected disabled>
                                      Choose
                                    </option>
                                    {restProps.productList.map((pro) => (
                                      <option value={pro.package_id}>
                                        {pro.package_title}
                                        {pro.package_id === idOfDNA.package_id ? ' ®' : ''}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="label" style={{ marginTop: 10 }}>
                                  {hasDuration(val.plan_id) && (
                                    <div className="label1">
                                      <label
                                        for="name"
                                        style={{
                                          fontSize: '10px',
                                          marginLeft: '10px'
                                        }}>
                                        Duration
                                      </label>
                                      <select
                                        name="duration"
                                        id="duration"
                                        style={{
                                          border:
                                            familyArr[index].borderForDuration && '1px solid red'
                                        }}
                                        disabled={!familyArr[index]?.select}
                                        onChange={(e) => {
                                          if (!val?.isValidMail || !val.name || !val.relation) {
                                            setShowError(true);
                                            setFamilyArr((prev) => {
                                              const updatedArr = [...prev];
                                              updatedArr[index] = {
                                                ...updatedArr[index],
                                                plan_id: '-1',
                                                plan: '',
                                                borderForName: !val?.name ? true : false,
                                                borderForRelation: !val?.relation ? true : false,
                                                borderForEmail:
                                                  !val?.email || !val?.isValidMail ? true : false
                                              };
                                              return updatedArr;
                                            });
                                            return;
                                          }
                                          filterChange(index, 'duration_id', e.target.value);
                                          setFamilyArr((prev) => {
                                            const updatedArr = [...prev];
                                            updatedArr[index] = {
                                              ...updatedArr[index],
                                              borderForDuration: false
                                            };
                                            return updatedArr;
                                          });
                                        }}
                                        value={val?.duration_id}>
                                        <option value="-1" selected disabled>
                                          Choose
                                        </option>
                                        {getDurations(val.plan_id).map((dur) => {
                                          return (
                                            <option value={dur.id}>
                                              {dur.life + ' ' + dur.duration_type}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  )}
                                </div>
                                <div className="label2">
                                  <label
                                    for="name"
                                    style={{
                                      fontSize: '10px',
                                      marginLeft: '10px'
                                    }}>
                                    Price
                                  </label>
                                  <p
                                    style={{
                                      fontSize: '16px',
                                      whiteSpace: 'nowrap',
                                      marginLeft: 10
                                    }}>
                                    ₹{' '}
                                    {val.plan_price === undefined || val.plan_price === ' '
                                      ? 0
                                      : val.plan_price}{' '}
                                    {(val.isAddedByOffer || restProps.IsValid) &&
                                      val.plan_id === String(idOfDNA.package_id) &&
                                      val.plan_price === 0 &&
                                      'Offer Redeemed'}
                                  </p>
                                </div>
                              </div>
                              {/* <p
                              style={{
                                fontWeight: '0',
                                fontSize: '10px',
                                margin: '0',
                                padding: '0',
                                overflow: 'visible',
                                color: val.sucess ? '#07d507' : 'red'
                              }}>
                              {val.sucess || val.fail}
                            </p> */}
                            </div>
                          </CardHolder>
                        );
                      })}
                      {console.log('ARRAY', arr)}
                    </InputWrapper>
                    {!loading && (
                      <AddMoreSection
                        style={{
                          flexDirection: screenWidth < 800 ? 'column' : 'row',
                          justifyContent: screenWidth > 800 ? 'space-between' : ''
                        }}>
                        <p>{ShowError && '* Please Fill All the mandatory fields'}</p>
                        <span
                          onClick={(e) => {
                            if (!checkFilled()) {
                              checkFilledUpdate();
                              setShowError(true);
                            } else {
                              if ((Purchase.planid || Purchase.duration) && id) {
                                setFamilyArr((prev) => [
                                  ...prev,
                                  {
                                    name: '',
                                    email: '',
                                    plan: '',
                                    select: true,
                                    planPurchased: false,
                                    relation: '',
                                    isValidPhone: false,
                                    countrycode: { code: '+91', name: 'India' },
                                    sucess: '',
                                    fail: '',
                                    errorForEmail: '',
                                    isValidMail: false,
                                    borderForName: false,
                                    borderForEmail: false,
                                    borderForPhone: false,
                                    borderForRelation: false,
                                    borderForPlan: false,
                                    borderForDuration: false
                                  }
                                ]);
                              } else if (id && PhoneNumber.length >= 8 && PrimaryPhoneValid) {
                                setFamilyArr((prev) => [
                                  ...prev,
                                  {
                                    name: '',
                                    email: '',
                                    plan: '',
                                    select: true,
                                    planPurchased: false,
                                    plan_price: 0,
                                    plan_id: '-1',
                                    plan: '',
                                    relation: '',
                                    isValidPhone: false,
                                    countrycode: { code: '+91', name: 'India' },
                                    sucess: '',
                                    fail: '',
                                    errorForEmail: '',
                                    isValidMail: false,
                                    borderForName: false,
                                    borderForEmail: false,
                                    borderForPhone: false,
                                    borderForRelation: false,
                                    borderForPlan: false,
                                    borderForDuration: false
                                  }
                                ]);
                              } else {
                                setPhoneError(true);
                                setShowError(true);
                              }
                            }
                          }}>
                          + Add Family Member
                        </span>
                      </AddMoreSection>
                    )}
                    {!loading ? (
                      <div
                        style={{
                          width: '100%',
                          margin: 'auto',
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                        {includeUser || selectedFamilyCount() !== 0 ? (
                          CheckVersion && selectedFamilyCount() === 0 && EMIOption ? (
                            <>
                              <button
                                className="paymentButton"
                                onClick={(e) => {
                                  if (!checkFilled()) {
                                    return alert('Please fill all the fields');
                                  }
                                  setShowTermsAndCondition(true);
                                }}>
                                Pay Through EMI
                              </button>
                              <button className="paymentButton" onClick={submitFamily}>
                                Pay Now
                              </button>
                            </>
                          ) : (
                            <button
                              className="paymentButton"
                              onClick={() => {
                                if (
                                  GrandTotal === 0 &&
                                  FamilyArrFormated().every(
                                    (data) =>
                                      data.plan_id === String(idOfDNA.package_id) &&
                                      data.plan_price === 0
                                  )
                                ) {
                                  if (!checkFilled()) {
                                    checkFilledUpdate();
                                  } else {
                                    redeemOffer();
                                  }
                                } else {
                                  if (!checkFilled()) {
                                    checkFilledUpdate();
                                  } else {
                                    submitFamily();
                                  }
                                }
                              }}>
                              {GrandTotal === 0 &&
                              FamilyArrFormated().every(
                                (data) =>
                                  data.plan_id === String(idOfDNA.package_id) &&
                                  data.plan_price === 0
                              )
                                ? 'Get The Offer'
                                : 'Proceed to Payment'}
                            </button>
                          )
                        ) : null}
                      </div>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                        <ReactLoading
                          type={'spin'}
                          color="#000"
                          style={{
                            width: '3rem',
                            height: '3rem'
                          }}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <h2>My Cart</h2>
                    <InputWrapper>
                      {console.log('PR', product)}
                      <CardHolder style={{ width: '100%' }}>
                        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%'
                            }}>
                            <input
                              type="checkbox"
                              id="value"
                              name="value"
                              checked={includeUser}
                              disabled={Purchase.planid || Purchase.duration}
                              onChange={(e) => {
                                setIncludeUser(e.target.checked);
                                if (
                                  !e.target.checked &&
                                  restProps.IsValid &&
                                  restProps.product === idOfMe.package_id
                                ) {
                                  let removedOne = false;

                                  const newArr = familyArr.filter((object, index) => {
                                    if (
                                      index === 0 &&
                                      object.plan_id === String(idOfDNA.package_id) &&
                                      object.isAddedByOffer &&
                                      !removedOne
                                    ) {
                                      removedOne = true;
                                      return false;
                                    }
                                    return true;
                                  });
                                  setFamilyArr(newArr);
                                }
                                if (
                                  restProps.product === idOfMe.package_id &&
                                  e.target.checked &&
                                  restProps.IsValid
                                ) {
                                  setIsThisIndex(null);
                                  restProps.setOpenMessage(true);
                                }
                              }}
                            />
                            <div className="label" style={{ width: '10rem' }}>
                              <label for="name" style={{ fontSize: '10px', marginLeft: '10px' }}>
                                Name
                              </label>
                              <input
                                type="text"
                                placeholder="name"
                                id="name"
                                value={name}
                                disabled
                                onChange={(e) =>
                                  filterChange(
                                    index,
                                    'name',
                                    e.target.value.replace(/[^a-zA-Z0-9]/g, '')
                                  )
                                }></input>
                              {Purchase.planid || Purchase.duration ? (
                                <p
                                  style={{
                                    fontWeight: '0',
                                    fontSize: '10px',
                                    margin: '0',
                                    padding: '0',
                                    width: '200%',
                                    overflow: 'visible'
                                  }}>
                                  Plan Already Purchased
                                </p>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="label" style={{ width: '23%' }}>
                              <label for="name" style={{ fontSize: '10px', marginLeft: '10px' }}>
                                Email
                              </label>
                              <input type="text" placeholder="email" disabled value={email}></input>
                            </div>
                            <div className="label" style={{ width: '3.5%' }}>
                              <div className="label1">
                                <label for="name" style={{ fontSize: '10px', marginLeft: '10px' }}>
                                  Phone
                                </label>
                                <Autocomplete
                                  id="phone"
                                  autoHighlight={true}
                                  options={CountryCode}
                                  getOptionLabel={(option) => option.code}
                                  value={selectedCountryCode}
                                  disabled={Purchase.planid || Purchase.duration}
                                  onChange={(event, newValue) => {
                                    setSelectedCountryCode(newValue);
                                  }}
                                  filterOptions={(options, state) => {
                                    return options.filter(
                                      (option) =>
                                        option.name
                                          .toLowerCase()
                                          .includes(state.inputValue.toLowerCase()) ||
                                        option.code
                                          .replace(/\D/g, '')
                                          .toLowerCase()
                                          .includes(state.inputValue.toLowerCase()) ||
                                        option.code
                                          .toLowerCase()
                                          .includes(state.inputValue.toLowerCase())
                                    );
                                  }}
                                  PopperComponent={({ children, ...other }) => (
                                    <Popper {...other}>
                                      <Paper style={{ width: '300px' }}>{children}</Paper>
                                    </Popper>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label=""
                                      variant="outlined"
                                      InputProps={{
                                        ...params.InputProps,
                                        style: {
                                          height: '44px',
                                          padding: '0px',
                                          '&:hover fieldset': {
                                            borderColor: '#c4c4c4'
                                          }
                                        },
                                        endAdornment: null
                                      }}
                                    />
                                  )}
                                  renderOption={(props, option) => (
                                    <li style={{ fontSize: 15 }} {...props}>
                                      <img src={option.flag} alt={option.name} />
                                      {`${option.code} ${option.name}`}
                                    </li>
                                  )}
                                  disableClearable={true}
                                  style={{
                                    backgroundColor: '#fff',
                                    marginTop: 4,
                                    borderRadius: 5
                                  }}>
                                  <option value="" key="placeholder">
                                    Select a country
                                  </option>
                                </Autocomplete>
                                <p
                                  style={{
                                    position: 'absolute',
                                    fontWeight: '0',
                                    fontSize: '10px',
                                    margin: '0',
                                    padding: '0',
                                    overflow: 'visible',
                                    width: 500,
                                    color: SuccessMsg ? '#07d507' : 'red'
                                  }}>
                                  {SuccessMsg || WarnMsg}
                                </p>
                              </div>
                            </div>
                            <div className="label" style={{ width: '10%' }}>
                              <div className="label1">
                                <input
                                  type="text"
                                  placeholder="Phone"
                                  value={PhoneNumber}
                                  disabled={Purchase.planid || Purchase.duration}
                                  onChange={(e) => {
                                    const phoneNumber = e.target.value.replace(/\D/g, '');
                                    setPrimaryPhoneValid(false);
                                    setSuccessMsg('');
                                    setWarnMsg('');
                                    setPhoneNumber(phoneNumber);
                                    setPhoneError(false);
                                    if (selectedCountryCode?.code?.length === 0) {
                                      setWarnMsg('Select a Country Code');
                                    } else if (
                                      phoneNumber?.length >= 8 &&
                                      selectedCountryCode?.code?.length !== 0
                                    ) {
                                      checkPhone(phoneNumber);
                                    }
                                  }}
                                  style={{
                                    marginTop: 24.33,
                                    width: '88%',
                                    border: PhoneError ? '1px solid red' : '1px solid #fff0'
                                  }}></input>
                              </div>
                            </div>
                            <div className="label" style={{ width: '10%', marginLeft: '10px' }}>
                              <label for="name" style={{ fontSize: '10px', marginLeft: '10px' }}>
                                Relation
                              </label>
                              <input
                                id="relation"
                                style={{ minWidth: '119.01px' }}
                                disabled
                                value={'Primary User'}></input>
                            </div>
                            <div className="label" style={{ width: '20%' }}>
                              <label for="name" style={{ fontSize: '10px', marginLeft: '10px' }}>
                                Plan
                              </label>
                              <input
                                id="plan"
                                disabled
                                value={
                                  PlanPurchase.planName ? PlanPurchase.planName : restProps.planName
                                }></input>
                            </div>
                            <div className="label" style={{ width: '10%' }}>
                              {hasDuration(
                                PlanPurchase.duration
                                  ? Purchase.planid
                                  : Purchase.planid || restProps.product
                              ) && (
                                <div className="label1">
                                  <label
                                    for="name"
                                    style={{ fontSize: '10px', marginLeft: '10px' }}>
                                    Duration
                                  </label>
                                  <select
                                    name="duration"
                                    id="duration"
                                    disabled={
                                      PlanPurchase.duration ? PlanPurchase.duration : !includeUser
                                    }
                                    value={
                                      PlanPurchase.duration ? PlanPurchase.duration : durationId
                                    }
                                    onChange={handleUserDurationChange}>
                                    {getDurations(
                                      PlanPurchase.duration ? Purchase.planid : restProps.product
                                    ).map((dur) => {
                                      return (
                                        <option value={dur.id}>
                                          {dur.life + ' ' + dur.duration_type}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              )}
                            </div>
                            <div className="label2">
                              <label
                                for="name"
                                style={{
                                  fontSize: '10px',
                                  marginLeft: '10px',
                                  textAlign: 'center'
                                }}>
                                Price
                              </label>
                              <p style={{ fontSize: '16px', textAlign: 'center' }}>
                                ₹ {PlanPurchase.planPrice ? PlanPurchase.planPrice : userPrice}
                              </p>
                            </div>
                          </div>
                          {/* <p
                        style={{
                          fontWeight: '0',
                          fontSize: '10px',
                          margin: '0',
                          padding: '0',
                          overflow: 'visible',
                          color: SuccessMsg ? '#07d507' : 'red'
                        }}>
                        {SuccessMsg || WarnMsg}
                      </p> */}
                        </div>
                      </CardHolder>
                      {familyArr.map((val, index) => {
                        if (val.relation === 'Primary User') {
                          return null;
                        }
                        console.log('val', val);
                        return (
                          <CardHolder style={{ width: '100%' }}>
                            <div
                              style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%'
                                }}>
                                {val.planPurchased ? (
                                  <input
                                    type="checkbox"
                                    id="value"
                                    name="value"
                                    value="Bike"
                                    defaultChecked
                                    disabled={true}
                                    checked={true}
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    id="value"
                                    name="value"
                                    value="Bike"
                                    defaultChecked
                                    disabled={val.planPurchased ? true : false}
                                    checked={val.select}
                                    onChange={(e) => {
                                      if (!e.target.checked) {
                                        setFamilyArr((prev) => {
                                          const updatedArr = [...prev];
                                          updatedArr[index] = {
                                            ...updatedArr[index],
                                            borderForName: val.name ? false : true,
                                            borderForEmail: val.email ? false : true,
                                            borderForRelation:
                                              !val.relation || val.relation === '-1' ? true : false
                                          };
                                          return updatedArr;
                                        });
                                        if (
                                          !val.name ||
                                          !val.email ||
                                          !val.relation ||
                                          val.relation === '-1'
                                        ) {
                                          setShowError(true);
                                          return;
                                        }
                                      }
                                      filterChange(index, 'select', e.target.checked);
                                      setFamilyArr((prev) => {
                                        const updatedArr = [...prev];
                                        updatedArr[index] = {
                                          ...updatedArr[index],
                                          borderForDuration: false,
                                          borderForEmail: false,
                                          borderForName: false,
                                          borderForPhone: false,
                                          borderForRelation: false,
                                          borderForPlan: false
                                        };
                                        return updatedArr;
                                      });
                                      if (
                                        val.plan_id === String(idOfMe.package_id) &&
                                        !e.target.checked &&
                                        restProps.IsValid
                                      ) {
                                        const selectedIndex = index;
                                        if (
                                          selectedIndex !== -1 &&
                                          selectedIndex < familyArr.length - 1 &&
                                          familyArr[selectedIndex + 1].plan_id ===
                                            String(idOfDNA.package_id) &&
                                          (familyArr[selectedIndex + 1].isAddedByOffer ||
                                            familyArr[selectedIndex + 1].plan_price === 0)
                                        ) {
                                          const newArr = [...familyArr];
                                          const indexToRemove = selectedIndex + 1;

                                          newArr.splice(indexToRemove, 1);
                                          setFamilyArr(newArr);
                                        }
                                      }
                                      if (
                                        val.plan_id === String(idOfMe.package_id) &&
                                        e.target.checked &&
                                        restProps.IsValid
                                      ) {
                                        restProps.setOpenMessage(true);
                                        setIsThisIndex(index);
                                      }
                                      if (e.target.checked) {
                                        totalCnt += 1;
                                      } else totalCnt -= 1;
                                    }}
                                  />
                                )}
                                <div className="label" style={{ width: '10rem' }}>
                                  <label
                                    for="name"
                                    style={{ fontSize: '10px', marginLeft: '10px' }}>
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="name"
                                    id="name"
                                    value={val?.name}
                                    style={{
                                      border: familyArr[index].borderForName && '1px solid red'
                                    }}
                                    disabled={!familyArr[index]?.select}
                                    onChange={(e) => {
                                      filterChange(
                                        index,
                                        'name',
                                        e.target.value.replace(/[^a-zA-Z0-9 ]/g, '')
                                      );
                                      if (
                                        val.plan_id === String(idOfMe.package_id) &&
                                        restProps.IsValid
                                      ) {
                                        const selectedIndex = index;
                                        if (
                                          selectedIndex !== -1 &&
                                          selectedIndex < familyArr.length - 1 &&
                                          familyArr[selectedIndex + 1].plan_id ===
                                            String(idOfDNA.package_id) &&
                                          familyArr[selectedIndex + 1].isAddedByOffer
                                        ) {
                                          const newArr = [...familyArr];
                                          const indexToRemove = selectedIndex + 1;

                                          newArr.splice(indexToRemove, 1);
                                          setFamilyArr(newArr);
                                        }
                                      }
                                      setFamilyArr((prev) => {
                                        const updatedArr = [...prev];
                                        updatedArr[index] = {
                                          ...updatedArr[index],
                                          borderForName: false,
                                          plan_id: val.isAddedByOffer
                                            ? String(idOfDNA.package_id)
                                            : '-1',
                                          plan_price: 0
                                        };
                                        return updatedArr;
                                      });
                                    }}></input>
                                  {val.planPurchased && (
                                    <p
                                      style={{
                                        fontWeight: '0',
                                        fontSize: '10px',
                                        margin: '0',
                                        padding: '0',
                                        width: '100%',
                                        overflow: 'visible'
                                      }}>
                                      {val.planPurchasedMessage}
                                    </p>
                                  )}
                                </div>
                                <div className="label" style={{ width: '23%' }}>
                                  <label
                                    for="name"
                                    style={{ fontSize: '10px', marginLeft: '10px' }}>
                                    Email
                                  </label>
                                  <input
                                    type="text"
                                    placeholder="email"
                                    value={val?.email}
                                    style={{
                                      border: familyArr[index].borderForEmail && '1px solid red'
                                    }}
                                    onChange={(e) => {
                                      filterChange(index, 'email', e.target.value.toLowerCase());
                                      if (
                                        val.plan_id === String(idOfMe.package_id) &&
                                        restProps.IsValid
                                      ) {
                                        const selectedIndex = index;
                                        if (
                                          selectedIndex !== -1 &&
                                          selectedIndex < familyArr.length - 1 &&
                                          familyArr[selectedIndex + 1].plan_id ===
                                            String(idOfDNA.package_id) &&
                                          familyArr[selectedIndex + 1].isAddedByOffer
                                        ) {
                                          const newArr = [...familyArr];
                                          const indexToRemove = selectedIndex + 1;

                                          newArr.splice(indexToRemove, 1);
                                          setFamilyArr(newArr);
                                        }
                                      }
                                      setFamilyArr((prev) => {
                                        const updatedArr = [...prev];
                                        updatedArr[index] = {
                                          ...updatedArr[index],
                                          plan_id: val.isAddedByOffer
                                            ? String(idOfDNA.package_id)
                                            : '-1',
                                          plan_price: 0,
                                          planPurchased: false,
                                          borderForEmail: false,
                                          planPurchasedMessage: ''
                                        };
                                        return updatedArr;
                                      });
                                    }}
                                    disabled={!familyArr[index]?.select}></input>
                                  <div
                                    style={{
                                      fontWeight: '0',
                                      fontSize: '10px',
                                      margin: '0',
                                      padding: '0',
                                      overflow: 'visible',
                                      color: 'red'
                                    }}>
                                    {familyArr[index]?.errorForEmail}
                                  </div>
                                </div>
                                <div className="label" style={{ width: '3.5%' }}>
                                  {familyArr[index]?.relation !== 'Child' &&
                                    familyArr[index]?.relation !== 'child' && (
                                      <div className="label1" style={{ position: 'relative' }}>
                                        <label
                                          for="name"
                                          style={{ fontSize: '10px', marginLeft: '10px' }}>
                                          Phone
                                        </label>
                                        <Autocomplete
                                          id="phone"
                                          autoHighlight={true}
                                          disabled={!familyArr[index]?.select}
                                          options={CountryCode}
                                          getOptionLabel={(option) => option.code}
                                          value={val?.countrycode}
                                          onChange={(event, newValue) => {
                                            filterChange(index, 'countrycode', newValue);
                                            setFamilyArr((prev) => {
                                              const updatedArr = [...prev];
                                              updatedArr[index] = {
                                                ...updatedArr[index],
                                                fail: '',
                                                success: ''
                                              };
                                              return updatedArr;
                                            });
                                          }}
                                          filterOptions={(options, state) => {
                                            return options.filter(
                                              (option) =>
                                                option.name
                                                  .toLowerCase()
                                                  .includes(state.inputValue.toLowerCase()) ||
                                                option.code
                                                  .replace(/\D/g, '')
                                                  .toLowerCase()
                                                  .includes(state.inputValue.toLowerCase()) ||
                                                option.code
                                                  .toLowerCase()
                                                  .includes(state.inputValue.toLowerCase())
                                            );
                                          }}
                                          PopperComponent={({ children, ...other }) => (
                                            <Popper {...other}>
                                              <Paper style={{ width: '300px' }}>{children}</Paper>
                                            </Popper>
                                          )}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label=""
                                              variant="outlined"
                                              InputProps={{
                                                ...params.InputProps,
                                                style: {
                                                  height: '44px',
                                                  padding: '0px',
                                                  '&:hover fieldset': {
                                                    borderColor: '#c4c4c4'
                                                  }
                                                },
                                                endAdornment: null
                                              }}
                                            />
                                          )}
                                          renderOption={(props, option) => (
                                            <li style={{ fontSize: 15 }} {...props}>
                                              <img src={option.flag} alt={option.name} />
                                              {`${option.code} ${option.name}`}
                                            </li>
                                          )}
                                          disableClearable={true}
                                          style={{
                                            backgroundColor: '#fff',
                                            marginTop: 4,
                                            borderRadius: 5
                                          }}>
                                          <option value="" key="placeholder">
                                            Select a country
                                          </option>
                                        </Autocomplete>
                                        <p
                                          style={{
                                            position: 'absolute',
                                            fontWeight: '0',
                                            fontSize: '10px',
                                            margin: '0',
                                            padding: '0',
                                            overflow: 'visible',
                                            width: 500,
                                            color: familyArr[index].sucess ? '#07d507' : 'red'
                                          }}>
                                          {familyArr[index].sucess || familyArr[index].fail}
                                        </p>
                                      </div>
                                    )}
                                </div>
                                <div className="label" style={{ width: '10%' }}>
                                  {familyArr[index]?.relation !== 'Child' &&
                                    familyArr[index]?.relation !== 'child' && (
                                      <div className="label1">
                                        <input
                                          type="text"
                                          placeholder="Phone"
                                          value={val?.phone}
                                          style={{
                                            border:
                                              familyArr[index].borderForPhone && '1px solid red',
                                            marginTop: 24.33,
                                            width: '88%'
                                          }}
                                          onPaste={(e) => {
                                            e.preventDefault();
                                          }}
                                          onChange={(e) => {
                                            const phoneNumber = e.target.value.replace(/\D/g, '');
                                            if (
                                              val.plan_id === String(idOfMe.package_id) &&
                                              restProps.IsValid
                                            ) {
                                              const selectedIndex = index;
                                              if (
                                                selectedIndex !== -1 &&
                                                selectedIndex < familyArr.length - 1 &&
                                                familyArr[selectedIndex + 1].plan_id ===
                                                  String(idOfDNA.package_id) &&
                                                familyArr[selectedIndex + 1].isAddedByOffer
                                              ) {
                                                const newArr = [...familyArr];
                                                const indexToRemove = selectedIndex + 1;

                                                newArr.splice(indexToRemove, 1);
                                                setFamilyArr(newArr);
                                              }
                                            }
                                            setFamilyArr((prev) => {
                                              const updatedArr = [...prev];
                                              updatedArr[index] = {
                                                ...updatedArr[index],
                                                borderForPhone: false,
                                                isValidPhone: false,
                                                plan_id: val.isAddedByOffer
                                                  ? String(idOfDNA.package_id)
                                                  : '-1',
                                                plan_price: 0
                                              };
                                              return updatedArr;
                                            });
                                            if (!val?.isValidMail || !val?.name) {
                                              setShowError(true);
                                              setFamilyArr((prev) => {
                                                const updatedArr = [...prev];
                                                updatedArr[index] = {
                                                  ...updatedArr[index],
                                                  phone: '',
                                                  borderForEmail:
                                                    !val?.email || !val?.isValidMail ? true : false,
                                                  borderForName: !val?.name ? true : false
                                                };
                                                return updatedArr;
                                              });
                                              return;
                                            } else {
                                              if (val?.countrycode?.code?.length === 0) {
                                                setFamilyArr((prev) => {
                                                  const updatedArr = [...prev];
                                                  updatedArr[index] = {
                                                    ...updatedArr[index],
                                                    fail: 'Select a Country Code'
                                                  };
                                                  return updatedArr;
                                                });
                                              } else {
                                                setFamilyArr((prev) => {
                                                  const updatedArr = [...prev];
                                                  updatedArr[index] = {
                                                    ...updatedArr[index],
                                                    phone: phoneNumber,
                                                    fail: '',
                                                    sucess: ''
                                                  };
                                                  return updatedArr;
                                                });
                                              }
                                              const isDuplicateInArray = familyArr
                                                .filter((_, i) => i !== index)
                                                .some((prev) => prev.phone === phoneNumber);
                                              const isDuplicateWithPhoneNumber =
                                                PhoneNumber === phoneNumber;
                                              if (
                                                isDuplicateInArray ||
                                                isDuplicateWithPhoneNumber
                                              ) {
                                                setFamilyArr((prev) => {
                                                  const updatedArr = [...prev];
                                                  updatedArr[index] = {
                                                    ...updatedArr[index],
                                                    fail: 'Phone number already registered with us. Please provide an alternative phone number.'
                                                  };
                                                  return updatedArr;
                                                });
                                              } else {
                                                setFamilyArr((prev) => {
                                                  const updatedArr = [...prev];
                                                  updatedArr[index] = {
                                                    ...updatedArr[index],
                                                    fail: ''
                                                  };
                                                  return updatedArr;
                                                });
                                                if (
                                                  phoneNumber.length >= 8 &&
                                                  val?.countrycode?.code?.length !== 0
                                                ) {
                                                  checkPhoneExist(
                                                    index,
                                                    phoneNumber,
                                                    val?.countrycode.code,
                                                    val?.email
                                                  );
                                                }
                                              }
                                            }
                                          }}
                                          disabled={!familyArr[index]?.select}></input>
                                      </div>
                                    )}
                                </div>
                                <div className="label" style={{ width: '10%', marginLeft: '10px' }}>
                                  <label
                                    for="name"
                                    style={{ fontSize: '10px', marginLeft: '10px' }}>
                                    Relation
                                  </label>
                                  <select
                                    id="relation"
                                    disabled={!familyArr[index]?.select}
                                    value={val?.relation}
                                    style={{
                                      border: familyArr[index].borderForRelation && '1px solid red'
                                    }}
                                    onChange={(e) => {
                                      if (e.target.value === 'Child') {
                                        delete familyArr[index].isValidPhone;
                                        delete familyArr[index].countrycode;
                                        delete familyArr[index].phone;
                                      } else {
                                        familyArr[index].countrycode = val.countrycode
                                          ? val.countrycode
                                          : {
                                              code: '+91',
                                              name: 'India'
                                            };
                                        if (
                                          val.plan_id === String(idOfMe.package_id) &&
                                          restProps.IsValid
                                        ) {
                                          const selectedIndex = index;
                                          if (
                                            selectedIndex !== -1 &&
                                            selectedIndex < familyArr.length - 1 &&
                                            familyArr[selectedIndex + 1].plan_id ===
                                              String(idOfDNA.package_id) &&
                                            familyArr[selectedIndex + 1].isAddedByOffer
                                          ) {
                                            const newArr = [...familyArr];
                                            const indexToRemove = selectedIndex + 1;

                                            newArr.splice(indexToRemove, 1);
                                            setFamilyArr(newArr);
                                          }
                                        }
                                        familyArr[index].phone = val.phone ? val.phone : '';
                                        if (!val?.isValidMail || !val.name || !val.isValidPhone) {
                                          setShowError(true);
                                          setFamilyArr((prev) => {
                                            const updatedArr = [...prev];
                                            updatedArr[index] = {
                                              ...updatedArr[index],
                                              relation: '',
                                              borderForName: !val?.name ? true : false,
                                              borderForEmail:
                                                !val?.email || !val?.isValidMail ? true : false,
                                              borderForPhone:
                                                !val?.phone || !val.isValidPhone ? true : false
                                            };
                                            return updatedArr;
                                          });
                                          return;
                                        }
                                      }
                                      filterChange(index, 'relation', e.target.value);
                                      setFamilyArr((prev) => {
                                        const updatedArr = [...prev];
                                        updatedArr[index] = {
                                          ...updatedArr[index],
                                          borderForRelation: false,
                                          plan_price: 0
                                        };
                                        return updatedArr;
                                      });
                                      familyArr[index].plan_id = val.isAddedByOffer
                                        ? String(idOfDNA.package_id)
                                        : '-1';
                                    }}>
                                    <option value="" disabled>
                                      Choose
                                    </option>
                                    <option value="Parent">Parent</option>
                                    <option value="Sibling">Sibling</option>
                                    <option value="Child">Child</option>
                                    <option value="Friend">Friend</option>
                                    <option value="Cousin">Cousin</option>
                                    <option value="Spouse">Spouse</option>
                                  </select>
                                </div>
                                <Tooltip title={val?.plan ? val?.plan : ''} placement="top" arrow>
                                  <div className="label" style={{ width: '20%' }}>
                                    <label
                                      for="name"
                                      style={{ fontSize: '10px', marginLeft: '10px' }}>
                                      Plan
                                    </label>
                                    <select
                                      id="plan"
                                      style={{
                                        border: familyArr[index].borderForPlan && '1px solid red'
                                      }}
                                      disabled={!familyArr[index]?.select || val.isAddedByOffer}
                                      value={val?.plan_id}
                                      onChange={(e) => {
                                        if (val?.relation === 'Child') {
                                          if (!val?.isValidMail || !val.name || !val.relation) {
                                            setShowError(true);
                                            setFamilyArr((prev) => {
                                              const updatedArr = [...prev];
                                              updatedArr[index] = {
                                                ...updatedArr[index],
                                                plan_id: '-1',
                                                plan: '',
                                                borderForName: !val?.name ? true : false,
                                                borderForRelation: !val?.relation ? true : false,
                                                borderForEmail:
                                                  !val?.email || !val?.isValidMail ? true : false
                                              };
                                              return updatedArr;
                                            });
                                            return;
                                          }
                                          if (
                                            (e.target.value === String(idOfDNA.package_id) ||
                                              e.target.value !== String(idOfMe.package_id)) &&
                                            restProps.IsValid &&
                                            val.plan_id === String(idOfMe.package_id)
                                          ) {
                                            const selectedIndex = index;
                                            if (
                                              selectedIndex !== -1 &&
                                              selectedIndex < familyArr.length - 1 &&
                                              familyArr[selectedIndex + 1].plan_id ===
                                                String(idOfDNA.package_id) &&
                                              familyArr[selectedIndex + 1].isAddedByOffer
                                            ) {
                                              const newArr = [...familyArr];
                                              const indexToRemove = selectedIndex + 1;

                                              newArr.splice(indexToRemove, 1);
                                              setFamilyArr(newArr);
                                            }
                                            familyArr[index].plan_price = Number(
                                              getPriceForProduct(e.target.value)
                                            );
                                            familyArr[index].plan_id = e.target.value;
                                          } else {
                                            filterChange(index, 'plan_id', e.target.value);
                                            filterChange(
                                              index,
                                              'plan',
                                              e.target.selectedOptions[0].textContent
                                            );
                                          }
                                          setFamilyArr((prev) => {
                                            const updatedArr = [...prev];
                                            updatedArr[index] = {
                                              ...updatedArr[index],
                                              borderForPlan: false,
                                              borderForDuration: false,
                                              duration_id: '-1'
                                            };
                                            return updatedArr;
                                          });
                                        } else {
                                          if (
                                            !val?.isValidMail ||
                                            !val.name ||
                                            !val.isValidPhone ||
                                            !val.relation
                                          ) {
                                            setShowError(true);
                                            setFamilyArr((prev) => {
                                              const updatedArr = [...prev];
                                              updatedArr[index] = {
                                                ...updatedArr[index],
                                                plan_id: '-1',
                                                plan: '',
                                                borderForName: !val?.name ? true : false,
                                                borderForRelation: !val?.relation ? true : false,
                                                borderForEmail:
                                                  !val?.email || !val?.isValidMail ? true : false,
                                                borderForPhone:
                                                  !val?.phone || !val.isValidPhone ? true : false
                                              };
                                              return updatedArr;
                                            });
                                            return;
                                          }
                                          if (
                                            (e.target.value === String(idOfDNA.package_id) ||
                                              e.target.value !== String(idOfMe.package_id)) &&
                                            restProps.IsValid &&
                                            val.plan_id === String(idOfMe.package_id)
                                          ) {
                                            const selectedIndex = index;
                                            if (
                                              selectedIndex !== -1 &&
                                              selectedIndex < familyArr.length - 1 &&
                                              familyArr[selectedIndex + 1].plan_id ===
                                                String(idOfDNA.package_id) &&
                                              familyArr[selectedIndex + 1].isAddedByOffer
                                            ) {
                                              const newArr = [...familyArr];
                                              const indexToRemove = selectedIndex + 1;

                                              newArr.splice(indexToRemove, 1);
                                              setFamilyArr(newArr);
                                            }
                                            familyArr[index].plan_price = Number(
                                              getPriceForProduct(e.target.value)
                                            );
                                            familyArr[index].plan_id = e.target.value;
                                          } else {
                                            filterChange(index, 'plan_id', e.target.value);
                                            filterChange(
                                              index,
                                              'plan',
                                              e.target.selectedOptions[0].textContent
                                            );
                                          }
                                          setFamilyArr((prev) => {
                                            const updatedArr = [...prev];
                                            updatedArr[index] = {
                                              ...updatedArr[index],
                                              borderForPlan: false,
                                              borderForDuration: false,
                                              duration_id: '-1'
                                            };
                                            return updatedArr;
                                          });
                                        }
                                      }}>
                                      <option value="-1" selected disabled>
                                        Choose
                                      </option>
                                      {restProps.productList.map((pro) => (
                                        <option value={pro.package_id}>
                                          {pro.package_title}
                                          {pro.package_id === idOfDNA.package_id ? ' ®' : ''}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </Tooltip>
                                <div className="label" style={{ width: '10%' }}>
                                  {(val.isAddedByOffer || restProps.IsValid) &&
                                    val.plan_id === String(idOfDNA.package_id) &&
                                    val.plan_price === 0 && (
                                      <div
                                        style={{
                                          fontSize: 15,
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          height: '100%'
                                        }}>
                                        Offer Redeemed
                                      </div>
                                    )}
                                  {hasDuration(val.plan_id) && (
                                    <div className="label1">
                                      <label
                                        for="name"
                                        style={{
                                          fontSize: '10px',
                                          marginLeft: '10px'
                                        }}>
                                        Duration
                                      </label>
                                      <select
                                        name="duration"
                                        id="duration"
                                        style={{
                                          border:
                                            familyArr[index].borderForDuration && '1px solid red'
                                        }}
                                        disabled={!familyArr[index]?.select}
                                        onChange={(e) => {
                                          if (!val?.isValidMail || !val.name || !val.relation) {
                                            setShowError(true);
                                            setFamilyArr((prev) => {
                                              const updatedArr = [...prev];
                                              updatedArr[index] = {
                                                ...updatedArr[index],
                                                plan_id: '-1',
                                                plan: '',
                                                borderForName: !val?.name ? true : false,
                                                borderForRelation: !val?.relation ? true : false,
                                                borderForEmail:
                                                  !val?.email || !val?.isValidMail ? true : false
                                              };
                                              return updatedArr;
                                            });
                                            return;
                                          }
                                          filterChange(index, 'duration_id', e.target.value);
                                          setFamilyArr((prev) => {
                                            const updatedArr = [...prev];
                                            updatedArr[index] = {
                                              ...updatedArr[index],
                                              borderForDuration: false
                                            };
                                            return updatedArr;
                                          });
                                        }}
                                        value={val?.duration_id}>
                                        <option value="-1" selected disabled>
                                          Choose
                                        </option>
                                        {getDurations(val.plan_id).map((dur) => {
                                          return (
                                            <option value={dur.id}>
                                              {dur.life + ' ' + dur.duration_type}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  )}
                                </div>
                                <div className="label2">
                                  <label
                                    for="name"
                                    style={{
                                      fontSize: '10px',
                                      marginLeft: '10px',
                                      textAlign: 'center'
                                    }}>
                                    Price
                                  </label>
                                  <p style={{ fontSize: '16px', textAlign: 'center' }}>
                                    ₹{' '}
                                    {val.plan_price === undefined || val.plan_price === ' '
                                      ? 0
                                      : val.plan_price}
                                  </p>
                                </div>
                              </div>
                              {/* <p
                            style={{
                              fontWeight: '0',
                              fontSize: '10px',
                              margin: '0',
                              padding: '0',
                              overflow: 'visible',
                              color: val.sucess ? '#07d507' : 'red'
                            }}>
                            {val.sucess || val.fail}
                          </p> */}
                            </div>
                          </CardHolder>
                        );
                      })}
                    </InputWrapper>
                    {!loading && (
                      <AddMoreSection
                        style={{
                          flexDirection: screenWidth < 800 ? 'column' : 'row',
                          justifyContent: screenWidth > 800 ? 'space-between' : ''
                        }}>
                        <p>{ShowError && '* Please Fill All the mandatory fields'}</p>
                        <span
                          onClick={(e) => {
                            if (!checkFilled()) {
                              checkFilledUpdate();
                              setShowError(true);
                            } else {
                              if ((Purchase.planid || Purchase.duration) && id) {
                                setFamilyArr((prev) => [
                                  ...prev,
                                  {
                                    name: '',
                                    email: '',
                                    plan: '',
                                    select: true,
                                    planPurchased: false,
                                    relation: '',
                                    isValidPhone: false,
                                    countrycode: { code: '+91', name: 'India' },
                                    sucess: '',
                                    fail: '',
                                    errorForEmail: '',
                                    isValidMail: false,
                                    borderForName: false,
                                    borderForEmail: false,
                                    borderForPhone: false,
                                    borderForRelation: false,
                                    borderForPlan: false,
                                    borderForDuration: false
                                  }
                                ]);
                              } else if (id && PhoneNumber.length >= 8 && PrimaryPhoneValid) {
                                setFamilyArr((prev) => [
                                  ...prev,
                                  {
                                    name: '',
                                    email: '',
                                    plan: '',
                                    select: true,
                                    planPurchased: false,
                                    plan_price: 0,
                                    plan_id: '-1',
                                    plan: '',
                                    relation: '',
                                    isValidPhone: false,
                                    countrycode: { code: '+91', name: 'India' },
                                    sucess: '',
                                    fail: '',
                                    errorForEmail: '',
                                    isValidMail: false,
                                    borderForName: false,
                                    borderForEmail: false,
                                    borderForPhone: false,
                                    borderForRelation: false,
                                    borderForPlan: false,
                                    borderForDuration: false
                                  }
                                ]);
                              } else {
                                setPhoneError(true);
                                setShowError(true);
                              }
                            }
                          }}>
                          + Add Family Member
                        </span>
                      </AddMoreSection>
                    )}
                    {!loading ? (
                      <div
                        style={{
                          width: '100%',
                          margin: 'auto',
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                        {includeUser || selectedFamilyCount() !== 0 ? (
                          CheckVersion && selectedFamilyCount() === 0 && EMIOption ? (
                            <>
                              <button
                                className="paymentButton"
                                onClick={(e) => {
                                  if (!checkFilled()) {
                                    return alert('Please fill all the fields');
                                  }
                                  setShowTermsAndCondition(true);
                                }}>
                                Pay Through EMI
                              </button>
                              <button className="paymentButton" onClick={submitFamily}>
                                Pay Now
                              </button>
                            </>
                          ) : (
                            <button
                              className="paymentButton"
                              onClick={() => {
                                if (
                                  GrandTotal === 0 &&
                                  FamilyArrFormated().every(
                                    (data) =>
                                      data.plan_id === String(idOfDNA.package_id) &&
                                      data.plan_price === 0
                                  )
                                ) {
                                  if (!checkFilled()) {
                                    checkFilledUpdate();
                                  } else {
                                    redeemOffer();
                                  }
                                } else {
                                  if (!checkFilled()) {
                                    checkFilledUpdate();
                                  } else {
                                    submitFamily();
                                  }
                                }
                              }}>
                              {GrandTotal === 0 &&
                              FamilyArrFormated().every(
                                (data) =>
                                  data.plan_id === String(idOfDNA.package_id) &&
                                  data.plan_price === 0
                              )
                                ? 'Get The Offer'
                                : 'Proceed to Payment'}
                            </button>
                          )
                        ) : null}
                      </div>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                        <ReactLoading
                          type={'spin'}
                          color="#000"
                          style={{
                            width: '3rem',
                            height: '3rem'
                          }}
                        />
                      </div>
                    )}
                  </div>
                )
              ) : (
                <div
                  style={{
                    width: '80vw',
                    maxWidth: '1000px',
                    overfloxX: 'hidden'
                  }}>
                  <h2>Terms and Conditions</h2>
                  <ol
                    style={{
                      marginLeft: '-1.25rem'
                    }}>
                    <li style={{ margin: '10px 0px' }}>
                      You are consenting to purchase an unlock.fit plan through 3 EMI instalments.
                      The EMI's will be deducted from your salary in equal instalments by your
                      organisation.
                    </li>

                    <li style={{ margin: '10px 0px' }}>
                      Once consented, the amount is non refundable.
                    </li>
                    <li style={{ margin: '10px 0px' }}>The plan is not transferable.</li>
                    <li style={{ margin: '10px 0px' }}>
                      There are no additional charges for choosing EMI, it's the same rate as it is
                      for paying upfront.
                    </li>
                    <li style={{ margin: '10px 0px' }}>
                      The Plan will be activated once you give consent.
                    </li>
                  </ol>
                  <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <label for="_enteredMobileno">Mobile : </label>&nbsp;&nbsp;
                    <input
                      className="custom-input"
                      type="text"
                      id="_enteredMobileno"
                      name="_enteredMobileno"
                      value={PhoneNumber}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (isNaN(value)) return;
                        setUserStatus(false);
                        setPhoneNumber(value);
                        if (value.length === 10) {
                          checkUser(value);
                        }
                      }}
                    />
                  </div>
                  <br />
                  {/* <br /> */}
                  <input
                    type="checkbox"
                    id="acceptTermsAndCondition"
                    name="acceptTermsAndCondition"
                    value={acceptTermsAndCondition}
                    onClick={(e) => {
                      setAcceptTermsAndCondition(e.target.checked);
                    }}
                  />{' '}
                  &nbsp;&nbsp;
                  <label for="acceptTermsAndCondition">I accept the Terms and Conditions</label>
                  <div
                    style={{
                      width: '100%',
                      margin: 'auto',
                      display: 'flex',
                      justifyContent: 'center'
                    }}>
                    <button
                      className="paymentButton"
                      onClick={buyThroughEMI}
                      // style={{
                      //   background: !enableConfirmButton && 'grey',
                      //   transition: '0.15s',
                      //   pointerEvents: !enableConfirmButton && 'none'
                      // }}
                    >
                      Confirm Purchase
                    </button>
                    <button
                      className="paymentButton"
                      onClick={(e) => {
                        restProps.setModalShow(false);
                        setShowTermsAndCondition(false);
                        setAcceptTermsAndCondition(false);
                        setEmiDuration(-1);
                      }}>
                      Cancel Purchase
                    </button>
                  </div>
                </div>
              )}
            </Content>
          </ModelContainer>
          <Snackbar open={open} autoHideDuration={6000} onClose={CloseSucess}>
            <Alert onClose={CloseSucess} severity={toastSeverity} sx={{ width: '100%' }}>
              {toastMessage}
            </Alert>
          </Snackbar>
        </Model>
      }
      <Model show={restProps.OpenMessage}>
        <ModelContainer>
          <Content>
            <div style={{ color: '#000', fontSize: 30, fontWeight: 600 }}>
              Unlock Me is Unlocked!
            </div>
            <div style={{ color: '#000', fontSize: 30, fontWeight: 400 }}>
              Now Get DNAPro® free for a loved one
            </div>
            <div
              onClick={() => {
                restProps.setOpenMessage(false);
                setFamilyArr((prev) => {
                  const newArray = [...prev];
                  if (TriggeredByApi) {
                    for (let i = 0; i < Count; i++) {
                      newArray.splice(IsThisIndex !== null ? IsThisIndex + 1 : 0, 0, {
                        name: '',
                        email: '',
                        plan_id: String(idOfDNA.package_id),
                        plan_price: 0,
                        select: true,
                        planPurchased: false,
                        relation: '',
                        isValidPhone: false,
                        countrycode: { code: '+91', name: 'India' },
                        sucess: '',
                        fail: '',
                        errorForEmail: '',
                        isValidMail: false,
                        borderForName: false,
                        borderForEmail: false,
                        borderForPhone: false,
                        borderForRelation: false,
                        isAddedByOffer: true
                      });
                    }
                    setCount(0);
                    setTriggeredByApi(false);
                  } else {
                    newArray.splice(IsThisIndex !== null ? IsThisIndex + 1 : 0, 0, {
                      name: '',
                      email: '',
                      plan_id: String(idOfDNA.package_id),
                      plan_price: 0,
                      select: true,
                      planPurchased: false,
                      relation: '',
                      isValidPhone: false,
                      countrycode: { code: '+91', name: 'India' },
                      sucess: '',
                      fail: '',
                      errorForEmail: '',
                      isValidMail: false,
                      borderForName: false,
                      borderForEmail: false,
                      borderForPhone: false,
                      borderForRelation: false,
                      isAddedByOffer: true
                    });
                  }
                  return newArray;
                });
              }}
              style={{
                background: '#0D85C3',
                padding: '10px 30px',
                borderRadius: 5,
                color: '#fff',
                cursor: 'pointer',
                marginTop: 20
              }}>
              Add Member
            </div>
            <div
              onClick={() => {
                restProps.setOpenMessage(false);
                setCount(0);
                setTriggeredByApi(false);
              }}
              style={{
                color: '#0D85C3',
                padding: '0px 30px',
                borderRadius: 5,
                cursor: 'pointer',
                marginTop: 20
              }}>
              Later in App
            </div>
          </Content>
        </ModelContainer>
      </Model>
      <Model show={RazorError}>
        <ModelContainer>
          <Content>
            <img src="assets/alert.png" />
            <div style={{ color: '#000', fontSize: 30, fontWeight: 600, marginTop: 38 }}>
              Razorpay SDK failed to load. Are you online?
            </div>
            <div
              onClick={() => {
                setRazorError(false);
              }}
              style={{
                background: '#0D85C3',
                padding: '10px 30px',
                borderRadius: 5,
                width: '70%',
                color: '#fff',
                cursor: 'pointer',
                marginTop: 20,
                display: 'flex',
                justifyContent: 'center'
              }}>
              Close
            </div>
          </Content>
        </ModelContainer>
      </Model>
      <Model show={RedeemSuccess}>
        <ModelContainer>
          <Content>
            <img src="assets/done.png" />
            <div style={{ color: '#000', fontSize: 30, fontWeight: 600, marginTop: 38 }}>
              Offer Redeemed Successfully !
            </div>
            <div style={{ color: '#000', fontSize: 20, marginTop: 20 }}>
              Congratulations! You have successfully added a member with DNAPro®.
            </div>
            <div
              onClick={() => {
                setRedeemSuccess(false);
                history.push('/thankyou');
                location.reload();
              }}
              style={{
                background: '#0D85C3',
                padding: '10px 30px',
                borderRadius: 5,
                width: '70%',
                color: '#fff',
                cursor: 'pointer',
                marginTop: 20,
                display: 'flex',
                justifyContent: 'center'
              }}>
              Done
            </div>
          </Content>
        </ModelContainer>
      </Model>
    </>
  );
};
const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  /* width: 400px; */
`;

const AddMoreSection = styled.div`
  display: flex;
  width: 100%;

  span {
    color: #4990e0;
    font-weight: lighter !important;
    cursor: pointer;
    padding-right: 20px;

    :hover {
      color: goldenrod;
    }
  }

  p {
    padding: 0rem;
    color: red;
    font-size: 15px;
    margin: 0;
  }

  .success {
    padding: 0rem;
    color: #07d507;
    font-size: 15px;
  }

  button {
    border-radius: 20px;
    /* padding:10px; */
    height: 50px;
    color: goldenrod;
    background: #f2f2f2;
    outline: none;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  z-index: 2;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  min-height: 500px;
  border-radius: 25px;
  padding: 2rem;
  background: #f2f2f2;

  button {
    border-radius: 20px;
    padding: 10px;
    outline: none;
  }
`;

const Wrapper = styled.div`
  background: white;
  display: none;

  /* display: '${({ show }) => (show ? 'block' : 'none')}'; */
  z-index: ${({ show }) => (show ? 2 : -1)};
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  position: fixed;
  width: 100%;
  border-radius: 25px;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  min-height: 500px;
  /* min-width:500px; */
`;

const CardHolder = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  ${'' /* width: 700px; */}
  background: #f2f2f2;
  /* border:2px solid #f2f2f2; */
  border-radius: 20px;
  align-items: center;
  margin: 2px;

  .label {
    display: flex;
    flex-direction: column;
    color: grey;
  }
  .label1 {
    color: grey;
    margin-top: -0.5rem;
  }

  .label2 {
    display: flex;
    flex-direction: column;
    color: grey;
  }

  input {
    border-radius: 5px;
    outline: none;
    padding: 11px 10px;
    margin: 5px 0px;
    font-size: 15px;
    border: 1px solid #fff0;
    font-family: 'Poppins', 'DM Sans', sans-serif;
  }

  select {
    border-radius: 5px;
    outline: none;
    padding: 10px;
    margin: 5px 0px;
    font-size: 15px;
    border: 1px solid #fff0;
    width: 100%;
    font-family: 'Poppins', 'DM Sans', sans-serif;
  }

  option {
    font-size: 15px;
    font-family: 'Poppins', 'DM Sans', sans-serif;
    color: #808080;
  }
  ${
    '' /* select {
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    border: 2px solid #f2f2f2;
    background: white;
    font-size: 15px;
  } */
  }

  p {
    font-size: 1.2rem;
    padding: 0 !important;
  }
`;
const CardHolder1 = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  ${'' /* width: 700px; */}
  background: #f2f2f2;
  /* border:2px solid #f2f2f2; */
  border-radius: 20px;
  align-items: center;
  margin: 2px;
  width: 100%;

  .label {
    display: flex;
    flex-direction: column;
    color: grey;
    width: 21%;
  }
  .label1 {
    color: grey;
    margin-top: -0.5rem;
  }

  .label2 {
    display: flex;
    flex-direction: column;
    color: grey;
    width: 5%;
  }

  input {
    border-radius: 5px;
    outline: none;
    padding: 11px 10px;
    margin: 5px 0px;
    font-size: 15px;
    color: #808080;
    border: none;
    font-family: 'Poppins', 'DM Sans', sans-serif;
  }

  select {
    border-radius: 5px;
    outline: none;
    padding: 10px;
    margin-top: 5px;
    font-size: 15px;
    border: none;
    width: 100%;
    font-family: 'Poppins', 'DM Sans', sans-serif;
  }

  option {
    font-size: 15px;
    font-family: 'Poppins', 'DM Sans', sans-serif;
    color: #808080;
  }
  ${
    '' /* select {
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    border: 2px solid #f2f2f2;
    background: white;
    font-size: 15px;
  } */
  }

  p {
    font-size: 1.2rem;
    padding: 0 !important;
  }
`;
export default Modal;
