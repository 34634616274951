import { React, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Wrapper, MainWrapper, InnerWrapper, ContentContainer } from './styles';
const Sec2 = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <Wrapper>
      <MainWrapper>
        <InnerWrapper>
          <ContentContainer data-aos="zoom-in" style={{ position: 'relative' }}>
            <div id="product" style={{ position: 'absolute', top: '-150px' }}></div>
            <h1>
              Wellness Program Exclusively curated for the <br /> TATA Communications Family
            </h1>
            <p style={{ fontWeight: '400' }}>
              Discover world&apos;s most personalized and scientifically-driven program to help you
              live your best life
            </p>
          </ContentContainer>
        </InnerWrapper>
      </MainWrapper>
    </Wrapper>
  );
};

export default Sec2;
