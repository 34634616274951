import styled from 'styled-components';
export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  /* background-color: #f6f6f6; */
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const LeftWrapper = styled.div`
  flex: 1;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 35%;
    margin-left: 1rem;
  }
`;

export const RightWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* background-color: green; */
  Button {
    background-color: #d2a614;
    /* background-color: #fff; */
    color: white;
    /* color: black; */
    font-weight: 500;
  }
  @media (max-width: 800px) {
    align-items: center;
    h2 {
      max-width: 80%;
    }
  }
`;

export const Image = styled.div`
  display: flex;
  margin-top: 5rem;
  img {
    margin-right: 5rem;

    @media (max-width: 1024px) {
      margin: 0 1rem;
      width: 179px;
      height: 60px;
    }
    @media (max-width: 600px) {
      margin: 0 2rem;
      margin-bottom: 2rem;
      width: 130px;
      height: 50px;
    }
    @media (max-width: 400px) {
      margin: 0 1rem;
      width: 92px;
      height: 35px;
      margin-bottom: 2rem;
    }
  }
  @media (max-width: 1024px) {
    margin-top: 2rem;
  }
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
export const Heading = styled.div`
  display: flex;
  align-items: flex-end;
  img {
    margin-right: 1rem;
    width: 62px;
    height: 62px;

    @media (max-width: 1024px) {
      width: 48px;
      height: 48px;
    }
    @media (max-width: 400px) {
      width: 32px;
      height: 32px;
    }
  }

  h2 {
    @media (max-width: 1024px) {
      font-size: 1.5rem;
    }
    @media (max-width: 400px) {
      font-size: 1rem;
    }
  }
`;
