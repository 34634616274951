/* eslint-disable */
import React from 'react';

import { Wrapper, AccordionWrapper, Headingwrapper } from './style';
import { Accordion, Button } from '../../components';
const question = [
  {
    title: 'What is DNA Test?',
    cont: 'DNA Test is a simple saliva test, that provides DNA analysis and reports on your individual genetic response to different fitness and nutrition markers. Using the information from this report, you can make better‐informed choices about how to exercise and eat, on the most personal level possible–by taking your genetic profile into account.'
  },
  {
    title: 'What are the benefits of taking DNA Test?',
    cont: 'One size does not fit all - we all are different and we live in the era of personalised services to suit your exercise and nutrition plans to include your genetic profile.unlock.fit’s DNA Test is just a simple saliva test but from this you’ll be provided with information to improve your health and fitness for a lifetime.Your genes do not change, but your lifestyle can.DNA Test looks at the interaction between genes and environment; by understanding your genetics you can better adjust your environment for your optimal health and fitness.Make the most of your time on the exercise by reducing guesswork/ trial and error with a new layer of truly personalised fitness information.Optimise your nutrition by knowing how your genetics affect the diet choices for your body.Our DNA Test is a new powerful tool in preventive wellbeing - we use genetics to help make the best informed choices when it comes to improving health through personalised fitness and diet.'
  },

  {
    title: 'What’s included in the DNA test?',
    cont: 'Our DNA Test is the most comprehensive package that includes reports on:1. Nutrition,2. Fitness,3. Weight Loss,4. Detox profile,5. Food intolerance'
  }
];
const FAQ = () => {
  return (
    <Wrapper>
      <Headingwrapper>
        <h1>FAQs</h1>
      </Headingwrapper>
      <AccordionWrapper>
        {question.map((el) => (
          <Accordion title={el.title} cont={el.cont} />
        ))}
      </AccordionWrapper>
      <a href="/faq">
        <Button>View More</Button>
      </a>
    </Wrapper>
  );
};

export default FAQ;
