import { React, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { IntroSection } from './styles';
const Sec5 = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <>
      <IntroSection.MainWrapper>
        <IntroSection.Image>
          <img src="/assets/dna2.png" alt="" />
        </IntroSection.Image>
        <IntroSection.Content>
          <h2 style={{ color: 'white', fontsize: '45px' }} data-aos="fade-down">
            Your DNA is as Unique as you
          </h2>
          <h2 style={{ letterSpacing: '1px', fontWeight: '500' }} data-aos="fade-down">
            Let your DNA guide you to make Better Choices
          </h2>
        </IntroSection.Content>
        <IntroSection.Image>
          <img src="/assets/dna3.png" alt="" />
        </IntroSection.Image>
      </IntroSection.MainWrapper>
    </>
  );
};

export default Sec5;
