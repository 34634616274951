import styled from 'styled-components';

export const Model = styled.div`
  z-index: 1000;
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* display: flex;
align-items: center;
justify-content: center; */
`;

export const ModelContainer = styled.div`
  /* background-color: red; */
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 10rem;
  margin-top: 1rem;
`;

export const ContactBox = styled.a`
  width: 75%;
  border-radius: 20px;
  margin: 1rem;
  border: 1px solid goldenrod;
  display: flex;
  text-decoration: none;
  color: #19191b;

  align-items: center;
  justify-content: space-around;
  padding: 1rem;

  @media (max-width: 400px) {
    width: 85%;
  }
  :hover {
    background-color: goldenrod;
  }
`;

export const Content = styled.div`
  position: relative;
  width: 30rem;
  height: 30rem;
  background-color: white;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  Button {
    background-color: #c59e2d;
    color: white;
    font-size: 20px;
    margin-top: 1rem;
    border-radius: 10px;
    @media (max-width: 800px) {
      font-size: 15px;
    }
  }

  h2 {
    font-size: 1.7rem;
    margin-top: 1rem;
    color: #c59e2d;
    border-bottom: 1px solid grey;
    margin-bottom: 1rem;
  }

  form {
    width: 80%;
    font-size: 1rem;
    input {
      width: 100%;
      padding: 5px 10px;
    }
  }

  @media (max-width: 600px) {
    /* width: 90%;
    height: 40%; */
  }
`;
