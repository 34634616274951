/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { Wrapper, AccordionHeading, ContentContainer } from './style';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';

function Accordion({ title, cont, center }) {
  const [active, setactive] = useState('');
  const [height, setheight] = useState('0px');

  const content = useRef(null);
  function showaccordion() {
    setactive(active === '' ? 'active' : '');
    setheight(active === 'active' ? '0px' : `${content.current.scrollHeight}px`);
  }

  return (
    <Wrapper>
      <AccordionHeading onClick={showaccordion}>
        <p style={{ color: '#d2a614', textAlign: center }}>{title}</p>
        {/* <p>hi</p> */}
        {active === 'active' ? <AiOutlineUp fontSize="1rem" /> : <AiOutlineDown  fontSize="1rem" />}
      </AccordionHeading>
      <ContentContainer ref={content} style={{ maxHeight: `${height}` }}>
        <p>{cont}</p>
      </ContentContainer>
    </Wrapper>
  );
}

export default Accordion;
