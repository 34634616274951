import styled from 'styled-components';

export const IntroSection = {};

IntroSection.MainWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #d2a614;

  @media (max-width: 450px) {
    justify-content: center;
    padding: 3rem 0;
    /* background-color: skyblue; */
    background-image: url('/assets/dna3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;
IntroSection.Content = styled.div`
  /* flex: 1; */
  width: 80%;
  text-align: center;
  h2 {
    font-weight: 700;
    /* font-size: 40px; */
    color: #19191b;
    @media (max-width: 1024px) {
      font-size: 30px;
    }
  }
`;
IntroSection.Image = styled.div`
  img {
    @media (max-width: 800px) {
      width: 164px;
    }
    @media (max-width: 600px) {
      width: 120px;
    }
    @media (max-width: 450px) {
      display: none;
    }
  }
`;
