import styled from 'styled-components/macro';

export const IntroSection = {};

IntroSection.SuperWrapper = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  background: url('/assets/Artboard-3-1.png') no-repeat center;
  background-size: cover;
  background-size: 57%;
  @media (max-width: 1024px) {
    background-size: 120%;
    height: 100vh;
  }
  @media (max-width: 800px) {
    background-size: 130%;
    height: 80vh;
  }
  @media (max-width: 700px) {
    background-size: 150%;
  }
  @media (max-width: 600px) {
    // height: 70vh;
  }
  @media (max-width: 450px) {
    height: 70vh;
  }
`;
IntroSection.Wrapper = styled.div`
  // height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(250, 250, 250, 0.97);
  @media (max-width: 1024px) {
    height: 100vh;
  }
  @media (max-width: 800px) {
    background-size: 150%;
    height: 80vh;
  }
  @media (max-width: 600px) {
    background-size: 200%;
    // height: 85vh;
  }
  @media (max-width: 450px) {
    height: 70vh;
  } ;
`;
IntroSection.ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 85%;
  height: 100vh;
  align-items: center;
  @media (max-width: 1024px) {
    height: 100vh;
  }
  @media (max-width: 800px) {
    height: 82vh;
  }
  @media (max-width: 450px) {
    height: 60vh;
  }
  h1 {
    margin: 5rem 3rem 0 0;
    @media (max-width: 1024px) {
      margin: 5rem auto;
      margin-bottom: 0;
      font-size: 2rem;
    }
    @media (max-width: 600px) {
      margin: 5rem 0 0 0;
      font-size: 1.7rem;
    }
  }
`;
IntroSection.Container = styled.div`
  display: flex;
  min-width: 80%;
  height: 90vh;
  justify-content: center;
  background: url('/assets/Desktop.png') no-repeat center;
  background-size: cover;
  background-size: 55%;

  @media (max-width: 1400px) {
    background-size: 59%;
  }
  @media (max-width: 1200px) {
    background-size: 67%;
  }
  @media (max-width: 1024px) {
    background-size: 90%;
  }
  @media (max-width: 800px) {
    height: 80vh;
  }
  @media (max-width: 600px) {
    background-size: cover;
    background-size: 85%;
  }
  @media (max-width: 450px) {
    background-size: 120%;
  }
`;
