import { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';

import {
  Benefit,
  Contact,
  Home1,
  // Section2,
  Sec2,
  Sec4,
  Sec5,
  HowWorks,
  Section6,
  Section7,
  Client,
  FAQ
} from './container';

import Footer from './container/footer2';
import axios from 'axios';
const MainWrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
`;
// console.log = function () {};
let emailId;

function Home() {
  const [loading, setLoading] = useState(false);
  const [IsValid, setIsValid] = useState(false);
  const [price, setPrice] = useState(0);
  const [prodId, setProdId] = useState('1');
  const [planName, setPlanName] = useState('');
  const [durationId, setDurationId] = useState('');
  const [PlanForUnlockMe, setPlanForUnlockMe] = useState();
  const [productsForNew, setproductsForNew] = useState([]);
  const [SelectedCategory, setSelectedCategory] = useState();
  const [SelectedNAme, setSelectedNAme] = useState();
  const [HasSubCategory, setHasSubCategory] = useState();
  const [SubCat, setSubCat] = useState('');
  const [show, setShow] = useState(false);
  const [Times, setTimes] = useState({
    startTime: '',
    endTime: ''
  });
  const Loading = useRef(false);

  // console.log(emailId);

  const getOffers = () => {
    axios
      .get(`${process.env.REACT_APP_BASEURL}tata/website/v1/check_offers`)
      .then((response) => {
        setIsValid(response.data.is_offer_active);
        setTimes({
          startTime: response.data.start_date_time,
          endTime: response.data.end_date_time
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getOffers();
  }, []);

  console.log(emailId);

  if (emailId) {
    // localStorage.setItem('emailId', emailId);
    // localStorage.setItem('nameId', nameId);
    // if (process.env.REACT_APP_ENV === 'production') {
    //   window.location.href = 'https://tc.unlock.fit/';
    // } else if (process.env.REACT_APP_ENV === 'development') {
    //   window.location.href = 'https://dev-tc.unlock.fit/';
    // } else {
    //   window.location.href = 'http://localhost:3000/';
    // }
    //for dev
    // window.location.href = 'https://dev-tc.unlock.fit/';
    //for localhost
    // window.location.href = 'http://localhost:3000/';
  }
  emailId = localStorage.getItem('emailId');

  const handleRedirect = () => {
    const emailId = new URLSearchParams(window.location.search).get('email');
    const nameId = new URLSearchParams(window.location.search).get('name');

    console.log('emailId', emailId);
    localStorage.setItem('nameId', nameId);
    localStorage.setItem('emailId', emailId);

    setTimeout(() => {
      const storedEmailId = localStorage.getItem('emailId');
      if (storedEmailId === null || storedEmailId === 'null') {
        window.location.href =
          'https://myapps.microsoft.com/signin/d736ddb1-a492-4775-b647-2f3eca0c6420?tenantId=20210462-2c5e-4ec8-b3e2-0be950f292ca';
      }
    }, 2000);
  };

  useEffect(() => {
    if (emailId === null || emailId === 'null') handleRedirect();
  }, [emailId]);

  // if(loading) return <div style={{height:"100vh",display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}><h1>Loading...</h1></div>

  return (
    <Router>
      <MainWrapper>
        <Home1
          id="home"
          IsValid={IsValid}
          setIsValid={setIsValid}
          Times={Times}
          setTimes={setTimes}
          setDurationId={setDurationId}
          setPlanName={setPlanName}
          setShow={setShow}
          setProdId={setProdId}
          setPrice={setPrice}
          PlanForUnlockMe={PlanForUnlockMe}
          productsForNew={productsForNew}
          setproductsForNew={setproductsForNew}
          SelectedCategory={SelectedCategory}
          setSelectedCategory={setSelectedCategory}
          SelectedNAme={SelectedNAme}
          setSelectedNAme={setSelectedNAme}
          HasSubCategory={HasSubCategory}
          setHasSubCategory={setHasSubCategory}
          SubCat={SubCat}
          setSubCat={setSubCat}
        />
        <Client id="home" IsValid={IsValid} />
        {/* <Section2 /> */}
        <Sec2 />
        <Section6
          setLoading={setLoading}
          loading={loading}
          Loading={Loading}
          IsValid={IsValid}
          show={show}
          price={price}
          durationId={durationId}
          planName={planName}
          prodId={prodId}
          setDurationId={setDurationId}
          setPlanName={setPlanName}
          setShow={setShow}
          setProdId={setProdId}
          setPrice={setPrice}
          setPlanForUnlockMe={setPlanForUnlockMe}
          productsForNew={productsForNew}
          setproductsForNew={setproductsForNew}
          SelectedCategory={SelectedCategory}
          setSelectedCategory={setSelectedCategory}
          SelectedNAme={SelectedNAme}
          setSelectedNAme={setSelectedNAme}
          HasSubCategory={HasSubCategory}
          setHasSubCategory={setHasSubCategory}
          SubCat={SubCat}
          setSubCat={setSubCat}
        />
        <Sec4 />
        <Sec5 />
        <HowWorks />
        <Benefit />
        <Section7 />
        <FAQ />
        <Contact />
        <Footer />
      </MainWrapper>
    </Router>
  );
}
export default Home;
