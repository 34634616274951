/* eslint-disable */
import React, { useEffect, useState } from 'react';
// import { IntroSection } from './styles';
import { Content, Logo, Model, ModelContainer } from './styles';
import AutoInfiniteSlider from './Slider';
import axios from 'axios';
const Home1 = ({
  IsValid,
  setIsValid,
  Times,
  setDurationId,
  setPlanName,
  setShow,
  setProdId,
  setPrice,
  productsForNew,
  setproductsForNew,
  SelectedCategory,
  setSelectedCategory,
  SelectedNAme,
  setSelectedNAme,
  HasSubCategory,
  setHasSubCategory,
  SubCat,
  setSubCat
}) => {
  const [OpenTree, setOpenTree] = useState(false);
  var x = localStorage.getItem('name');
  const emailId = localStorage.getItem('emailId');

  const callOffers = () => {
    const body = {
      email: emailId,
      name: x,
      phone: ''
    };
    axios.post(`${process.env.REACT_APP_BASEURL}tata/website/user/redeem/logger`, body);
  };
  console.log('HR');
  useEffect(() => {
    const email = new URLSearchParams(window.location.search).get('email');
    console.log('email', email);
    // if (email) localStorage.setItem('userEmail', email);
    var cnt = 0;
    const urlString = window?.location?.href;
    let paramString = urlString.split('?')[1];
    let queryString = new URLSearchParams(paramString);
    for (let pair of queryString.entries()) {
      localStorage.setItem(pair[0], pair[1]);
      console.log('Key is:' + pair[0]);
      console.log('Value is:' + pair[1]);
      cnt++;
    }
    localStorage.setItem('noOfPeople', cnt);
    const res = new URLSearchParams(window.location.search).get('resource');
    if (res === 'offer_popup' && IsValid) setOpenTree(true);
  }, []);
  console.log('name', x);

  const images = [
    { id: 1, link: 'assets/card 1.jpg' },
    { id: 2, link: 'assets/card 2.jpg' },
    { id: 3, link: 'assets/card 3.jpg' },
    { id: 4, link: 'assets/card 4.jpg' }
  ];

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener on component mount
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function Countdown({ endTime }) {
    const [countdown, setCountdown] = useState('');

    useEffect(() => {
      let countdownInterval;

      function updateCallback(countdownValue) {
        setCountdown(countdownValue);
      }

      startCountdown(endTime, updateCallback, countdownInterval);

      // Cleanup the interval when the component unmounts
      return () => clearInterval(countdownInterval);
    }, [endTime]);

    return (
      <div
        style={{
          background: '#0D85C3',
          height: screenWidth < 800 ? 30 : 60,
          color: '#fff',
          padding: '10px',
          fontSize: screenWidth < 800 ? 10 : 30,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <div
          style={{
            display: 'flex',
            marginLeft: 15
          }}>
          <div
            style={{
              width: screenWidth < 800 ? 78 : 230,
              marginRight: 10,
              marginLeft: screenWidth < 800 ? 0 : 90
            }}>
            {countdown}
          </div>{' '}
          <div>Left for the Offer</div>
        </div>
        <div
          style={{
            marginRight: screenWidth < 800 ? 10 : 90,
            display: 'flex',
            alignItems: 'center'
          }}>
          <div
            onClick={() => {
              const divide = PlanForUnlockMe[0];
              setDurationId(divide.duration_id);
              setPlanName(divide.package_title);
              setShow(true);
              setProdId(divide.package_id);
              setPrice(divide.special_price);
              callOffers();
            }}
            style={{
              border: 'none',
              color: '#0D85C3',
              background: '#ffff',
              padding: '8px 18px',
              outline: 'none',
              fontSize: screenWidth < 800 ? 10 : 18,
              borderRadius: 5,
              cursor: 'pointer',
              fontWeight: 600
            }}>
            Redeem Now
          </div>
        </div>
      </div>
    );
  }

  function startCountdown(endTime, updateCallback, interval) {
    function update() {
      const currentTime = new Date().getTime();
      const endDate = new Date(endTime).getTime();

      if (currentTime >= endDate) {
        clearInterval(interval);
        setIsValid(false);
        updateCallback('Countdown has ended');
      } else {
        const timeDifference = endDate - currentTime;

        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');

        updateCallback(`${formattedHours}  :  ${formattedMinutes}  :  ${formattedSeconds}  hrs`);
      }
    }

    // Initial update
    update();

    // Update the countdown every second
    interval = setInterval(update, 1000);
  }

  return (
    <div style={{ marginTop: screenWidth < 800 ? 73 : 97 }}>
      {/* {IsValid && <Countdown startTime={Times.startTime} endTime={Times.endTime} />} */}
      <AutoInfiniteSlider
        images={images}
        productsForNew={productsForNew}
        setproductsForNew={setproductsForNew}
        SelectedCategory={SelectedCategory}
        setSelectedCategory={setSelectedCategory}
        SelectedNAme={SelectedNAme}
        setSelectedNAme={setSelectedNAme}
        HasSubCategory={HasSubCategory}
        setHasSubCategory={setHasSubCategory}
        SubCat={SubCat}
        setSubCat={setSubCat}
        // setDurationId={setDurationId}
        // setPlanName={setPlanName}
        // setShow={setShow}
        // setProdId={setProdId}
        // setPrice={setPrice}
        // PlanForUnlockMe={PlanForUnlockMe}
        // callOffers={callOffers}
      />
      <Model show={OpenTree}>
        <ModelContainer>
          <Content>
            <img src="/assets/Confetti.gif" height="300" />
            <div style={{ color: '#000', fontSize: 30, fontWeight: 400, textAlign: 'center' }}>
              Congratulations You're Just One Step Away From Unlocking Your Offer
            </div>
            <a href="./#product" style={{ textDecoration: 'none' }}>
              <div
                onClick={() => {
                  setOpenTree(false);
                }}
                style={{
                  background: '#0D85C3',
                  padding: '10px 30px',
                  borderRadius: 5,
                  color: '#fff',
                  cursor: 'pointer',
                  marginTop: 20
                }}>
                Buy Now
              </div>
            </a>
          </Content>
        </ModelContainer>
      </Model>
    </div>
    // <IntroSection.SupperWrapper style={{ marginTop: '70px' }}>
    //   <IntroSection.MainWrapper>
    //     <IntroSection.Wrapper>
    //       <IntroSection.LeftWrapper>
    //         <h2>
    //           Hi, <span style={{ color: '#4286c2' }}>{x}</span>
    //         </h2>
    //         <h1>Why Does This Always Happen To Me?</h1>
    //         <h4>
    //           The answer lies in your DNA. Your genetic code contains all the information you need
    //           to optimize your health holistically.
    //         </h4>
    //         <h3>#ListenToYourDNA</h3>
    //         <br />
    //         <br />
    //         <a href="./#product">
    //           <Button>GET FIT WITH US</Button>
    //         </a>
    //       </IntroSection.LeftWrapper>
    //       <IntroSection.RightWrapper></IntroSection.RightWrapper>
    //     </IntroSection.Wrapper>
    //   </IntroSection.MainWrapper>
    // </IntroSection.SupperWrapper>
  );
};

export default Home1;
