import styled from 'styled-components';

export const IntroSection = {};

IntroSection.MainWrapper = styled.div`
  width: 100%;
  text-align: center;
  background: url('/assets/works.jpeg');
  background-size: cover;

  @media (max-width: 450px) {
    background: url('/assets/works3.png');
  }
`;

IntroSection.TextContainer = styled.div`
  margin: auto;
  padding: 1rem;
  h1 {
    margin: none;
  }
`;

IntroSection.ImageSection = styled.div`
  img {
    width: 90%;
  }
  margin-top: -5rem;
  @media (max-width: 450px) {
    display: none;
  }
`;

IntroSection.ImageSection1 = styled.div`
  img {
    margin-top: -1rem;
    display: none;
    @media (max-width: 450px) {
      display: block;
      width: 100%;
    }
  }
`;
