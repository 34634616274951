import React from 'react';
import { IntroSection } from './styles';
const HowWorks = () => {
  return (
    <>
      <IntroSection.MainWrapper>
        <IntroSection.TextContainer>
          <h1>How does it work?</h1>
        </IntroSection.TextContainer>
        <IntroSection.ImageSection>
          <img src="assets/works1.png" alt="how-works" />
        </IntroSection.ImageSection>
        <IntroSection.ImageSection1>
          <img src="assets/works2.png" alt="how-works" />
        </IntroSection.ImageSection1>
      </IntroSection.MainWrapper>
    </>
  );
};

export default HowWorks;
