/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components';

const MainWrapper = styled.div`
  width: 100%;
  background-color: white;
`;

const Wrapper = styled.div`
  width: 60%;
  margin: 7rem auto;

  @media (max-width: 765px) {
    width: 90%;
    margin: 5rem auto;
  }

  h1 {
    text-align: center;
    color: #d2a614;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    color: #555555;
  }
`;

const Terms = () => {
  return (
    <>
      <MainWrapper>
        <Wrapper>
          <h1>Terms Of Use</h1>
          <p>
            These Terms of Use ("Terms of Use") are executed between you and Unlock Wellness Private
            <br />
            Limited (hereinafter referred to as "unlock.fit", "we", "our" or "us") and govern the
            usage or
            <br />
            access of our Products and Platform (both, as defined hereinafter) and any other
            features and
            <br />
            technologies we offer.
            <br />
            These Terms of Use constitute a binding agreement between you and unlock.fit and its
            affiliates
            <br />
            and subsidiaries. By accessing, using and/or registering on our Platform, you understand
            and agree
            <br />
            to be bound by these Terms of Use, the privacy policy as is more particularly set forth
            on the
            <br />
            Platform (the "Privacy Policy"), and related documents and any modifications or
            amendments
            <br />
            made thereto by unlock.fit from time to time, at its sole discretion. The Terms of Use,
            and our
            <br />
            Privacy Policy (which addresses how we handle and protect your data and information),
            and any
            <br />
            appendices, addendums, and schedules attached thereto shall be collectively referred to
            as
            <br />
            "Terms". If you do not agree to these Terms, you are not authorised to access or use our
            Products
            <br />
            and/or Platform.
            <br />
            By accepting these Terms, you represent that you have the capacity to enter into, or, if
            you are
            <br />
            acting on behalf of an entity, that you have the authority to bind such entity to a
            legally binding
            <br />
            contract, and you agree that these Terms legally bind you or the entity on behalf of
            which you
            <br />
            purport to act, in the same manner as a signed, written, paper contract. These Terms
            constitute an
            <br />
            electronic record within the meaning of the Applicable Law. This electronic record is
            generated by
            <br />a computer system and does not require any physical or digital signatures.
            Accessing, browsing
            <br />
            and/or using the Platform, and/or Application and/or using any of the Products or
            services or
            <br />
            information provided therein shall be deemed to signify the User’s (as defined
            hereinafter)
            <br />
            unequivocal acceptance of the Terms. You expressly acknowledge and agree to be bound by
            the
            <br />
            Terms of Use, regardless of however you or anyone on your behalf has accessed,
            installed,
            <br />
            downloaded or used the Platform and whether or not you have registered on the Platform.
            <br />
            BY DOWNLOADING/INSTALLING, ACCESSING, COPYING OR USING THE
            <br />
            PLATFORM, AND/OR OUR PRODUCTS AND SERVICES, YOU (A) ACKNOWLEDGE
            <br />
            THAT YOU HAVE READ AND UNDERSTOOD THE TERMS; (B) REPRESENT THAT
            <br />
            YOU ARE ATLEAST 18 YEARS OF AGE, OR THE REQUIRED LEGAL AGE IN YOUR
            <br />
            JURISDICTION; AND (C) ACCEPT THESE TERMS AND AGREE THAT YOU ARE
            <br />
            LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS, DO
            <br />
            NOT DOWNLOAD, INSTALL OR USE THE PLATFORM OR OUR PRODUCTS OR
            <br />
            SERVICES AND DELETE THE APPLICATION FROM YOUR DEVICE.
            <br />
            Definitions
            <br />
            "Applicable Laws" shall mean any and all: (i) laws, statutes, constitutions, treaties,
            rules,
            <br />
            regulations, ordinances, codes, guidance, and common law; and (ii) all judicial,
            executive,
            <br />
            legislative, administrative or military orders, directives, decrees, injunctions,
            judgments, permits,
            <br />
            agreements, and other legal requirements, in each case, of, with, or adopted or imposed
            by any
            <br />
            Governmental Authority (as defined hereinafter), now or hereafter in effect and, in each
            case, as
            <br />
            amended from time to time.
            <br />
            "Application", singular or plural, refers to any html-based / internet-based computer
            programs,
            <br />
            smart phone, tablet or personal computer applications and all other software programs
            made
            <br />
            available to the Users by us.
            <br />
            "Confidential Information" shall have the meaning ascribed to it in Clause 13 of these
            Terms of
            <br />
            Use.
            <br />
            "Content" means all information that is created, uploaded, posted and stored on the
            Platform such
            <br />1<br />
            as text, photos, audio, video, or other materials and information, including the
            Products and
            <br />
            services and includes any other information made available by unlock.fit on or through
            the
            <br />
            Platform including proprietary unlock.fit content and any content licensed or authorized
            for use by
            <br />
            or through unlock.fit from a third party.
            <br />
            "Customer" means any User who has purchased any of the Products made available on the
            <br />
            Platform.
            <br />
            "Data Protection Law" means any data protection, data security or privacy law,
            including, without
            <br />
            limitation, the Indian Information Technology Act, 2000 and applicable rules,
            regulations, EU
            <br />
            General Data Protection Regulation 2016/679 (the "GDPR"), if applicable and any laws
            governing
            <br />
            Personal Data (as defined hereinafter), Sensitive Personal Data or Information (as
            defined
            <br />
            hereinafter), outbound telephone calls, transmission of electronic mail, transmission of
            facsimile
            <br />
            messages and any other communication-related data protection, data security or privacy
            laws, to
            <br />
            which either Party, as applicable, is subject to, in connection with the Terms.
            <br />
            "Genetic Information" means information regarding your genotypes generated through
            <br />
            processing of your saliva by unlock.fit or by its contractors, successors, or assignees;
            or otherwise
            <br />
            processed by unlock.fit.
            <br />
            "Governmental Authority" means any government authority, statutory authority, regulatory
            <br />
            authority, government department, agency, commission, board, tribunal or court or other
            law, rule
            <br />
            or regulation making entity/ authority having or purporting to have jurisdiction on
            behalf of the
            <br />
            Republic of India or any state or other subdivision thereof or any municipality,
            district or other
            <br />
            subdivision thereof.
            <br />
            "Intellectual Property" or "IP" includes ideas, concepts, creations, discoveries,
            inventions,
            <br />
            improvements, know how, trade or business secrets; trademarks, service marks, designs,
            utility
            <br />
            models, tools, devices, models, methods, procedures, processes, systems, principles,
            synthesis
            <br />
            protocol, algorithms, works of authorship, flowcharts, drawings, books, papers, models,
            sketches,
            <br />
            formulas, proprietary techniques, research projects, copyright, designs, and other
            confidential and
            <br />
            proprietary information, databases, data, documents, instruction manuals, records,
            memoranda,
            <br />
            notes, user guides, in either printed or machine-readable form, whether or not
            copyrightable or
            <br />
            patentable or protectable under any other intellectual property law, or any written or
            verbal
            <br />
            instructions or comments.
            <br />
            "Intellectual Property Rights" or "IP Rights" include: (i) all rights, title, and
            interest under any
            <br />
            statute or under applicable law including patent rights; copyrights including moral
            rights; and any
            <br />
            similar rights in respect of the Intellectual Property, anywhere in the world, whether
            negotiable or
            <br />
            not; (ii) any licenses, permissions and grants in connection therewith; (iii)
            applications for any of
            <br />
            the foregoing and the right to apply for them in any part of the world; (iv) right to
            obtain and hold
            <br />
            appropriate registrations in Intellectual Property anywhere in the world; (v) all
            extensions and
            <br />
            renewals thereof; and (vi) causes of action in the past, present or future, related
            thereto including
            <br />
            the rights to damages and profits, due or accrued, arising out of past, present or
            future
            <br />
            infringements or violations thereof and the right to sue for and recover the same.
            <br />
            "Losses" shall mean all direct losses, damages, liabilities, costs (including legal
            fees), expenses,
            <br />
            charges, interest, penalty, claims, arbitration, proceedings, suits and all sums paid in
            relation to any
            <br />
            compromise or settlement of any claim, arbitration, suit or proceeding.
            <br />
            "Misuse" or "Abuse" means misuse or abuse of Content that is inconsistent with the
            spirit of the
            <br />
            Terms, even if it is something that is not expressly or impliedly forbidden by the
            letter of these
            <br />
            Terms. In other words, if you do something that is not prohibited here verbatim but is
            not expressly
            <br />
            allowed or provided for by the Terms, unlock.fit is wholly entitled to use its
            discretion to remove
            <br />
            the said content from the Platform and from all other appropriate places.
            <br />2<br />
            "Party" refers individually to each of you and unlock.fit.
            <br />
            "Parties" refer to both you and unlock.fit jointly.
            <br />
            "Personal Data" means any personally identifiable information relating to an identified
            or
            <br />
            identifiable individual, including data that identifies an individual or that could be
            used to identify,
            <br />
            locate, track, or contact an individual. Personal Data includes both directly
            identifiable
            <br />
            information, such as a name, identification number or unique job title, and indirectly
            identifiable
            <br />
            information such as date of birth, unique mobile or wearable device identifier,
            information that
            <br />
            could be used to identify a household, telephone number, key-coded data or online
            identifiers, such
            <br />
            as IP addresses, and includes any data that constitutes "personal data" under the GDPR
            or similar
            <br />
            terms under other Data Protection Law. For the avoidance of doubt, Personal Data
            includes
            <br />
            (without limitation) Personal Identification Information and Genetic Information.
            <br />
            "Personal Identification Information" means your name, address, identification number,
            phone
            <br />
            number, and includes any other information by which you may be personally identified.
            <br />
            "Platform" means the unlock.fit website located at https://www.unlock.fit/ or such
            website or any
            <br />
            other Application powered by unlock.fit to provide the Products and services, but does
            not include
            <br />
            any website or mobile application owned or operated by a third party that may be
            accessed from
            <br />
            any page on https://www.unlock.fit/ or mobile Application powered by unlock.fit.
            <br />
            "Products" shall mean the wellness products and programs curated/created by unlock.fit
            that are
            <br />
            made available on the Platform and shall include any and all services and features
            offered on the
            <br />
            Platform, or made available by unlock.fit to the User, including services related to DNA
            testing and
            <br />
            analysis including the provision of DNA kit and collection and analysis of saliva
            sample, blood
            <br />
            tests, counselling sessions with experts such as genetic/fitness experts, participation
            in health and
            <br />
            wellness programs etc.
            <br />
            "Sensitive Personal Data or Information" with respect to a person means such personal
            <br />
            information which consists of information relating to:
            <br />
            (i) DNA data/saliva samples;
            <br />
            (ii) Recorded video uploaded by Users;
            <br />
            (iii) Blood reports and/or any other health assessment reports submitted/uploaded by the
            User
            <br />
            including the nutrition assessment form;
            <br />
            (iv) password;
            <br />
            (v) financial information such as bank account or credit card or debit card or other
            payment
            <br />
            instrument details;
            <br />
            (vi) physical, physiological and mental health condition;
            <br />
            (vii) sexual orientation;
            <br />
            (viii) medical records and history;
            <br />
            (ix) biometric information;
            <br />
            (x) any detail relating to the above clauses as provided to us for providing Products
            and
            <br />
            services; and
            <br />
            (xi) any of the information received under the above clauses by us for processing,
            stored or
            <br />
            processed under lawful contract or otherwise.
            <br />
            "User" capitalised or otherwise, means the person who accesses and/or uses the Platform,
            <br />
            associated services and if authorised and applicable, the entity on whose behalf any
            person
            <br />
            accesses and/or uses the Platform and associated services. It is clarified that the term
            User shall
            <br />
            include all Customers.
            <br />
            "User Account" shall have the meaning ascribed to it in Clause 3 of these Terms of Use.
            <br />3<br />
            "User Content" means any content such as text, photos, audio, video, or other materials
            and
            <br />
            information, created, uploaded, posted, sent, received and stored on or through the
            Platform by the
            <br />
            User and includes (without limitation) recorded training videos of the User.
            <br />
            "We" capitalized or otherwise, refers to unlock.fit and related phrases "us" and "our"
            should be
            <br />
            understood accordingly.
            <br />
            "You" capitalised or otherwise, means the User. "Your" capitalised or otherwise, has a
            <br />
            corresponding meaning.
            <br />
            1. ELIGIBILITY
            <br />
            1.1 By checking any acceptance boxes, clicking any acceptance buttons, submitting any
            text or
            <br />
            content or simply by browsing, accessing or making any use of the Platform, you: (i)
            accept
            <br />
            the Terms and agree to be bound by each of its terms; and (ii) represent and warrant to
            <br />
            unlock.fit that: (a) these Terms are binding and enforceable against you; (b) to the
            extent an
            <br />
            individual is accepting these Terms on behalf of an entity, such individual has the
            right and
            <br />
            authority to agree to all of the terms set forth herein on behalf of such entity; and
            (c) you
            <br />
            have read and understood our Privacy Policy, the terms of which are incorporated herein
            by
            <br />
            reference, and agree to abide by the Privacy Policy. These Terms are made between you
            and
            <br />
            us and shall come into effect on the date you access the Platform and/or download the
            <br />
            Application and/or your User Account is created whichever is earlier, and shall continue
            <br />
            unless and until terminated by us or you in accordance with the Terms.
            <br />
            1.2 In the event that the User or anyone acting on the User’s behalf does not wish to be
            bound by
            <br />
            the Terms, the User (or the legal person/entity acting on the User’s behalf)
            unequivocally
            <br />
            agrees to refrain from accessing, using or retaining the Platform and the Products on
            any
            <br />
            device in any manner whatsoever. The User agrees that anything done or caused to be done
            <br />
            by the User or anyone acting on the User’s behalf, whether expressly or impliedly that
            is in
            <br />
            contravention of the Terms will render the User liable for legal and punitive action.
            <br />
            1.3 While individuals under the age of 18 (eighteen) may utilize/browse the Platform,
            they shall
            <br />
            do so only with the involvement, guidance and supervision of their parents and / or
            legal
            <br />
            guardians, under such parent / legal guardian’s registered account. unlock.fit reserves
            the
            <br />
            right to terminate your access and refuse to provide you with access to the Platform if
            <br />
            unlock.fit discovers that you are under the age of 18 (eighteen) years or the required
            legal
            <br />
            age in your jurisdiction. You further represent and warrant that you are not under any
            legal
            <br />
            or other deficiency which prevents/may prevent you from: (i) entering into a valid
            contract
            <br />
            under the Applicable Laws; and (ii) making valid payment to unlock.fit for the services
            <br />
            availed by you.
            <br />
            1.4 You are responsible for, and agree to comply with, all laws, rules, and regulations
            applicable
            <br />
            to your use of the Platform and the Products, any transaction you enter into on the
            Platform,
            <br />
            or in connection with your use of the Platform.
            <br />
            2. ACCEPTANCE OF TERMS
            <br />
            These Terms of Use form an electronic contract that establishes legally binding terms
            that
            <br />
            the User must accept to use the Products provided by unlock.fit through the Platform.
            These
            <br />
            Terms of Use include by reference other terms disclosed and agreed to by the User in the
            <br />
            event the User registers for, purchases, or accepts additional features, services in
            addition to
            <br />
            the Products, including but not limited to terms governing features, billing, discounts,
            <br />
            promotions, etc.
            <br />4<br />
            3. ACCOUNT REGISTRATION
            <br />
            3.1 In order to access certain features of the Platform, and to purchase a Product, you
            must
            <br />
            register to create an account ("User Account"). The Platform requires you to register as
            a<br />
            Customer by creating a User Account in order to utilize the services offered by
            unlock.fit on
            <br />
            the Platform. You will be responsible for maintaining the confidentiality of the User
            Account
            <br />
            information, its password and are fully responsible for all activities that occur under
            your
            <br />
            User Account. You agree to: (i) immediately notify unlock.fit of any unauthorized use of
            the
            <br />
            User Account information or any other breach of security; and (ii) ensure that you
            exit/log
            <br />
            out from the account at the end of each session. unlock.fit cannot and shall not be
            liable for
            <br />
            any loss or damage arising from your failure to comply with this Clause. You may be held
            <br />
            liable for losses incurred by unlock.fit or any other customer or visitor to the
            Platform due to
            <br />
            authorized or unauthorized use of your User Account as a result of your failure in
            keeping
            <br />
            your User Account information secure and confidential.
            <br />
            3.2 Unlock.fit may require you to register your User Account through your account with
            certain
            <br />
            third-party social networking services, such as Facebook or Google ("SNS Account"). In
            <br />
            such case, you will have the ability to disable the connection between your User Account
            <br />
            and your SNS Account at any time, by accessing the "Settings" section of the Platform.
            <br />
            3.3 At the time of registration, you shall ensure that the User Account information
            provided by
            <br />
            you on the Platform registration form is complete, accurate and up-to-date. Use of
            another
            <br />
            User’s account information is expressly prohibited.
            <br />
            3.4 Unlock.fit allows restricted access to the Platform for unregistered Users. We shall
            also not
            <br />
            charge any fees for creating and registering a User Account on the Platform. Your access
            of
            <br />
            the Products and Platform is free of charge. You shall only be charged for any orders
            placed
            <br />
            by you on the Platform for purchasing the Products. However, please note that this is
            subject
            <br />
            to change and unlock.fit shall, at its sole discretion, have the right to charge Users
            any fee
            <br />
            that it may deem appropriate by amending these Terms of Use.
            <br />
            4. DESCRIPTION OF THE PRODUCTS
            <br />
            4.1 Through the Platform, you can browse through the Products, any information provided
            on
            <br />
            the Products by unlock.fit and purchase any of the Products. You may view the Products
            as
            <br />
            an unregistered visitor to the Platform. However, if you wish to purchase the Products,
            you
            <br />
            must first register as a Customer and create a User Account.
            <br />
            4.2 The Products are displayed on the Platform with their detailed description. We have
            made
            <br />
            every effort to display as accurately as possible the information, descriptions, and
            images of
            <br />
            our Products. We will not be held responsible for any mistakes or omissions to any
            <br />
            information given. We reserve the right to correct inaccuracies at any time without
            prior
            <br />
            notice.
            <br />
            4.3 We reserve the right to refuse any order you place with us. We reserve the right,
            but are not
            <br />
            obligated, to limit the provision of our Products and services to any person, geographic
            <br />
            region or jurisdiction. We may exercise this right on a case-by-case basis. All
            descriptions of
            <br />
            the Products are subject to change at any time without notice, at our sole discretion.
            We
            <br />
            reserve the right to discontinue any Product at any time. In the event that, we make a
            change
            <br />
            to or cancel an order, we may attempt to notify you by contacting the e-mail and/or
            billing
            <br />
            address/phone number provided at the time the order was made.
            <br />
            4.4 We do not warrant that the results and/or quality of any Products, information, or
            other
            <br />
            material purchased or obtained by you will meet your expectations, or that any errors in
            the
            <br />
            services will be corrected.
            <br />5<br />
            5. PRICING
            <br />
            5.1 The prices of the Products are as mentioned on the Platform and are inclusive of the
            base
            <br />
            price, all applicable taxes, shipping costs, excluding custom duties (if applicable).
            Please
            <br />
            note that depending on your geographical location, the shipping cost for the Products
            may
            <br />
            vary, and you shall be notified of the final price at the time of check out while making
            <br />
            payment for the Products on the Platform. We reserve the right to change the price of
            any of
            <br />
            our Products without any prior notice. You are obligated to confirm the price mentioned
            on
            <br />
            the Platform before placing any order. Please note however that in the event any change
            in
            <br />
            the Applicable Law or laws of the country where the Products are being delivered results
            in
            <br />
            an increase in the price of the Products including but not limited to an increase in
            custom
            <br />
            duties, export duties etc., then you shall be liable to bear such extra costs as
            communicated
            <br />
            to you by unlock.fit.
            <br />
            5.2 In connection with your requested purchase, you will be asked to provide customary
            billing
            <br />
            information such as name, billing address and credit/debit card information either to
            <br />
            unlock.fit or its third-party payment processor. You agree to pay unlock.fit for the
            purchase
            <br />
            made on the Platform in accordance with the Terms, by one of the methods described on
            the
            <br />
            Platform. You hereby authorize the collection of such amounts by charging the
            credit/debit
            <br />
            card provided, either directly by unlock.fit or indirectly, via a third party online
            payment
            <br />
            processor or by one of the payment methods described on the Platform. If you are
            directed to
            <br />
            unlock.fit's third party payment processor, you may be subject to terms and conditions
            <br />
            governing the use of that third party's service and that third party's personal
            information
            <br />
            collection practices. Please review their terms and conditions and privacy policy before
            <br />
            using the services. You will be solely responsible for the security and confidentiality
            of your
            <br />
            credit/ debit card details. unlock.fit expressly disclaims all liabilities that may
            arise as a<br />
            consequence of any unauthorized use of your credit/ debit card. Once your transaction is
            <br />
            complete, you will receive a confirmation email summarizing your purchase from
            unlock.fit.
            <br />
            5.3 From time to time, we may offer special promotions for some or all of our Products,
            <br />
            including discounts, limited edition Products etc. These offers may be for a limited
            time only
            <br />
            and we reserve the right to change or discontinue such offers at any time.
            <br />
            5.4 If we are unable to collect any amounts you owe for an order placed by you, we may
            engage
            <br />
            in collection efforts to recover such amounts from you. You hereby explicitly agree that
            all
            <br />
            communication in relation to amounts owed will be made by electronic mail or by phone,
            as
            <br />
            provided to us by you. Such communication may be made by us or by anyone on our behalf,
            <br />
            including but not limited to, a third-party collection agent.
            <br />
            6. DELIVERY OF PRODUCTS
            <br />
            6.1 The Products displayed on the Platform will include information with respect to the
            number
            <br />
            of days it will take for the confirmed orders to be delivered. Quoted or acknowledged
            <br />
            delivery dates are only estimated dates of delivery. We specifically disclaim liability
            for
            <br />
            delays in delivery and any resulting consequential damage or losses.
            <br />
            6.2 Delivery of DNA kits: After placing your order successfully, our logistics team will
            deliver
            <br />
            the DNA kit at the shipping address provided by you at the time of check out. You are
            <br />
            required to provide your samples as per the instructions given on the DNA kit and on our
            <br />
            Platform. You are required to log into your User Account and register your kit to
            schedule a<br />
            sample pick up date and time. Our logistics team will confirm the date and time on your
            <br />
            registered email and pick the sample according to the scheduled date. All information
            about
            <br />
            your kit purchase and progress will be available on your User Account dashboard.
            <br />6<br />
            6.3 We will always endeavor to fulfill your order once completed and paid for. If a kit
            should
            <br />
            become unavailable after your order has been confirmed and paid for, we will contact you
            to
            <br />
            make arrangements for later delivery or refund the price you paid for the kit without
            any
            <br />
            interest. You shall however not be entitled to claim any compensation in the event of an
            <br />
            overdue delivery period.
            <br />
            7. CANCELLATIONS AND REFUNDS
            <br />
            An order may be cancelled within 2 (two) days of placing the order by sending a written
            <br />
            request at support@unlock.fit. However, a cancellation fee shall be charged by
            unlock.fit if
            <br />
            kits have been dispatched, samples have been collected, or tests have been run. In case
            the
            <br />
            order has been delivered, we will only accept return of unopened kits, within 7 (seven)
            days
            <br />
            of receipt. Once your return is received and inspected, we will notify you of the
            approval or
            <br />
            rejection of your refund. If your request is approved, then your refund will be
            processed, and
            <br />a credit will automatically be applied to your credit card or original mode of
            payment within
            <br />
            15 (fifteen) days. Please note that return and refund of: (i) used kits; (ii) return
            request made
            <br />
            outside the specified time frame; and (iii) Kits with tampered or missing items will not
            be
            <br />
            entertained by us. Any refund by us would be provided after deducting any shipping and
            <br />
            delivery charges incurred by us.
            <br />
            8. INSPECTIONS AND COMPLAINTS
            <br />
            8.1 The Customer shall be obliged to carefully inspect the items immediately upon
            arrival at
            <br />
            their destination or to have these examined upon receipt by the Customer. unlock.fit
            must be
            <br />
            informed in writing of any complaints at support@unlock.fit in respect of any defects to
            the
            <br />
            items no later than within 3 (three) days after receipt of the items. No refunds shall
            be
            <br />
            processed should the Customer fail to intimate unlock.fit within the abovementioned
            term.
            <br />
            No refund requests shall be processed for any online programs and subscriptions once
            <br />
            subscribed to by the User. It is clarified that refunds shall only be processed for
            physical
            <br />
            items/Products purchased by the User on the Platform.
            <br />
            8.2 Any refund of the defective items shall be governed by the provisions of Clause 7
            above for
            <br />
            Cancellations and Refunds with respect to inspection of the returned item and timeline
            of the
            <br />
            refund, if approved. If the defect is confirmed by unlock.fit, either an exchange or
            full refund
            <br />
            will be provided to the Customer.
            <br />
            9. REVIEWS AND FEEDBACK
            <br />
            9.1 Reviews
            <br />
            (a) A User may have the option to leave a review regarding any Product purchased by the
            User
            <br />
            ("Review"). The completed Review may be: (i) uploaded onto our Platform for the sole
            <br />
            purpose of informing other Users of your opinion of the Product and its quality; and
            (ii)
            <br />
            (wholly or partly) used and placed by unlock.fit at its sole discretion (e.g. for
            marketing,
            <br />
            promotion, or improvement of our services) on our Platform or such social media
            platforms,
            <br />
            newsletters, special promotions, apps, or other channels owned, hosted, used, or
            controlled
            <br />
            by unlock.fit and our business partners. We reserve the right to adjust, refuse, or
            remove
            <br />
            Reviews at our sole discretion insofar it violates any terms as set forth in this
            Clause.
            <br />
            unlock.fit does not compensate or otherwise reward a User for completing a Review. The
            <br />
            Review form should be regarded as a survey and does not include any (further commercial)
            <br />
            offers, invitations, or incentives whatsoever. unlock.fit undertakes to use its best
            efforts to
            <br />
            monitor and remove reviews that include obscenities or the mention of an individual’s
            name
            <br />
            or reference to stolen goods.
            <br />
            (b) unlock.fit will not accept Reviews which include:
            <br />7<br />
            (i) profanity, sexually explicit, hate speech, discriminatory, threats, violence;
            <br />
            (ii) mention of full names, personal attack towards the staff;
            <br />
            (iii) promoting illegal activities (e.g. drugs, prostitution);
            <br />
            (iv) sites, emails and addresses, phone numbers, cc details; and/or
            <br />
            (v) politically sensitive comments.
            <br />
            9.2 Feedback
            <br />
            We welcome and encourage you to provide feedback, comments, and suggestions for
            <br />
            improvements to the Platform and Products ("Feedback"). You may submit Feedback by
            <br />
            emailing us at support@unlock.fit.
            <br />
            9.3 You acknowledge and agree that all the Reviews and Feedback will be the sole and
            exclusive
            <br />
            property of unlock.fit and you hereby irrevocably assign to unlock.fit and agree to
            <br />
            irrevocably assign to unlock.fit all of your rights, title, and interest in and to all
            the Reviews
            <br />
            and Feedback, including without limitation all worldwide patent, copyright, trade
            secret,
            <br />
            moral and other proprietary or Intellectual Property Rights therein. At unlock.fit's
            request
            <br />
            and expense, you will execute documents and take such further acts as unlock.fit may
            <br />
            reasonably request to assist unlock.fit to acquire, perfect, and maintain its
            Intellectual
            <br />
            Property Rights and other legal protections for the Reviews and Feedback.
            <br />
            10. TERM & TERMINATION
            <br />
            10.1 Term
            <br />
            These Terms will come into effect from the date that you access, browse the Platform and
            <br />
            shall remain in effect until terminated in accordance with the Terms.
            <br />
            10.2 Termination and/or Suspension
            <br />
            (a) We reserve the right, at our sole discretion, to suspend, deactivate or cancel your
            User
            <br />
            Account and/or your access to our Platform and/or the Products and/or our services,
            without
            <br />
            providing prior notice to you, if you: (i) breach the terms and conditions of the Terms;
            (ii)
            <br />
            provide any information during the registration process or thereafter which is proved to
            be
            <br />
            inaccurate, not current or incomplete; (iii) carry out an unauthorised use of the
            Platform,
            <br />
            and/or its services; or (iv) have used the Platform, Products, and/or our services in a
            manner
            <br />
            that violates the laws of India or which threatens the security or otherwise harms
            unlock.fit
            <br />
            or its affiliates, personnel of unlock.fit, or other Users of the Platform and third
            parties.
            <br />
            (b) You may terminate your User Account at any time by sending an email to
            <br />
            support@unlock.fit. Please note that if your User Account is terminated, we do not have
            an
            <br />
            obligation to delete or return to you any User Content you have posted to the Platform,
            <br />
            including, but not limited to, any Reviews or Feedback, if any.
            <br />
            (c) Notwithstanding any other provisions of the Terms, we reserve the right to suspend
            or
            <br />
            discontinue the Products, services and the Platform either temporarily or permanently
            and
            <br />
            either wholly or in part, for maintenance purposes or otherwise, without prior notice.
            <br />
            (d) Provisions in these Terms of Use that are by their nature intended to survive
            termination
            <br />
            (including, without limitation Clause 12 (Ownership and Rights), Clause 13
            <br />
            (Confidentiality), Clause 14 (Data Protection), Clause 15 (Indemnity), Clause 16
            <br />
            (Disclaimers), Clause 17 (Limitations of Liability), Clause 20 (Governing Law and
            <br />
            Jurisdiction), Clause 21 (Dispute Resolution), Clause 22 (Privacy) and Clause 24
            <br />
            (Miscellaneous)) will continue to apply to you with full force and effect
            notwithstanding any
            <br />8<br />
            termination of the Terms.
            <br />
            10.3 Amendment to the Terms
            <br />
            (a) We reserve the right to revise, modify, or update the Terms from time to time and
            the most
            <br />
            current version will be posted on the Platform. If a revision, in our sole discretion,
            is
            <br />
            material, we will notify you by e-mail or through any other means of communication.
            Other
            <br />
            revisions may be updated only on the Platform and you are responsible for checking such
            <br />
            postings regularly. By continuing to use the Products and/or Platform after revisions
            become
            <br />
            effective, you agree to be bound by the revised Terms. The revised Terms shall supersede
            all
            <br />
            prior versions. If you do not agree to the revised terms, you may terminate the Terms in
            <br />
            accordance with Clause 10.2 above.
            <br />
            (b) In addition, in the event any regulatory authority that has provided us any license
            to provide
            <br />
            the Products and/or Platform, revokes such license, you hereby agree that we may
            terminate
            <br />
            these Terms, at any time, without liability on our part.
            <br />
            11. USER CONDUCT
            <br />
            11.1 You understand and agree that you are solely responsible for your compliance with
            any and
            <br />
            all laws, rules, regulations, and tax obligations that may apply to your use of the
            Platform,
            <br />
            Products and Content. In connection with your use of our Platform, you may not, and you
            <br />
            agree that you will not:
            <br />
            (a) violate any local, state, provincial, national, or other law or regulation, or any
            order of a<br />
            court, including, without limitation, tax regulations;
            <br />
            (b) through any act or omission, threaten the unity, integrity, defense, security or
            sovereignty of
            <br />
            India, friendly relations with foreign states, or public order or causes incitement to
            the
            <br />
            commission of any cognizable offence or prevents investigation of any offence or is
            <br />
            insulting to any other nation;
            <br />
            (c) use manual or automated software, devices, scripts robots, other means or processes
            to
            <br />
            access, "scrape," "crawl" or "spider" any web pages or other services contained in the
            <br />
            Platform, or Content;
            <br />
            (d) use the Platform for any commercial or other purposes that are not expressly
            permitted by
            <br />
            these Terms;
            <br />
            (e) copy, store or otherwise access any information contained on the Platform for
            purposes not
            <br />
            expressly permitted by these Terms;
            <br />
            (f) infringe the rights of any person or entity, including without limitation, their
            Intellectual
            <br />
            Property, privacy, publicity or contractual rights;
            <br />
            (g) interfere with or damage our Platform, including, without limitation, through the
            use of
            <br />
            viruses, cancel bots, Trojan horses, harmful code, flood pings, denial-of-service
            attacks,
            <br />
            packet or IP spoofing, forged routing or electronic mail address information or similar
            <br />
            methods or technology;
            <br />
            (h) use our Platform, to transmit, distribute, post or submit any information concerning
            any
            <br />
            other person or entity, including without limitation, photographs of others without
            their
            <br />
            permission, personal contact information or credit, debit, calling card or account
            numbers;
            <br />
            (i) use our Platform in connection with the distribution of unsolicited commercial email
            <br />9<br />
            ("Spam") or advertisements unrelated to lodging in a private residence;
            <br />
            (j) "stalk" or harass any other user of our Platform, or collect or store any personally
            <br />
            identifiable information about any other user other than for purposes of transacting as
            a<br />
            User;
            <br />
            (k) register for more than one User Account or register for a User Account on behalf of
            an
            <br />
            individual other than yourself without being authorized to do so;
            <br />
            (l) impersonate any person or entity, or falsify or otherwise misrepresent yourself or
            your
            <br />
            affiliation with any person or entity;
            <br />
            (m) use automated scripts to collect information or otherwise interact with the
            Platform;
            <br />
            (n) post, upload, display, publish, submit or transmit any information or User Content
            that: (i)
            <br />
            infringes, misappropriates or violates any third party's patent, copyright, trademark,
            trade
            <br />
            secret, moral rights or other Intellectual Property Rights, or rights of publicity or
            privacy; (ii)
            <br />
            violates, or encourages any conduct that would violate, any Applicable Law or regulation
            or
            <br />
            would give rise to civil liability; (iii) is fraudulent, false, misleading or deceptive;
            (iv) is
            <br />
            blasphemous, defamatory, obscene, libelous, pornographic, pedophilic, vulgar or
            offensive;
            <br />
            (v) promotes discrimination, bigotry, racism, hatred, harassment or harm against any
            <br />
            individual or group; (vi) harms minors in any way; (vii) is violent or threatening or
            promotes
            <br />
            violence or actions that are threatening to any other person; (viii) is relating or
            encouraging
            <br />
            money laundering or gambling; (ix) promotes illegal or harmful activities or substances;
            (x)
            <br />
            deceives or misleads the addressee/users about the origin of such messages or
            communicates
            <br />
            any information which is grossly offensive or menacing in nature; or (xi) creates
            liability for
            <br />
            unlock.fit or causes it to lose (in whole or in part) the services of ISPs or other
            suppliers.
            <br />
            (o) systematically retrieve, copy or download data or other Content from our Platform,
            to create
            <br />
            or compile, directly or indirectly, in single or multiple downloads, a collection,
            compilation,
            <br />
            database, directory or the like, whether by manual methods, through the use of bots,
            <br />
            crawlers, or spiders, or otherwise, either for your benefit or for the benefit of any
            other
            <br />
            persons;
            <br />
            (p) use, display, mirror or frame the Platform, or any individual element within the
            Platform, or
            <br />
            Products or services, unlock.fit's name, any unlock.fit trademark, logo or other
            proprietary
            <br />
            information, or the layout and design of any page or form contained on a page, without
            <br />
            unlock.fit's express written consent;
            <br />
            (q) access, tamper with, or use non-public areas of the Platform, unlock.fit's computer
            systems,
            <br />
            or the technical delivery systems of unlock.fit's providers;
            <br />
            (r) attempt to probe, scan, or test the vulnerability of any unlock.fit system or
            network or breach
            <br />
            any security or authentication measures;
            <br />
            (s) avoid, bypass, remove, deactivate, impair, descramble, or otherwise circumvent any
            <br />
            technological measure implemented by unlock.fit or any of unlock.fit's providers or any
            <br />
            other third party (including another user) to protect the Platform, and Content;
            <br />
            (t) forge any TCP/IP packet header or any part of the header information in any email or
            <br />
            newsgroup posting, or in any way use the Platform, or Content to send altered, deceptive
            or
            <br />
            false source-identifying information; or
            <br />
            (u) attempt to decipher, decompile, disassemble or reverse engineer any of the software
            used to
            <br />
            provide the Platform, or Content; or advocate, encourage, or assist any third party in
            doing
            <br />
            10
            <br />
            any of the foregoing.
            <br />
            11.2 Unlock.fit will have the right to investigate and prosecute violations of any of
            the above to
            <br />
            the fullest extent of the law. unlock.fit may involve and cooperate with law enforcement
            <br />
            authorities in prosecuting Users who violate these Terms of Use. You acknowledge that
            <br />
            unlock.fit has no obligation to monitor your access to or use of the Platform, or the
            Products,
            <br />
            or Content or to review or edit any User Content, but has the right to do so for the
            purpose of
            <br />
            operating the Platform: (i) to ensure your compliance with these Terms of Use, including
            <br />
            investigation of potential violations hereof; (ii) to comply with Applicable Law or the
            order
            <br />
            or requirement of a court, administrative agency or other governmental body; (iii)
            detect,
            <br />
            prevent, or otherwise address fraud, security or technical issues; (iv) respond to user
            support
            <br />
            requests; or (v) protect our rights, property or safety of our users and the public.
            unlock.fit
            <br />
            reserves the right, at any time and without prior notice, to remove, suspend, edit, or
            disable
            <br />
            access to any User Content that unlock.fit, at its sole discretion, considers to be
            <br />
            objectionable for any reason (including, but not limited to, upon receipt of claims or
            <br />
            allegations from third parties or authorities relating to such User Content), in
            violation of
            <br />
            these Terms or otherwise harmful to the Platform.
            <br />
            12. OWNERSHIP & RIGHTS
            <br />
            12.1 Data Usage & Retention
            <br />
            (a) The Personal Data that you provide to us or otherwise authorise us to have access
            to, is your
            <br />
            property.
            <br />
            (b) By purchasing our Products, and/or accessing the Platform, you grant us a license to
            collect,
            <br />
            use, copy, transmit, store and back-up your Personal Data for purposes of providing the
            <br />
            Products and/or for any other purpose(s) as contemplated by the Terms.
            <br />
            (c) All Personal Data and Sensitive Personal Data provided to us by you shall be
            governed by
            <br />
            the Privacy Policy.
            <br />
            12.2 Intellectual Property
            <br />
            (a) You acknowledge and agree that unlock.fit owns and reserves the right, title and
            interest in
            <br />
            and to the Products, Platform and Content.
            <br />
            (b) As part of the Products, we grant you a non-exclusive, limited, royalty-free,
            revocable
            <br />
            license, during the term of the Terms of Use to use our Platform to facilitate your
            personal,
            <br />
            non-commercial use of our Products. Any rights relating to our Platform and Content that
            we
            <br />
            do not expressly grant to you in writing are expressly reserved, and your access to and
            use of
            <br />
            our Platform does not grant you an express or implied licence in respect of any of the
            <br />
            Intellectual Property Rights that are owned by, licensed to, or controlled by us and our
            <br />
            licencees.
            <br />
            (c) To the extent required in order for us to operate this Platform and provide you with
            the
            <br />
            Products, and/or promote the Platform, in any media or platform, you grant to us a<br />
            non-exclusive, world-wide, royalty-free, transferrable, irrevocable licence and right to
            host,
            <br />
            publicly display, transmit, distribute, or use (that includes the right to copy,
            reproduce,
            <br />
            and/or publish) the User Content you upload onto this Platform. Insofar as User Content
            <br />
            (including images) includes Sensitive Personal Data, such User Content will only be used
            for
            <br />
            those purposes if such use complies with the applicable Data Protection Laws in
            accordance
            <br />
            with the Terms of Use and the Privacy Policy. Unless otherwise agreed between the
            Parties,
            <br />
            unlock.fit does not claim any ownership rights in any User Content and nothing in the
            Terms
            <br />
            will be deemed to restrict any rights that the User may have to use or exploit its User
            <br />
            11
            <br />
            Content.
            <br />
            (d) You acknowledge and agree that you are solely responsible for all User Content that
            you
            <br />
            make available through the Platform. Accordingly, you represent and warrant that: (i)
            you
            <br />
            either are the sole and exclusive owner of all User Content that you make available
            through
            <br />
            the Platform or you have all rights, licenses, consents and releases that are necessary
            to grant
            <br />
            to unlock.fit the rights in such User Content, as contemplated under these Terms; and
            (ii)
            <br />
            neither the User Content nor your posting, uploading, publication, submission or
            transmittal
            <br />
            of the User Content or unlock.fit's use of the User Content (or any portion thereof) on,
            <br />
            through or by means of the Platform, will infringe, misappropriate or violate a third
            party's
            <br />
            patent, copyright, trademark, trade secret, moral rights or other proprietary or
            Intellectual
            <br />
            Property Rights, or rights of publicity or privacy, or result in the violation of any
            Applicable
            <br />
            Law or regulation.
            <br />
            (e) You acknowledge and agree that ‘unlock.fit’ is our trademark and shall not be used
            by you
            <br />
            without our prior written consent.
            <br />
            (f) Any distribution, reprint or electronic reproduction of any Content from the
            Platform, in
            <br />
            whole or in part, is strictly prohibited without our prior written consent.
            <br />
            (g) You acknowledge and agree that you shall not copy, modify, transmit, create any
            derivative
            <br />
            works from, make use of, or reproduce in any way any copyrighted material, trademarks,
            <br />
            trade names, service marks, or other Intellectual Property or proprietary information
            <br />
            accessible through the Products and/or Platform, without first obtaining the prior
            written
            <br />
            consent of unlock.fit.
            <br />
            13. CONFIDENTIALITY
            <br />
            During the course of your use of our Products and/or the Platform, you may receive
            <br />
            information relating to us or to our Products, that is not known to the general public
            <br />
            ("Confidential Information"). You agree that: (i) all Confidential Information will
            remain
            <br />
            unlock.fit's exclusive property; (ii) you will use Confidential Information only as is
            <br />
            reasonably necessary for your participation in our services and use of the Platform;
            (iii) you
            <br />
            will not otherwise disclose Confidential Information to any other person or Third Party;
            and
            <br />
            (iv) you will take all reasonable measures to protect the Confidential Information
            against any
            <br />
            use or disclosure that is not expressly permitted in these Terms. You may not issue any
            press
            <br />
            release or make any public statement related to our Products or services, or use our
            name,
            <br />
            trademarks, or logo, in any way (including in promotional material) without our advance
            <br />
            written permission, or misrepresent or embellish the relationship between us in any way.
            <br />
            14. DATA PROTECTION
            <br />
            You agree that we shall have the right to collect and/or use or analyse your Personal
            Data or
            <br />
            Sensitive Personal Data as set forth in the Privacy Policy.
            <br />
            15. INDEMNITY
            <br />
            15.1 You release unlock.fit and agree and undertake to fully indemnify and hold
            unlock.fit, its
            <br />
            affiliates, and its respective officers, directors, employees, successors,
            representatives, and
            <br />
            agents harmless from and against all Losses howsoever arising from all claims,
            allegations,
            <br />
            actions, proceedings, demands, or costs brought by a third party or other liability or
            expenses
            <br />
            (including, without limitation, attorneys’ fees) (each, a "Claim") that we may sustain
            or
            <br />
            incur, directly or indirectly, arising from or as a result of your: (i) actual or
            alleged breach of
            <br />
            the Terms; (ii) use of the Platform; (iii) access to, use, or Misuse or Abuse of the
            Content on,
            <br />
            or any Products or service(s) offered through, our Platform; (iv) misconduct in any
            manner,
            <br />
            12
            <br />
            including negligence and fraud, in connection with your use of our Platform, Products or
            any
            <br />
            of the services; or (v) any actual or alleged infringement of any Intellectual Property
            Rights
            <br />
            by you, and any personal injury, death, or property damage related thereto.
            <br />
            15.2 We will notify you of any such Claim or proceeding and assist you, at your expense,
            in
            <br />
            defending the same. We reserve the right to assume, at your expense, the exclusive
            control
            <br />
            and defence of any matter that is or may be subject to indemnification under this
            Clause.
            <br />
            Should we exercise this right, you nevertheless agree to cooperate with any reasonable
            <br />
            requests we make of you to assist with our defence of such matter.
            <br />
            16. DISCLAIMER
            <br />
            16.1 UNLOCK.FIT AND ITS AFFILIATES MAKE NO REPRESENTATIONS OR
            <br />
            WARRANTIES OF ANY KIND, EXPRESS, STATUTORY OR IMPLIED AS TO THE
            <br />
            OPERATION OF THE PLATFORM, PROVISION OF PRODUCTS OR SERVICES OR
            <br />
            THE INFORMATION, CONTENT, OR MATERIALS OR SERVICES INCLUDED ON
            <br />
            THE PLATFORM. TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE
            <br />
            LAW, UNLOCK.FIT AND ITS AFFILIATES DISCLAIM ALL WARRANTIES,
            <br />
            EXPRESS, STATUTORY, OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
            <br />
            IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A<br />
            PARTICULAR PURPOSE AND NON-INFRINGEMENT. UNLOCK.FIT AND
            <br />
            AFFILIATES FURTHER DO NOT WARRANT THE ACCURACY OR COMPLETENESS
            <br />
            OF THE INFORMATION, TEXT, GRAPHICS, LINKS OR OTHER ITEMS CONTAINED
            <br />
            WITHIN THE SERVICES. UNLOCK.FIT IS NOT RESPONSIBLE FOR THE CONDUCT
            <br />
            OF ANY USER OF THE PLATFORM. UNLOCK.FIT DOES NOT WARRANT OR
            <br />
            COVENANT THAT THE SERVICES OR PLATFORM WILL BE AVAILABLE AT ANY
            <br />
            TIME OR FROM ANY PARTICULAR LOCATION, WILL BE SECURE OR
            <br />
            ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE PRODUCTS
            <br />
            AND SERVICES AND ACCESS TO THE PLATFORM IS FREE OF VIRUSES OR
            <br />
            OTHER POTENTIALLY HARMFUL COMPONENTS. ANY MATERIAL OR CONTENT
            <br />
            DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF OUR
            <br />
            PRODUCTS OR SERVICES OR THE PLATFORM IS ACCESSED AT YOUR OWN
            <br />
            DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY
            <br />
            DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS
            <br />
            FROM THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR
            <br />
            INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY ANY USER FROM
            <br />
            THE PLATFORM, OR THROUGH OR FROM THE SERVICES SHALL CREATE ANY
            <br />
            WARRANTY NOT EXPRESSLY STATED HEREIN.
            <br />
            16.2 THE PLATFORM AND THE PRODUCTS AND SERVICES OFFERED THROUGH THE
            <br />
            PLATFORM ARE ONLY AIMED TO PROVIDE ASSISTANCE TO YOU. UNLOCK.FIT
            <br />
            IS NOT A MEDICAL ORGANIZATION AND OUR EXPERTS CANNOT AND DO NOT
            <br />
            GIVE YOU MEDICAL ADVICE OR DIAGNOSIS. NOTHING CONTAINED ON OUR
            <br />
            PLATFORM AND/OR SERVICES AND/OR PRODUCTS SHOULD BE CONSTRUED
            <br />
            AS SUCH ADVICE OR DIAGNOSIS. THE INFORMATION AND EMAILS CREATED
            <br />
            BY US FOR YOU ARE FOR GUIDANCE PURPOSES ONLY AND ARE NOT A<br />
            SUBSTITUTE FOR PHYSICIAN CONSULTATION, EVALUATION, OR TREATMENT.
            <br />
            16.3 OUR EXPERTS MAY PROVIDE NUTRITIONAL CONSULTANCY AIMED AT
            <br />
            OPTIMISING NUTRITION CHOICES. HOWEVER, KEEPING IN MIND THE
            <br />
            COMPLEXITY OF THE HUMAN BODY AND BEHAVIOR, IT IS NOT POSSIBLE FOR
            <br />
            UNLOCK.FIT TO GUARANTEE RESULTS. YOU ARE URGED AND ADVISED TO
            <br />
            SEEK THE ADVICE OF A PHYSICIAN BEFORE BEGINNING ANY DIET REGIMEN.
            <br />
            13
            <br />
            16.4 Neither shall unlock.fit be responsible for the delay or inability to use the
            Platform or the
            <br />
            Products, the provision of or failure to provide Products, or for any information,
            software,
            <br />
            services and related graphics obtained through the Platform, or otherwise arising out of
            the
            <br />
            use of the Platform, whether based on contract, tort, negligence, strict liability or
            otherwise.
            <br />
            Further, unlock.fit shall not be held responsible for non-availability of the Platform
            during
            <br />
            periodic maintenance operations or any unplanned suspension of access to the Application
            <br />
            that may occur due to technical reasons or for any reason beyond the Platform's control.
            The
            <br />
            User understands and agrees that any material and/or data downloaded or otherwise
            obtained
            <br />
            through the Platform is done entirely at their own discretion and risk and they will be
            solely
            <br />
            responsible for any damage to their computer systems or loss of data that results from
            the
            <br />
            download of such material and/or data.
            <br />
            16.5 THESE LIMITATIONS, DISCLAIMER OF WARRANTIES AND EXCLUSIONS APPLY
            <br />
            WITHOUT REGARD TO WHETHER THE DAMAGES ARISE FROM (A) BREACH OF
            <br />
            CONTRACT, (B) BREACH OF WARRANTY, (C) NEGLIGENCE, OR (D) ANY OTHER
            <br />
            CAUSE OF ACTION, TO THE EXTENT SUCH EXCLUSION AND LIMITATIONS ARE
            <br />
            NOT PROHIBITED BY APPLICABLE LAW.
            <br />
            16.6 Whilst we will use reasonable commercial efforts to ensure that all your submitted
            Personal
            <br />
            Data and Confidential Information is accurately captured, extracted and/or entered into
            our
            <br />
            system, we do not warrant that this process or that any reports and/or analysis
            generated by
            <br />
            the Platform will be 100% (hundred percent) error free. You are responsible for
            reviewing
            <br />
            and verifying all such reports and/or analysis and promptly informing us of any errors
            noted
            <br />
            in writing. Subject to that, we will take steps to investigate and rectify any confirmed
            errors
            <br />
            as soon as reasonably practicable following receipt of your notification.
            <br />
            17. LIMITATION OF LIABILITY
            <br />
            17.1 To the maximum extent permitted by law, we will not be liable to you for any
            Losses,
            <br />
            (including loss of information, data, revenues, profits or savings) resulting, directly
            or
            <br />
            indirectly, out of, or in any way connected with your access to, use of, or reliance on
            the
            <br />
            Platform, Products or services including any special, consequential, punitive,
            incidental or
            <br />
            other losses, expenses or damages arising out of or in connection with the use of the
            <br />
            Platform whether in negligence, contract, tort, strict liability, or any other legal
            theory. You
            <br />
            assume full responsibility for the use of the Products and/or the Platform.
            <br />
            17.2 We may at our sole discretion and without assigning any reason whatsoever at any
            time,
            <br />
            deactivate or/and suspend your User Account and/or access to the Platform without giving
            <br />
            any prior notice, to carry out system maintenance or/and upgrading or/and testing or/and
            <br />
            repairs or/and other related work. Without prejudice to any other provisions of these
            Terms,
            <br />
            we shall not be liable to indemnify you for any loss or/and damage or/and costs or/and
            <br />
            expense that you may suffer or incur, and no fees or/and charges payable by you to the
            <br />
            Platform for any purchase shall be deducted or refunded or rebated, as a result of such
            <br />
            deactivation or/and suspension.
            <br />
            17.3 We may suspend performance of our services or access to the Products and Platform
            without
            <br />
            liability where we have identified an actual or potential personal, financial or legal
            risk to the
            <br />
            User (which may include the following circumstances: (i) if you breach these Terms or
            any
            <br />
            of our policies; (ii) if you fail to reasonably cooperate with an investigation by us;
            or (iii)
            <br />
            where we reasonably believe that your continued provision of any of our services would
            <br />
            expose you or us or our respective affiliates or customers to a material security risk
            or a<br />
            regulatory action.
            <br />
            17.4 Without limiting Clause 17.1 above, if you suffer Losses or damage as a result of
            our gross
            <br />
            negligence or willful failure to comply with our obligations under these Terms, any
            claim by
            <br />
            14
            <br />
            you against us will in any event be limited to the amount paid by you to unlock.fit for
            that
            <br />
            particular Product or service which is purchased by you.
            <br />
            17.5 Market data and other information made available to you through the Platform may be
            <br />
            obtained by unlock.fit from third parties. Whilst unlock.fit believes such market data
            or
            <br />
            information to be reliable, neither unlock.fit nor such third parties guarantee the
            accuracy,
            <br />
            completeness or timeliness of any such market data or information and are provided on
            "as
            <br />
            is" basis.
            <br />
            17.6 You are solely responsible for all of your communications and interactions with
            other Users
            <br />
            of the Platform, and with other persons with whom you communicate or interact as a
            result
            <br />
            of your use of the Platform, Products or services, including, but not limited to, any
            Users.
            <br />
            You understand that unlock.fit does not undertake to verify the statements of Users of
            the
            <br />
            Platform, or the Reviews of any Products. unlock.fit makes no representations or
            warranties
            <br />
            as to the conduct of Users of the Platform or their compatibility with any current or
            future
            <br />
            Users of the Platform. You agree to take reasonable precautions in all your
            communications
            <br />
            and interactions with other Users of the Platform and with other persons with whom you
            <br />
            communicate or interact as a result of your use of the Platform, including, but not
            limited to,
            <br />
            the Users, particularly if you decide to meet offline or in person regardless of whether
            such
            <br />
            meetings are organized by unlock.fit.
            <br />
            17.7 You expressly agree that in the event of any statute, rule, regulation or amendment
            coming
            <br />
            into force that would result in unlock.fit/Platform incurring any form of liability
            whatsoever,
            <br />
            these Terms and any agreement thereof will stand terminated 1 (one) day before the
            coming
            <br />
            into effect of such statute, rule, regulation or amendment.
            <br />
            18. THIRD PARTY WEBSITES
            <br />
            18.1 The Platform may also enable you to link to the websites and to access the content,
            products
            <br />
            and/or services of third parties, including users, advertisers, affiliates and sponsors
            of such
            <br />
            third parties. We have no control over and are not responsible for any such third-party
            <br />
            websites or content, products or services and you agree to bear all risks associated
            with your
            <br />
            access to and/or use of any such third-party websites, content and services.
            <br />
            18.2 You are aware that all such third-party content is the responsibility of the
            respective authors
            <br />
            thereof, and unlock.fit does not make any warranties or guarantees with respect to the
            same.
            <br />
            You are further aware that unlock.fit does not: (i) guarantee the accuracy,
            completeness, or
            <br />
            usefulness of any third party content provided through the Platform; or (ii) adopt,
            endorse or
            <br />
            accept responsibility for the accuracy or reliability of any opinion or statement made
            by any
            <br />
            party that appears in the Platform. You acknowledge and agree that under no
            circumstances
            <br />
            will unlock.fit or its affiliates be responsible or liable for any Losses resulting from
            your
            <br />
            reliance on information or other Content posted on the Platform or transmitted to or by
            any
            <br />
            Users or third parties.
            <br />
            19. FORCE MAJEURE
            <br />
            Neither Party shall be liable for any failure or delay on its part in performing its
            obligations
            <br />
            under these Terms, if such failure or delay is due in whole or in part, to "Force
            Majeure"
            <br />
            conditions. Force Majeure for these Terms, shall mean incidents which could not have
            been
            <br />
            reasonably predicted and which have resulted from circumstances beyond the control of
            the
            <br />
            affected Party and which are limited to act of God, or governmental act, political
            instability,
            <br />
            epidemic, pandemic, flood, fire, explosion, accident, civil commotion, war or computer
            <br />
            viruses. The Party whose performance is prevented by Force Majeure shall take all
            <br />
            reasonable actions within its power to comply as fully as possible herewith and to
            preserve
            <br />
            and protect the respective interests of the other Party hereto.
            <br />
            15
            <br />
            20. GOVERNING LAW AND JURISDICTION
            <br />
            20.1 These Terms will be governed by the laws of India.
            <br />
            20.2 The courts of New Delhi shall have exclusive jurisdiction over any disputes between
            the
            <br />
            Parties arising out of or in relation to these Terms.
            <br />
            21. DISPUTE RESOLUTION
            <br />
            21.1 Any complaint or dispute can be raised in writing to our compliance team at
            <br />
            legal@unlock.fit.
            <br />
            22. PRIVACY
            <br />
            Use of the Platform and the Products are also governed by the Privacy Policy. We only
            use
            <br />
            your information as described in the Privacy Policy. We view protection of your privacy
            as a<br />
            very important community principle. If you object to the Privacy Policy in any way,
            please
            <br />
            do not use the Platform and/or the Products.
            <br />
            23. GRIEVANCE OFFICER
            <br />
            In the event you have any grievances or questions about the Platform or if you wish to
            make
            <br />a complaint regarding any violation of the provisions of the Privacy Policy and
            the way
            <br />
            your Personal Data is processed, you may contact us at the details as set out below,
            pursuant
            <br />
            to which your grievance shall be redressed within 1 (one) month from the date of receipt
            of
            <br />
            the grievance/complaint.
            <br />
            Name: Shah Fahad Husami
            <br />
            Email ID: fahad@unlock.fit
            <br />
            24. MISCELLANEOUS
            <br />
            24.1 Waiver
            <br />
            The failure of a Party at any time to insist on performance of any provision of the
            Terms is
            <br />
            not a waiver of that Party’s right at a later time to insist on performance of that or
            any other
            <br />
            governing provision of the Terms.
            <br />
            24.2 Severability
            <br />
            If any term or provision of the Terms is held by a court of competent jurisdiction to be
            <br />
            invalid, void or unenforceable, the remainder of the terms and provisions of the Terms
            shall
            <br />
            remain in full force and effect and shall in no way be affected, impaired or
            invalidated.
            <br />
            24.3 Notices
            <br />
            Any notice given under the Terms by either Party to the other must be in writing, by
            email,
            <br />
            or by electronic communication via the Platform and will be deemed to have been given on
            <br />
            transmission, unless the recipient can satisfactorily establish that the email or
            electronic
            <br />
            communication was not received by the recipient’s email or web server. Notices to
            unlock.fit
            <br />
            must be sent by email to legal@unlock.fit or to any other email address notified by
            email to
            <br />
            the Users by unlock.fit, or by electronic communication via the Platform from time to
            time
            <br />
            for such purpose. Notices to the Users shall be sent to the email address provided when
            <br />
            16
            <br />
            registering for setting up their User Account or by such other electronic communication
            via
            <br />
            the Platform.
            <br />
            24.4 Assignment
            <br />
            The User shall not have the right to assign or transfer the Terms, or any part thereof,
            without
            <br />
            the prior written consent of unlock.fit. Any assignment without such consent shall be
            void
            <br />
            and shall have no effect. unlock.fit shall have the right to assign or transfer the
            Terms
            <br />
            without requiring the prior approval of the Users.
            <br />
            24.5 Rights of Third Parties
            <br />A person who is not a Party to these Terms has no right to benefit under or
            entitlement to
            <br />
            enforce any term of these Terms.
            <br />
            24.6 Relationship of Parties
            <br />
            You acknowledge and agree that nothing in the Terms shall cause or constitute the
            Parties to
            <br />
            be partners, agents or fiduciaries of each other. You will have no authority to make or
            accept
            <br />
            any offers or representations on our behalf. The Terms will not create an exclusive
            <br />
            relationship between you and us.
          </p>
        </Wrapper>
      </MainWrapper>
    </>
  );
};

export default Terms;
