import styled from 'styled-components/macro';

export const Div = styled.div`
  display: flex;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  overflow: hidden;
`;

export const Wrapper = styled.div`
  display: flex;
  max-width: 80%;
  padding: 40px;
  background-color: #f2f2f2;
  @media (max-width: 800px) {
    justify-content: flex-end;
    flex-direction: column-reverse;
    max-width: 95%;
    padding: 10px;
  }
`;

export const LeftWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1024px) {
    padding-left: 0;
  }
  @media (max-width: 800px) {
    flex: 0.2;
    padding-bottom: 3rem;
  }
  @media (max-width: 600px) {
    padding-left: 2rem;
  }

  h1 {
    color: #d2a614;
    font-weight: 700;
    font-size: 60px;

    @media (max-width: 1024px) {
      font-size: 2rem;
    }
  }

  h3 {
    letter-spacing: 1px;
    font-size: 36px;
    font-weight: 400;
    color: #19191b;
    @media (max-width: 450px) {
      font-size: 28px;
    }
  }
`;

export const Image = styled.div`
  display: flex;
  justify-content: space-between;
  width: 22rem;

  @media (max-width: 1024px) {
    width: 18rem;
  }
  @media (max-width: 800px) {
    width: 28rem;
    justify-content: center;
    margin: 0;
  }
  @media (max-width: 600px) {
    width: 22rem;
  }
  @media (max-width: 450px) {
    width: 18rem;
  }
  img {
    @media (max-width: 1024px) {
      width: 179px;
      height: 60px;
    }
    @media (max-width: 800px) {
      width: 220px;
      height: 70px;
    }
    @media (max-width: 600px) {
      width: 179px;
      height: 60px;
    }
    @media (max-width: 450px) {
      width: 150px;
      height: 50px;
    }
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 600px) {
  }
`;
export const Heading = styled.div`
  display: flex;
  align-items: flex-end;
  img {
    margin-right: 1rem;
    width: 62px;
    height: 62px;

    @media (max-width: 1024px) {
      width: 48px;
      height: 48px;
    }
    @media (max-width: 400px) {
      width: 32px;
      height: 32px;
    }
  }

  h2 {
    @media (max-width: 1024px) {
      font-size: 1.5rem;
    }
    @media (max-width: 400px) {
    }
  }
`;

export const RightWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

//desktop
export const ImageContainer = styled.div`
  img {
    width: 99%;
    margin-bottom: -10rem;
    @media (max-width: 800px) {
      display: none;
    }
  }
`;

//mobile
export const ImageContainer1 = styled.div`
  img {
    padding: 5rem;
    margin: auto;
    display: none;
    @media (max-width: 800px) {
      display: block;
      width: 50%;
    }
  }
`;
