import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #f8f8f8;

  /* @media (max-width: 1440px) { */
  /* margin-top: 7rem; */
  /* margin-top: ${(props) => (props.bg === 'up' ? '-30rem' : '-40rem')};
  } */
  @media (max-width: 700px) {
    margin: 0rem;
  }
  @media (max-height: 640px) {
  }
`;
export const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6rem;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  /* background-color: red; */
  background-color: #fbfbfb;

  @media (max-width: 600px) {
    margin: 5rem 1rem;
  }
`;

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  text-align: left;
  padding: 1rem;
  width: 100%;
  box-shadow: 0px 2px 30px rgba(167, 167, 167, 0.25);
  border-radius: 10px;

  @media (max-width: 768px) {
    box-shadow: none;
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: Roboto;
  justify-content: center;
  /* align-items: center; */
  width: 90%;

  @media (max-width: 700px) {
    width: 100%;
  }
  h1 {
    font-family: 'Roboto', sans-serif;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #000;
    font-size: 2.3rem;
    margin-top: 3rem;

    @media (max-width: 1000px) {
      font-size: 1.7rem;
    }
    @media (max-width: 700px) {
      font-size: 1.3rem;
    }
  }

  label {
    @media (max-width: 1000px) {
      font-size: 0.8rem;
    }
  }

  Input {
    @media (max-width: 1000px) {
      font-size: 0.8rem;
    }
  }
`;
export const Img = styled.div`
  display: flex;
  /* margin-top: -40rem; */
  margin-top: ${(props) => (props.bg === 'up' ? '-30rem' : '-40rem')};
  img {
    width: 100rem;
    height: 28rem;
  }
`;

export const RightWrapper = styled.div`
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* width: 50%; */

  @media (max-width: 700px) {
    display: none;
  }

  img {
    margin-left: 6rem;
    width: 30rem;
    height: 30rem;
    border-radius: 20px;

    @media (max-width: 1000px) {
      width: 20rem;
      height: 20rem;
    }
    @media (max-width: 700px) {
      /* width: 10rem;
      height: 10rem; */
      display: none;
    }
    @media (max-width: 450px) {
      display: none;
    }
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* margin: 5rem 0; */
  /* height: 150vh; */
  /* padding: 0 20px; */
  @media (max-width: 1200px) {
    margin: 0 1rem;
  }
  /* @media(max-width:1000px){
    margin: 0 6rem;
  } */
`;
export const Form = styled.form`
  padding: 2rem;
  width: 50%;

  box-sizing: border-box;
  border-radius: 28px;
  box-shadow: 0px 2px 30px rgba(167, 167, 167, 0.25);
  span {
    color: red;
  }
  button {
    background-color: #000000;
    height: 3rem;
    width: 10rem;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    border: none;
    margin-bottom: 5rem;
    margin-top: 2rem;
    font-size: 1rem;

    :hover {
      background-color: #ccc;

      color: black;
    }
  }
`;
export const Input = styled.input`
  display: block;
  width: 100%;
  background-color: #ececec;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 20px 0;
  padding: 20px;
  box-sizing: border-box;
`;
export const Textarea = styled.textarea`
  display: block;
  width: 100%;
  background-color: #ececec;
  resize: none;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 20px 0;
  padding: 20px;
  box-sizing: border-box;
`;

export const Input1 = styled.input`
  display: block;
  width: 100%;
  background-color: #ececec;

  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 20px 0;
  padding: 20px;
  box-sizing: border-box;
  /* height: 40px; */
  padding: 40px;
`;
export const Input2 = styled.input`
  display: block;
  width: 100%;
  background-color: #ececec;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 20px 0;
  padding: 20px;
  box-sizing: border-box;
`;

export const Button = styled.button`
  padding: 1rem;
`;
export const ButtonWrapper = styled.div``;
export const Side = styled.div`
  display: flex;

  label {
    margin-right: 1rem;
  }
`;
