import styled from 'styled-components/macro';

export const IntroSection = {};
IntroSection.SupperWrapper = styled.div`
  align-items: center;
  position: relative;
  height: 100vh;
  width: 100%;
  // background-color: #fdfdfd;
  @media (max-width: 1024px) {
    height: 70vh;
  }
  @media (max-width: 800px) {
    height: 100vh;
  }
  @media (max-width: 600px) {
    // height: 75vh;
  }
  @media (max-width: 350px) {
    // height: 85vh;
  }
`;

IntroSection.Wrapper = styled.div`
  height: 90vh;
  width: 80%;
  display: flex;
  flex-direction: row-reverse;
  margin: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  @media (max-width: 800px) {
    flex-direction: column;
    // height: 60vh;
    justify-content: center;
  }
`;

IntroSection.LeftWrapper = styled.div`
  flex: 1;
  display: flex;
  margin-right: 4rem;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1200px) {
    margin-right: 2rem;
  }
  @media (max-width: 800px) {
    margin-right: 0;
    margin-top: 3.5rem;
  }
  @media (max-width: 450px) {
    margin-top: 5rem;
  }
  @media (max-width: 350px) {
    margin-top: 10rem;
  }

  img {
    width: 650px;
    height: 500px;
    margin-top: 12px;
    @media (max-width: 1200px) {
      width: 550px;
      height: 420px;
    }
    @media (max-width: 1024px) {
      width: 450px;
      height: 340px;
    }
    @media (max-width: 800px) {
      width: 480px;
      height: 380px;
    }
    @media (max-width: 600px) {
      width: 400px;
      height: 300px;
    }
    @media (max-width: 400px) {
      width: 350px;
      height: 260px;
    }
  }
`;
IntroSection.RightWrapper = styled.div`
  flex: 1;
  display: flex;
  height: 90vh;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 800px) {
    align-items: center;
    text-align: center;
  }
  @media (max-width: 600px) {
    /* align-items: center; */
    /* text-align: center; */
    /* height: 50vh; */
  }
  h1 {
    /* width: 100%; */
    //font-weight: 800;
    @media (max-width: 1400px) {
      /* width: 100%; */
      font-size: 2.5rem;
    }
    @media (max-width: 1024px) {
      font-size: 2rem;
    }
    @media (max-width: 800px) {
      font-size: 1.7rem;
    }
    @media (max-width: 400px) {
      font-size: 1.5rem;
    }
  }
  p {
    width: 70%;
    color: grey;
    font-weight: 100;
    width: 90%;
    // margin-bottom: 1rem;
    @media (max-width: 1400px) {
      width: 90%;
    }
    @media (max-width: 1024px) {
      font-size: 1rem;
    }
    @media (max-width: 800px) {
      // font-size: 0.5rem;
    }
  }
  Button {
    width: 12rem;
    height: auto;
    font-weight: 600;
    // border-radius: 0 30px 0 30px;
    border-radius: 10px;
    background-color: #05f;
    color: #fff;
    a {
      color: white;
      text-decoration: none;
      letter-spacing: 1px;
    }
    :hover {
      animation: button 1s 2;
      animation-direction: alternate;

      @keyframes button {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(1.25);
        }
      }
    }
    @media (max-width: 1024px) {
      width: 10rem;
      font-size: 1rem;
    }
    @media (max-width: 800px) {
      // width: 7rem;
      // font-size: 0.7rem;
      // border-radius: 0 20px 0 20px;
    }
    @media (max-width: 600px) {
      width: 7rem;
      font-size: 0.7rem;
      // border-radius: 12px 0 12px 0;
      border-radius: 5px;
    }
    @media (max-width: 400px) {
      // width: 7rem;
      // font-size: 0.7rem;
      // border-radius: 8px 0 8px 0;
    }
  }
`;
