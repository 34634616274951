import React from 'react';

import { Wrapper, WrapperContainer, LeftContainer, RightContainer } from './style';
const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <Wrapper>
      <WrapperContainer>
        <RightContainer>
          <p>© {currentYear} Tata Communications | unlock.fit. All Rights Reserved.</p>
        </RightContainer>
        <LeftContainer>
          <a href="./privacy">
            <p style={{ border: 'none' }}>PRIVACY POLICY</p>
          </a>
          <p>|</p>
          <a href="./terms">
            <p>TERMS OF USE</p>
          </a>
        </LeftContainer>
      </WrapperContainer>
      {/*<TechPartner>*/}
      {/*  <p>*/}
      {/*    Technology Partner{' '}*/}
      {/*    <span style={{ color: 'red' }}>*/}
      {/*      {' '}*/}
      {/*      <a target="_blank" rel="noopener noreferrer" href="https://www.videtorrium.com/">*/}
      {/*        Videtorrium India*/}
      {/*      </a>*/}
      {/*    </span>*/}
      {/*  </p>*/}
      {/*</TechPartner>*/}
    </Wrapper>
  );
};

export default Footer;
