import styled from 'styled-components';

export const StyledButton = styled.button`
  padding: 0.9em 0.9em;
  margin-top :0.5em;
  font-size: 1.3em;
  font-weight: 700;
  display: inline-block;
  border-radius: 30px;
  outline: none;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  @media (max-width: 480px) {
    font-size: 12px;
    padding: 0.5em, 0.5em;
  }
  @media (max-width: 600px) {
    font-size: 12px;
  } ;
`;
