import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;

  Button {
    font-size: 24px;
    font-weight: 700;
    margin-top: 2rem;
    background: #d2a614;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    color: #fff;
    padding: 1rem 1rem;
    :hover {
      background-color: #e4be02;
      transform: scale(1.06);
    }
    @media (max-width: 600px) {
      font-size: 18px;
    }
  }
`;

export const AccordionWrapper = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;

  /*  {
    border-bottom: 0.3px solid #19191b;
  } */
`;

export const Headingwrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  h1 {
    color: #19191b;
  }
  h2 {
    font-size: 1.7rem;
    font-weight: 500;
    color: #19191b;
    /* margin-right: 5rem; */
    @media (max-width: 1440px) {
      font-size: 1.5rem;
    }
    @media (max-width: 1024px) {
      /* p {
        font-size: 3rem;
      } */
    }
    @media (max-width: 800px) {
      font-size: 1.2rem;
    }
  }

  p {
    font-size: 1rem;
    margin-top: 0.7rem;
    color: #19191b;

    @media (max-width: 800px) {
      font-size: 0.8rem;
    }

    @media (max-width: 600px) {
      font-size: 0.7rem;
    }
  }
`;
