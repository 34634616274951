import styled from 'styled-components';

export const Wrapper = styled.div`
  /* height: 80vh; */
  width: 100%;
  display: none;
  align-items: center;
  background-color: #fff;
  padding: 2rem 0 3rem 0;

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  } ;
`;

export const Content = styled.div`
  max-width: 73%;
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 16px;
  }
  h4 {
    font-weight: 100;
  }
  Button {
    color: white;
    background: #d2a614;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    :hover {
      background-color: #e4be02;
      transform: scale(1.06);
    }
  }
`;
