import styled from 'styled-components/macro';

export const IntroSection = {};
export const Model = styled.div`
  z-index: ${({ show }) => (show ? 100 : -1)};
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  top: 0rem;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* display: flex;
align-items: center;
justify-content: center; */
`;
export const ModelContainer = styled.div`
  /* background-color: red; */
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Content = styled.div`
  position: relative;
  min-width: 20rem;
  width: 600px;
  /* overflow: scroll; */
  z-index: 2;
  padding: 2rem;
  /* min-height: 12rem; */
  height: auto;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 60%;
  overflow-y: auto;
  Button {
    margin 0 0.5rem;
    background-color: #c59e2d;
    color: white;
    font-size: 20px;
    margin-top: 1rem;
    border-radius: 30px;
    background: #d2a614;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    /* padding: 12px; */
    padding: 0.5rem 2rem;
    border-radius: 50px;
    background: #d2a614;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    @media (max-width: 800px) {
      font-size: 15px;
    }
    :hover {
      background: #d2a614;
      scale: 1.1;
      cursor: pointer;
    }
  }

  h2 {
    font-size: 1.7rem;
    margin-top: 1rem;
    color: #c59e2d;
    border-bottom: 1px solid grey;
    margin-bottom: 1rem;
  }

  form {
    width: 80%;
    font-size: 1rem;
    input {
      width: 100%;
      padding: 5px 10px;
    }
  }

  @media (max-width: 600px) {
    /* min-width: 12rem; */
    /* width: 90%; */
    /* height: 40%; */
  }

  .custom-input {
    border-radius: 5px;
    outline: none;
    padding: 11px 10px;
    margin: 5px 0px;
    font-size: 15px;
    color: #808080;
    border: 1px solid;
    font-family: 'Poppins', 'DM Sans', sans-serif;
  }
`;

IntroSection.SupperWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 80%;
  background-color: red;
  background: url('/assets/intro.png') center no-repeat;
  /* background-size: auto 100% ; */
  background-position: 50% 0;

  @media (max-width: 800px) {
    /* background: url('/assets/intro2.png') center no-repeat;
    background-size: cover;
    min-height: 50vh; */
    background-position: 55%;
    margin-top: 0;
  }
  @media (max-width: 600px) {
    min-height: 50vh;
    margin-top: 4vh;
  }
`;
IntroSection.MainWrapper = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 16vh;
  padding: 2rem;
`;
IntroSection.Wrapper = styled.div`
  display: flex;
`;
IntroSection.LeftWrapper = styled.div`
  flex: 0.6;
  margin-top: -4rem;

  @media (max-width: 800px) {
    flex: 0.7;
    margin-top: 0;
  }
  @media (max-width: 600px) {
    flex: 0.6;
  }

  h1 {
    font-size: 3.2rem;
    color: #19191b;
    font-weight: 700;
    @media (max-width: 730px) {
      font-size: 2.8rem;
    }
    @media (max-width: 450px) {
      font-size: 2rem;
    }
  }
  h2 {
    margin-top: 1rem;
    margin-bottom: 0.4rem;
    @media (max-width: 450px) {
      font-size: 1.4rem;
    }
  }
  h4 {
    font-size: 20px;
    font-weight: 100;
    letter-spacing: 0.5px;
    color: #19191b;
    @media (max-width: 600px) {
      display: none;
    }
  }
  h3 {
    font-size: 30px;
    color: #19191b;
    font-weight: 700;
    @media (max-width: 730px) {
      font-size: 22px;
    }

    @media (max-width: 600px) {
      display: none;
    }
  }
  Button {
    font-size: 24px;
    font-weight: 700;
    /* background-color: #d2a614; */
    color: white;
    background: #d2a614;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    :hover {
      background-color: #e4be02;
      transform: scale(1.06);
    }
    @media (max-width: 730px) {
      font-size: 18px;
    }
    @media (max-width: 600px) {
      display: none;
    }
  }
`;
IntroSection.RightWrapper = styled.div`
  /* flex: 1; */
`;
