import styled from 'styled-components/macro';
import { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 100%;
  height: auto;
  padding: 8rem 0;
  align-items: center;
  justify-content: space-around;
  .main-ti {
    margin: 15px 0px;
    color: #d4a52f;
    font-weight: 500;
  }
  h1 {
    color: #19191b;
    font-size: 3.625rem;
    font-weight: 600;
    margin: 4rem 0;

    @media (max-width: 1200px) {
      font-size: 3rem;
    }
    @media (max-width: 800px) {
      margin-bottom: 1rem;
      font-size: 2.8rem;
    }
    @media (max-width: 600px) {
      font-size: 2.2rem;
    }
    @media (max-width: 400px) {
      font-size: 2rem;
    }
  }
  @media (max-width: 1200px) {
    padding: 4rem 0;
  }
  @media (min-width: 600px) {
    .main-ti {
      font-size: 23px;
      margin: 9px 0px;
    }
    .right {
      width: 20px;
    }
    .left {
      width: 20px;
    }
  }
  @media (min-width: 768px) {
    .main-ti {
      font-size: 23px;
      margin: 9px 0px;
    }
    .right {
      width: 20px;
    }
    .left {
      width: 20px;
    }
  }
  @media (min-width: 992px) {
    .main-ti {
      font-size: 25px;
      margin: 11px 0px;
    }
    .right {
      width: 20px;
    }
    .left {
      width: 20px;
    }
  }
  @media (min-width: 1200px) {
    .main-ti {
      font-size: 36px;
      margin: 15px 0px;
      color: #d4a52f;
      font-weight: 500;
    }
    .right {
      width: 33px;
    }
    .left {
      width: 33px;
    }
  }
`;

export const MainUnlockLife = styled.div`
  width: 72rem;
  border-radius: 18px;
  padding: 1rem;
  /* box-shadow: 0px 0px 25px -5px rgba(0, 0, 0, 0.1); */
  background: #ffffff;
  box-shadow: 4px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 25px;

  @media (max-width: 1200px) {
    width: 60rem;
  }
  @media (max-width: 1200px) {
    width: 50rem;
  }
  @media (max-width: 900px) {
    width: auto;
  }
  ${({ gif }) =>
    gif &&
    css`
      @media (max-width: 800px) {
        display: none;
      }
    `}
  ${({ gif1 }) =>
    gif1 &&
    css`
      display: none;
      @media (max-width: 800px) {
        display: flex;
        h1 {
          font-weight: 700;
        }
      }
    `}
`;

export const MainContentWrapper = styled.div`
  display: flex;
  margin: auto;
  border: 2px solid #d2a614;
  min-width: 65%;
  align-items: center;
  border: 1px solid goldenrod;
  justify-content: center;
  height: auto;
  padding: 1rem 0.7rem;
  border-radius: 18px;
  overflow: hidden;
  /* // border: 2px solid red; */
  @media (max-width: 800px) {
    padding: 1rem 1rem;
    flex-direction: column;
  }

  @media (max-width: 450px) {
    min-width: 55%;
  }
  @media (max-width: 400px) {
    min-width: 65%;
  }
  .class1 {
    /* width: rem; */
    @media (max-width: 450px) {
      // border-top: 1.5px solid #d2a614;
      /* border-top: 3px double #d2a614; */
      /* border-bottom: 3px double #d2a614; */
      // border-bottom: 1.5px solid #d2a614;
      width: 60%;
    }
  }
  // .main-wrapper-button {
  //   display: none;
  //   @media (max-width: 1200px) {
  //   }
  //}
`;

export const UnlockLife = styled.div`
  display: flex;

  flex: 1;
  width: 50%;
  height: 100%;
  margin: 0.4rem;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.align ? props.align : 'flex-start')};
  @media (max-width: 450px) {
    align-items: center;
    width: 25vw;
  }
  span {
    img {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.3rem;
    }
  }
  h1 {
    font-size: 6rem;
    border-bottom: 1px solid black;
    width: 80%;
    color: #d2a614;
    margin: 0;
    height: 44px;
    font-weight: 600;

    @media (max-width: 1200px) {
      font-size: 5rem;
    }
    @media (max-width: 800px) {
      font-size: 2.5rem;
    }
    @media (max-width: 450px) {
      font-size: 2.2rem;
    }
  }
  h2 {
    color: #19191b;
    font-size: 2.6rem;
    font-weight: 400;
    line-height: 1;
    @media (max-width: 1200px) {
      font-size: 1.3rem;
    }
    @media (max-width: 800px) {
      font-size: 1rem;
    }
    @media (max-width: 450px) {
      // font-size: 0.8rem;
    }
  }
  h3 {
    color: #2a2a2a;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 3;
    margin-bottom: 1.25rem;
    letter-spacing: 0.1px;
    @media (max-width: 1440px) {
      font-size: 1.2rem;
    }
    @media (max-width: 1200px) {
      font-size: 1rem;
    }
    @media (max-width: 600px) {
      font-size: 0.65rem;
      margin: 0.4rem 0;
    }
    @media (max-width: 600px) {
      font-size: 0.8rem;
    }
    @media (max-width: 450px) {
      font-size: 0.7rem;
      text-align: center;
    }
  }
  h7 {
    font-size: 2rem;
    text-align: left;
  }
  h4 {
    margin: 0 1rem 2rem 0;
    font-size: 1.3rem;
    font-weight: 100;
    @media (max-width: 1200px) {
      font-size: 1rem;
    }
    @media (max-width: 900px) {
      text-align: center;
      margin: 0;
      width: 90%;
    }
    @media (max-width: 800px) {
      font-size: 0.8rem;
    }
    @media (max-width: 600px) {
      font-size: 0.7rem;
    }
    @media (max-width: 450px) {
      font-size: 0.8rem;
      border: none;
      margin: 1rem 0 0 0;
    }

    span {
      ${
        '' /* text-decoration: line-through;
      text-decoration-thickness: 1.7px;
      text-decoration-color: #c08719; */
      }
    }
  }
  h5 {
    /* margin: 0 1.5rem;
    margin-top: 1rem; */
    color: #19191b;

    font-size: 1.3rem;
    @media (max-width: 1024px) {
      margin: 0 1rem;
    }
    @media (max-width: 800px) {
      font-size: 1rem;
    }
    @media (max-width: 600px) {
      margin: 0;
      font-size: 0.8rem;
    }
  }
  Button {
    width: auto;
    height: auto;
    font-size: 1.5rem;
    font-weight: 600;
    background-color: #d2a614;
    color: #fff;
    padding: 0.8rem 2.5rem;
    background: #d2a614;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;

    :hover {
      background-color: #e4be02;
      transform: scale(1.06);
    }
    @media (max-width: 1200px) {
      border-radius: 0 20px 0 20px;
      width: auto;
      font-size: 0.8rem;
      padding: 0.5rem 1.5rem;
    }
    @media (max-width: 600px) {
      font-size: 0.4rem;
      border-radius: 10px 0 10px 0;
      padding: 0.3rem 1rem;
    }
    // @media (max-width: 450px) {
    //   font-size: 0.7rem;
    //   margin-bottom: 0.3rem;
    // }
  }
  img {
    border-radius: 50%;
    width: 5rem;
    @media (max-width: 1200px) {
      width: 5rem;
    }
    @media (max-width: 800px) {
      width: 3rem;
    }
    @media (max-width: 450px) {
      /* width: 7rem; */
      /* display: none; */
    }
  }

  // code for buy now button in Main Wrapper (top)
  ${({ buyNowBotton }) =>
    buyNowBotton &&
    css`
      display: flex;
      @media (max-width: 450px) {
        Button {
          display: none;
        }
      }
    `}

  // code for buy now button in Main Wrapper (bottom)
  ${({ itemBotton }) =>
    itemBotton &&
    css`
      display: none;
      @media (max-width: 800px) {
        display: flex;
        min-width: 100%;
        Button {
          height: 4rem;
          // min-width: 21vw;
          border-radius: 0;
          font-weight: 600;
          background-color: rgb(210, 166, 20, 0.15);
          color: #d2a614;
          box-shadow: 8px -8px 16px transparent, -8px 8px 16px #ffffff;
          @media (max-width: 800px) {
            min-width: 80vw;
            font-size: 1rem;
            margin-bottom: -1.4rem;
          }
      }
    
    `}
`;

export const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-width: 55vw;
  min-height: auto;
  align-items: center;
  margin-top: 2rem;
  flex-wrap: wrap;
  flex-wrap: wrap;
  @media (max-width: 800px) {
    flex-direction: column;
    height: auto;
  }
`;

//Card
export const Card = styled.div`
  /* ${({ gif1 }) =>
    gif1 &&
    css`
      display: none;
      @media (max-width: 800px) {
        display: flex;
        h1 {
          font-weight: 700;
        }
      }
    `} */
  margin: 26.5px;

  background: #ffffff;
  box-shadow: 0px 0px 34px 0px rgba(0, 0, 0, 0.09);
  border-radius: 18px;
`;

export const InnerCard = styled.div`
  position: relative;
  height: 30rem;
  min-width: 320px;
  width: auto;
  border: 1px solid #d2a614;
  border-radius: 18px;

  Button {
    position: absolute;
    bottom: 20px;
    width: -webkit-fill-available;
    border-radius: 5px;
    padding: 10px;
    background-color: #0d85c3;
    color: whitesmoke;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.08);
    font-weight: 600;
    font-size: 20px;
    margin: 0px 30px;

    :hover {
      background-color: #0d85c3c7;
      /* color: black; */
    }
  }

  @media (min-width: 600px) {
    height: 22rem;
    width: 15rem;
    min-width: 279px;
    Button {
      font-size: 13px;
      padding: 7px;
    }
  }
  @media (min-width: 768px) {
    height: 23rem;
    width: 15rem;
    min-width: 279px;
    Button {
      font-size: 13px;
      padding: 8px;
    }
  }
  @media (min-width: 992px) {
    height: 26rem;
    width: 15rem;
    min-width: 305px;
    Button {
      font-size: 15px;
      padding: 8px;
    }
  }
  @media (min-width: 1200px) {
    width: 17rem;
    height: 33rem;
    min-width: 404px;
  }
  @media (min-width: 1600px) {
    width: 17rem;
    height: 39rem;
    min-width: 404px;
  }
  // @media (max-width: 950px) {
  //   width: 15rem;
  //   height: 38rem;
  // }
  // @media (max-width: 800px) {
  //   width: 20rem;
  //   height: 38rem;
  // }
  // @media (max-width: 400px) {
  //   width: 20rem;
  //   height: 33rem;
  // }

  h2 {
    font-size: 2.6rem;
    font-weight: 400;
    line-height: 1;
    padding: 0.5rem;
    color: #19191b;
    margin: 0 0 0 01rem;
  }

  h1 {
    padding: 0.5rem;
    color: #d2a614;
    margin: 0 0 0 1rem;
  }
  h5 {
    color: #19191b;
    margin: 0 0 0 01rem;
    padding: 0.4rem;
  }

  // p {
  //   color: #19191b;
  //   font-weight: 600;
  //   padding: 0rem;
  //   margin: 0 0 0 0;
  // }

  margin: auto;
`;

export const Upper = styled.div`
  h1 {
    line-height: 1;
    font-size: 50px;
  }
  h2 {
    font-size: 36px;
  }
  li {
    font-size: 18px;
    line-height: 2;
    font-weight: 400;
  }

  ul {
    list-style: none;
  }

  ul li:before {
    content: '✓';
    color: #d2a614;
  }

  .tags {
    color: #393838;
  }

  .svgs {
    width: 10px;
  }
  .margins {
    display: flex;
    margin-top: 0px;
  }

  .main-cards {
    font-size: 20px;
    padding: 7px 0px 5px 0px;
    color: #d4a52f;
    margin: 0;
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: bold;
  }

  .des {
    margin: 4px 0px;
    font-size: 15px;
    font-weight: 100;
    text-align: left;
    padding: 0px 20px;
    color: #393838;
  }

  .inner {
    margin-top: 7px;
    padding: 0 11px;
    color: #393838;
  }

  .tags {
    margin-bottom: 0px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
  }

  .dur {
    font-size: 16px;
    color: #4d4d4d;
    display: flex;
    margin-top: 8px;
    justify-content: center;
  }

  .prod-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    cursor: pointer;
  }

  .special-life {
    font-size: 20px;
    font-weight: 700;
  }

  .special-type {
    font-size: 12px;
    font-weight: 400;
  }

  .price {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: -webkit-fill-available;
    bottom: 65px;
  }

  .special {
    color: #a7a7a7;
    font-size: 11px;
    font-weight: 400;
    text-decoration: line-through;
  }

  .actual {
    color: #4d4d4d;
    font-size: 14px;
    font-weight: 600;
  }

  @media only screen and (min-width: 600px) {
    .svgs {
      width: 10px;
    }
    .margins {
      display: flex;
      margin-top: 0px;
    }

    .main-cards {
      font-size: 20px;
      padding: 7px 0px 5px 0px;
      color: #d4a52f;
      margin: 0;
      display: flex;
      justify-content: center;
      text-align: center;
      font-weight: bold;
    }

    .des {
      margin: 4px 0px;
      font-size: 8px;
      font-weight: 100;
      text-align: left;
      padding: 0px 20px;
      color: #393838;
    }

    .inner {
      margin-top: 7px;
      padding: 0 11px;
      color: #393838;
    }

    .tags {
      margin-bottom: 0px;
      line-height: 16px;
      font-size: 7px;
      font-weight: 500;
    }

    .dur {
      font-size: 11px;
      color: #4d4d4d;
      display: flex;
      margin-top: 8px;
      justify-content: center;
    }

    .prod-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 4px 10px;
      cursor: pointer;
    }

    .special-life {
      font-size: 14px;
      font-weight: 700;
    }

    .special-type {
      font-size: 6px;
      font-weight: 400;
    }

    .price {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      width: -webkit-fill-available;
      bottom: 65px;
    }

    .special {
      color: #a7a7a7;
      font-size: 8px;
      font-weight: 400;
      text-decoration: line-through;
    }

    .actual {
      color: #4d4d4d;
      font-size: 11px;
      font-weight: 600;
    }
  }

  @media only screen and (min-width: 768px) {
    .svgs {
      width: 10px;
    }
    .margins {
      display: flex;
      margin-top: 0px;
    }

    .main-cards {
      font-size: 20px;
      padding: 7px 0px 5px 0px;
      color: #d4a52f;
      margin: 0;
      display: flex;
      justify-content: center;
      text-align: center;
      font-weight: bold;
    }

    .des {
      margin: 4px 0px;
      font-size: 8px;
      font-weight: 100;
      text-align: left;
      padding: 0px 20px;
      color: #393838;
    }

    .inner {
      margin-top: 7px;
      padding: 0 11px;
      color: #393838;
    }

    .tags {
      margin-bottom: 0px;
      line-height: 16px;
      font-size: 7px;
      font-weight: 500;
    }

    .dur {
      font-size: 11px;
      color: #4d4d4d;
      display: flex;
      margin-top: 8px;
      justify-content: center;
    }

    .prod-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 4px 10px;
      cursor: pointer;
    }

    .special-life {
      font-size: 14px;
      font-weight: 700;
    }

    .special-type {
      font-size: 6px;
      font-weight: 400;
    }

    .price {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      width: -webkit-fill-available;
      bottom: 65px;
    }

    .special {
      color: #a7a7a7;
      font-size: 8px;
      font-weight: 400;
      text-decoration: line-through;
    }

    .actual {
      color: #4d4d4d;
      font-size: 11px;
      font-weight: 600;
    }
  }

  @media only screen and (min-width: 992px) {
    .svgs {
      width: 13px;
    }
    .margins {
      display: flex;
      margin-top: 0px;
    }

    .main-cards {
      font-size: 23px;
      padding: 7px 0px;
      color: #d4a52f;
      margin: 0;
      display: flex;
      justify-content: center;
      text-align: center;
      font-weight: bold;
    }

    .des {
      margin: 6px 0px;
      font-size: 11px;
      font-weight: 100;
      text-align: left;
      padding: 0px 20px;
      color: #393838;
    }

    .inner {
      margin-top: 10px;
      padding: 0 18px;
      color: #393838;
    }

    .tags {
      margin-bottom: 0px;
      line-height: 14px;
      font-size: 10px;
      font-weight: 500;
    }

    .dur {
      font-size: 14px;
      color: #4d4d4d;
      display: flex;
      margin-top: 8px;
      justify-content: center;
    }

    .prod-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 4px 10px;
      cursor: pointer;
    }

    .special-life {
      font-size: 18px;
      font-weight: 700;
    }

    .special-type {
      font-size: 8px;
      font-weight: 400;
    }

    .price {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      width: -webkit-fill-available;
      bottom: 70px;
    }

    .special {
      color: #a7a7a7;
      font-size: 10px;
      font-weight: 400;
      text-decoration: line-through;
    }

    .actual {
      color: #4d4d4d;
      font-size: 13px;
      font-weight: 600;
    }
  }

  @media only screen and (min-width: 1200px) {
    .svgs {
      width: 15px;
    }

    .margins {
      display: flex;
      margin-top: 12px;
    }

    .main-cards {
      font-size: 33px;
      padding: 12px 0px;
      color: #d4a52f;
      margin: 0;
      display: flex;
      justify-content: center;
      text-align: center;
      font-weight: bold;
    }

    .des {
      font-size: 15px;
      font-weight: 100;
      text-align: left;
      padding: 0px 20px;
      color: #393838;
    }

    .inner {
      margin-top: 22px;
      padding: 0 18px;
      color: #393838;
    }

    .tags {
      margin-bottom: 0px;
      line-height: 18px;
      font-size: 14px;
      font-weight: 500;
    }

    .dur {
      font-size: 17px;
      color: #4d4d4d;
      display: flex;
      margin-top: 10px;
      justify-content: center;
    }

    .prod-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 4px 10px;
      cursor: pointer;
    }

    .special-life {
      font-size: 27px;
      font-weight: 700;
    }

    .special-type {
      font-size: 13px;
      font-weight: 400;
    }

    .price {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      width: -webkit-fill-available;
      bottom: 70px;
    }

    .special {
      color: #a7a7a7;
      font-size: 12px;
      font-weight: 400;
      text-decoration: line-through;
    }

    .actual {
      color: #4d4d4d;
      font-size: 17px;
      font-weight: 600;
    }
  }

  @media only screen and (min-width: 1600px) {
    .svgs {
      width: 20px;
    }

    .margins {
      display: flex;
      margin-top: 12px;
    }

    .main-cards {
      font-size: 36px;
      padding: 12px 0px;
      color: #d4a52f;
      margin: 0;
      display: flex;
      justify-content: center;
      text-align: center;
      font-weight: bold;
    }

    .des {
      font-size: 18px;
      font-weight: 100;
      text-align: left;
      padding: 0px 20px;
      color: #393838;
    }

    .inner {
      margin-top: 25px;
      padding: 0 18px;
      color: #393838;
    }

    .tags {
      margin-bottom: 0px;
      line-height: 20px;
      font-size: 17px;
      font-weight: 500;
    }

    .dur {
      font-size: 20px;
      color: #4d4d4d;
      display: flex;
      margin-top: 10px;
      justify-content: center;
    }

    .prod-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 4px 10px;
      cursor: pointer;
    }

    .special-life {
      font-size: 30px;
      font-weight: 700;
    }

    .special-type {
      font-size: 16px;
      font-weight: 400;
    }

    .price {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      width: -webkit-fill-available;
      bottom: 85px;
    }

    .special {
      color: #a7a7a7;
      font-size: 15px;
      font-weight: 400;
      text-decoration: line-through;
    }

    .actual {
      color: #4d4d4d;
      font-size: 20px;
      font-weight: 600;
    }
  }
`;

// export const Lower = styled.div`
//   height: 10%;
//   margin-bottom: 1rem;

//   p {
//     font-size: 16px;
//     span {
//       color: #19191b;
//       font-weight: 100;
//     }
//   }
//   `;

/*  ${
    ''
     text-decoration: line-through;
  text-decoration-thickness: 1.7px;
  text-decoration-color: #c08719; 
  }*/
// Card at mobile
export const Card1 = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: flex;
    /* background-color: red; */
    h1 {
      font-weight: 700;
    }
  }
  padding: 0.5rem;
  margin: auto 1rem;
  margin-bottom: 1rem;

  background: #ffffff;
  box-shadow: 4px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
`;
export const InnerCard1 = styled.div`
  position: relative;
  height: 40rem;
  width: 20rem;
  border: 1px solid #d2a614;
  border-radius: 18px;

  @media (max-width: 800px) {
    width: 25rem;
    height: 40rem;
  }
  @media (max-width: 450px) {
    width: 20rem;
    height: 100%;
  }

  h2 {
    font-size: 2.6rem;
    font-weight: 400;
    line-height: 1;
    padding: 0.5rem;
    color: #19191b;
    margin: 0 0 0 1rem;
  }

  h1 {
    padding: 0.5rem;
    color: #d2a614;
    margin: 0 0 0 1rem;
  }
  h5 {
    color: #19191b;
    margin: 0 0 0 1rem;
    padding: 0.4rem;
  }

  // p {
  //   color: #19191b;
  //   font-weight: 600;
  //   padding: 0;
  //   margin: 0;
  // }

  margin: auto;

  Button {
    position: relative;
    bottom: 0;
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px;
    background-color: #d2a614;
    color: whitesmoke;
    font-weight: 700;
    font-size: 20px;

    :hover {
      background-color: #e4be02;
      /* color: black; */
    }
  }
`;
export const Upper1 = styled.div`
  height: 53%;
  h1 {
    line-height: 1;
    font-size: 50px;
  }
  h2 {
    font-size: 36px;
  }
  li {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 400;
  }

  ul {
    list-style: none;
  }
  ul li:before {
    content: '✓';
    color: #d2a614;
  }
`;

// export const Lower1 = styled.div`
//   margin-bottom: 2rem;
//   height: 18%;
//   p {
//     font-size: 16px;
//     span {
//       color: #19191b;
//       font-weight: 100;
//       ${
//         '' /* text-decoration: line-through;
//       text-decoration-thickness: 1.7px;
//       text-decoration-color: #c08719; */
//       }
//     }
//   }
//   h4 {
//     font-weight: 100;
//     text-align: center;
//     margin: 0.5rem auto;
//     font-size: 12px;

//     @media (max-width: 450px) {
//       font-size: 12px;
//     }
//   }
// `;

// export const dFlex = styled.div`
// display: flex;

//   @media (max-width: 768px){
//     display : block;
//   }
// `

//! CardsEnd
export const PriceButton = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 1px;
  margin-top: 1rem;
  align-items: center;

  @media (max-width: 1100px) {
    width: 20vw;
  }
  @media (max-width: 600px) {
    width: 27vw;
  }

  Button {
    :hover {
      background-color: #d2a614;
      color: white;
    }
  }
`;

export const Model = styled.div`
  z-index: ${({ show }) => (show ? 100 : -1)};
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  top: 0rem;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* display: flex;
align-items: center;
justify-content: center; */
`;

export const ModelContainer = styled.div`
  /* background-color: red; */
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 1.5rem;
  margin-top: 1rem;
`;

export const Content = styled.div`
  position: relative;
  min-width: 20rem;
  width: auto;
  /* overflow: scroll; */
  z-index: 2;
  padding: 2rem;
  /* min-height: 12rem; */
  height: auto;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 60%;
  overflow-y: auto;
  Button {
    margin 0 0.5rem;
    background-color: #c59e2d;
    color: white;
    font-size: 20px;
    margin-top: 1rem;
    border-radius: 30px;
    background: #d2a614;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    /* padding: 12px; */
    padding: 0.5rem 2rem;
    border-radius: 50px;
    background: #d2a614;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    @media (max-width: 800px) {
      font-size: 15px;
    }
    :hover {
      background: #d2a614;
      scale: 1.1;
      cursor: pointer;
    }
  }

  h2 {
    font-size: 1.7rem;
    margin-top: 1rem;
    color: #c59e2d;
    border-bottom: 1px solid grey;
    margin-bottom: 1rem;
  }

  form {
    width: 80%;
    font-size: 1rem;
    input {
      width: 100%;
      padding: 5px 10px;
    }
  }

  @media (max-width: 600px) {
    /* min-width: 12rem; */
    /* width: 90%; */
    /* height: 40%; */
  }

  .custom-input {
    border-radius: 5px;
    outline: none;
    padding: 11px 10px;
    margin: 5px 0px;
    font-size: 15px;
    color: #808080;
    border: 1px solid;
    font-family: 'Poppins', 'DM Sans', sans-serif;
  }
`;
