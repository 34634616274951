import styled from 'styled-components/macro';
import { FiPlus, FiMinus } from 'react-icons/fi';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 95%;
`;
export const AccordionHeading = styled.div`
  padding: 0 1rem;
  cursor: pointer;
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: center;
  > a {
    text-decoration: none;
  }
  p {
    font-family: 'Roboto', sans-serif;
    font-weight: 350;
    font-size: 1rem !important;
    width: 100%;
  }
  @media (max-width: 1440px) {
    /* width:30%; */
    p {
      font-size: 0.8rem;
    }
  }
  @media (max-width: 1024px) {
    p {
      /* font-size: 1rem;
      padding-right: 1rem; */
    }
  }
  @media (max-width: 800px) {
    p {
      font-size: 1rem;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
    p {
      font-size: 0.7rem;
    }
  }
`;
export const ContentContainer = styled.div`
  /* background-color: #eee; */
  overflow: hidden;
  transition: max-height 0.6s ease;
  p {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    /* margin-left: 2rem;
    margin-right: 2rem; */
  }
  @media (max-width: 1440px) {
    p {
      font-size: 0.8rem;
    }
  }
  @media (max-width: 1024px) {
    p {
      /* font-size: 2rem; */
    }
  }

  @media (max-width: 600px) {
    p {
      font-size: 0.7rem;
    }
  }
`;
export const Plus = styled(FiPlus)`
  color: #004495;
  font-size: 1.5rem;
  margin-left: 2rem;
`;
export const Minus = styled(FiMinus)`
  color: #004495;
  font-size: 1.5rem;
  margin-left: 2rem;
`;
