import { React, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { MainWrapper, Wrapper, CardContainer, Card } from './style';
const Benefit = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <MainWrapper>
      <Wrapper>
        <h1 data-aos="fade-down">What makes us stand out?</h1>
        <CardContainer>
          <Card>
            <img data-aos="zoom-in" src="assets/b1.png" alt="png" />
            <p>
              Trusted by <br />
              International Athletes
            </p>
          </Card>
          <Card>
            <img data-aos="zoom-in" src="assets/b4.png" alt="png" />
            <p>
              World&#39;s Most Personalized <br /> Wellness Program
            </p>
          </Card>
          <Card>
            <img data-aos="zoom-in" src="assets/b3.png" alt="png" />
            <p>
              Same Exclusive Price <br /> for your Family
            </p>
          </Card>
          <Card>
            <img data-aos="zoom-in" src="assets/b2.png" alt="png" />
            <p id="download">
              Genomics <br />
              Based
            </p>
          </Card>
        </CardContainer>
      </Wrapper>
    </MainWrapper>
  );
};

export default Benefit;
