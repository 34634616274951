import { React, useEffect, useRef, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
/* eslint-disable */
import styled from 'styled-components/macro';
import {
  Wrapper,
  MainContentWrapper,
  UnlockLife,
  CardWrapper,
  Card,
  PriceButton,
  InnerCard,
  Upper,
  Lower,
  Card1,
  InnerCard1,
  Upper1,
  Lower1,
  // dFlex,
  MainUnlockLife,
  Model,
  ModelContainer,
  Content
} from './styles';
// import { productsForNew } from './tc_product';
import './styles/style.css';
import { Slider, withStyles, Box, Grid, ListItem } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Accordion2, Button, Modal } from '../../components';
import { TiTick } from 'react-icons/ti';
import { ReactComponent as Tick } from './styles/tick.svg';
import { makeStyles } from '@material-ui/core/styles';
import { BsDot } from 'react-icons/bs';
import axios from 'axios';

const useStyles = makeStyles({
  root: {
    color: '#d2a614'
  },
  thumb: {
    width: 16, // set the width of the thumb
    height: 16, // set the height of the thumb
    marginTop: -6, // adjust the position of the thumb
    marginLeft: -8 // adjust the position of the thumb
  },
  track: {
    height: 5, // set the height of the track
    borderRadius: 8 // set the border-radius of the track
  },
  rail: {
    height: 5, // set the height of the rail
    borderRadius: 8 // set the border-radius of the rail
  },
  mark: {
    backgroundColor: '#d2a614', // set the background color of the marks
    height: 5, // set the height of the marks
    width: 5, // set the width of the marks
    borderRadius: '50%' // make the marks round
  }
});

const AccordionWrapper = styled.div`
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    max-height: auto;
  }
`;

const Section6 = ({
  setLoading,
  Loading,
  loading,
  IsValid,
  show,
  setShow,
  durationId,
  setDurationId,
  planName,
  setPlanName,
  prodId,
  setProdId,
  price,
  setPrice,
  setPlanForUnlockMe,
  productsForNew,
  setproductsForNew,
  SelectedCategory,
  setSelectedCategory,
  SelectedNAme,
  setSelectedNAme,
  HasSubCategory,
  setHasSubCategory,
  SubCat,
  setSubCat
}) => {
  const [OpenMessage, setOpenMessage] = useState(false);
  const [Success, setSuccess] = useState(false);
  const [emailCheck] = useState(true);
  const history = useHistory();
  const [product, setProduct] = useState([]);
  const [token, setToken] = useState('');
  const [primaryPackage, setPrimaryPackage] = useState();
  const [productList, setProductList] = useState([]);
  const [trigger, setTrigger] = useState(1);
  const [productList1, setProductList1] = useState([]);

  const subCategoryLength = productsForNew
    .filter((product) => product.category === 'DNA Test')
    .map((product) => product.sub_categories.length)[0];

  const subCategoryLengthForBlood = productsForNew
    .flatMap((product) => product.sub_categories)
    .find((subCategory) => subCategory.sub_category_id === SubCat)?.products.length;

  const subCategoryLengthFornut = productsForNew
    .filter((product) => product.category === 'Nutrition Plan')
    .map((product) => product.sub_categories.length)[0];

  const classes = useStyles();

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_Dependents}employee-token`, {
        email_id: localStorage.getItem('emailId')
      })
      .then((res) => {
        setToken(res.data.api_token);
      })
      .catch((err) => {
        console.error(err);
      });
    console.log('token', token);
  }, []);

  const queryParams = new URLSearchParams(window.location.search);
  // let email = queryParams.get('email');

  const Blood = productsForNew.find((item) => item.category.toLowerCase() === 'blood test');
  const findThyroid = Blood?.sub_categories.find(
    (item) => item.sub_category.toLowerCase() === 'thyroid'
  );

  useEffect(() => {
    const queryString = window.location.search;
    const value = queryString.substring(1);
    if (value === 'product/blood' && productsForNew.length !== 0) {
      const targetCriteria = 'product';
      const targetElements = document.querySelectorAll(`[id*="${targetCriteria}"]`);

      const prod = productsForNew;
      const Blood = prod.find((item) => item.category.toLowerCase() === 'blood test');
      console.log('first', findThyroid, Blood);
      setSelectedCategory(Blood?.category_id);
      setSelectedNAme(Blood?.category);
      setHasSubCategory(Blood?.has_sub_category);
      setSubCat(Blood?.sub_categories[0].sub_category_id);

      if (targetElements.length > 0) {
        const firstTargetElement = targetElements[0];
        firstTargetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [Blood, productsForNew, findThyroid]);

  useEffect(() => {
    const email = localStorage.getItem('emailId');
    axios
      .get(`${process.env.REACT_APP_BASEURL}tata/website/user/getPlanListV3?email=${email}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((res) => {
        const products = res.data.data.map((product) => {
          if (product.sub_categories) {
            const updatedSubCategories = product.sub_categories.map((subCategory) => {
              if (subCategory.has_durations && subCategory.durations.length > 0) {
                return {
                  ...subCategory,
                  duration_id: subCategory.durations[0].id,
                  standard_price: subCategory.durations[0].actual_price,
                  special_price: subCategory.durations[0].special_price
                };
              }
              return subCategory;
            });
            return { ...product, sub_categories: updatedSubCategories };
          }
          return product;
        });
        setproductsForNew(products);
        const queryString = window.location.search;
        const value = queryString.substring(1);
        if (value === 'product/blood' && products.length !== 0) {
          const targetCriteria = 'product';
          const targetElements = document.querySelectorAll(`[id*="${targetCriteria}"]`);

          const prod = products;
          const Blood = prod.find((item) => item.category.toLowerCase() === 'blood test');
          setSelectedCategory(Blood?.category_id);
          setSelectedNAme(Blood?.category);
          setHasSubCategory(Blood?.has_sub_category);
          setSubCat(Blood?.sub_categories[0].sub_category_id);

          if (targetElements.length > 0) {
            const firstTargetElement = targetElements[0];
            firstTargetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        } else {
          setSelectedCategory(products[0]?.category_id);
          setSelectedNAme(products[0]?.category);
          setHasSubCategory(products[0]?.has_sub_category);
        }
        const filteredObjects = products.flatMap((obj) => {
          const filteredSubCategories = obj.sub_categories.filter((subCat) => {
            return subCat.package_id || (subCat.products && subCat.products.length > 0);
          });

          return filteredSubCategories
            .map((subCat) => {
              if (subCat.has_durations && subCat.durations.length > 0) {
                return {
                  ...subCat,
                  duration_id: subCat.durations[0].id,
                  standard_price: subCat.durations[0].standard_price,
                  special_price: subCat.durations[0].special_price
                };
              } else {
                return subCat.products ? subCat.products : subCat;
              }
            })
            .flat();
        });
        // setPrimaryPackage(getPrimaryPackage(products));
        setProductList(filteredObjects);
        // setProductList1(getProducts(products));
        // setProduct(res.data.result);
      });
  }, [localStorage.getItem('emailId')]);

  const navigate = () => {
    history.push('#working');
  };

  const getDurationLife = (marks, value) => {
    let life = 0;
    marks.forEach((mark) => {
      if (mark.value === value) {
        life = mark.life;
      }
    });
    return life;
  };

  const getCustomMark = (life, durations) => {
    let marksList1 = [];
    let val = getMinForSlider(durations);
    const interval =
      (getMaxForSlider(durations) - getMinForSlider(durations)) / (durations.length - 1);
    durations.forEach((duration) => {
      marksList1.push({
        value: val,
        life: duration.life
      });
      val = val + interval;
    });
    let mark = getMinForSlider(durations);
    marksList1.forEach((mark1) => {
      if (mark1.life === life) {
        mark = mark1.value;
      }
    });
    return mark;
  };

  const handleDurationChange = (newValue, durations, packageId) => {
    console.log(newValue, durations, packageId, 'duration change');
    const _products = productList;
    let marksList = [];
    let val = getMinForSlider(durations);
    const interval =
      (getMaxForSlider(durations) - getMinForSlider(durations)) / (durations.length - 1);
    durations.forEach((duration) => {
      marksList.push({
        value: val,
        life: duration.life
      });
      val = val + interval;
    });
    const newDurationLife = newValue;
    const standardPrice = getStandardPrice(durations, newDurationLife);
    const specialPrice = getSpecialPrice(durations, newDurationLife);
    const durationId = getDurationId(durations, newDurationLife);
    _products.forEach((product) => {
      if (product.package_id === packageId) {
        product.standard_price = standardPrice;
        product.special_price = specialPrice;
        product.duration = newDurationLife;
        product.duration_id = durationId;
      }
    });
    setProductList(_products);
    setTrigger(trigger + 1);
  };

  const getPrimaryPackage = (products) => {
    let primaryPackage = products.find((product) => product.is_primary_package);
    return primaryPackage;
  };

  const getFirstName = (name) => {
    let firstName = name.split(' ')[0];
    return firstName;
  };

  const getRestName = (name) => {
    let restName = name.split(' ').slice(1).join(' ');
    return restName;
  };

  const getRestNameWithoutBrackets = (name) => {
    let firstName = name.split(' ')[1];
    return firstName;
  };

  const getRestNameWithBrackets = (name) => {
    let restName = name.split(' ').slice(2).join(' ');
    return restName;
  };

  //get products excpet primary package
  const getProducts = (products) => {
    let productsList = products.filter((product) => !product.is_primary_package);
    return productsList;
  };

  const getMarks = (durations) => {
    let marks = [];
    let val = getMinForSlider(durations);
    const interval =
      (getMaxForSlider(durations) - getMinForSlider(durations)) / (durations.length - 1);
    durations.forEach((duration) => {
      marks.push({
        value: val,
        label: duration.life + ' ' + duration.duration_type
      });
      val = val + interval;
    });
    console.log('marks', marks);
    return marks;
  };

  // const getMarks = (durations) => {
  //   let marks = [];
  //   durations.forEach((duration) => {
  //     marks.push({
  //       value: duration.life,
  //       label: duration.life + " " + duration.duration_type
  //     });
  //   });
  //   return marks;
  // }

  const getMaxForSlider = (durations) => {
    let max = durations[0].life;
    durations.forEach((duration) => {
      if (duration.life > max) {
        max = duration.life;
      }
    });
    return max;
  };

  const getMinForSlider = (durations) => {
    let min = durations[0].life;
    durations.forEach((duration) => {
      if (duration.life < min) {
        min = duration.life;
      }
    });
    return min;
  };

  const getDurationId = (durations, value) => {
    let durationId = durations[0].id;
    durations.forEach((duration) => {
      if (duration.life === value) {
        durationId = duration.id;
      }
    });
    return durationId;
  };

  const getStandardPrice = (durations, value) => {
    let standardPrice = durations[0].actual_price;
    durations.forEach((duration) => {
      if (duration.life === value) {
        standardPrice = duration.actual_price;
      }
    });
    return standardPrice;
  };

  const getSpecialPrice = (durations, value) => {
    let specialPrice = durations[0].special_price;
    durations.forEach((duration) => {
      if (duration.life === value) {
        specialPrice = duration.special_price;
      }
    });
    return specialPrice;
  };

  const minDurationIndex = (durations) => {
    let min = 0;
    durations.forEach((duration, index) => {
      if (duration.life < durations[min].life) {
        min = index;
      }
    });
    return min;
  };

  const maxDurationIndex = (durations) => {
    let max = 0;
    durations.forEach((duration, index) => {
      if (duration.life > durations[max].life) {
        max = index;
      }
    });
    return max;
  };

  const getDurationFromId = (id) => {
    let result = '';
    productList.forEach((product) => {
      if (product.has_durations) {
        product.durations.forEach((duration) => {
          if (duration.id === id) {
            result = duration.life + ' ' + duration.duration_type;
          }
        });
      }
    });
    return result;
  };

  const contentRef = useRef(null);

  const scrollNext = () => {
    const content = contentRef.current;
    if (content) {
      const scrollAmount = content.scrollWidth * 0.1;
      const currentScrollLeft = content.scrollLeft;
      const targetScrollLeft = currentScrollLeft + scrollAmount;

      scrollTo(content, targetScrollLeft);
    }
  };

  const scrollBack = () => {
    const content = contentRef.current;
    if (content) {
      const scrollAmount = content.scrollWidth * 0.1;
      const currentScrollLeft = content.scrollLeft;
      const targetScrollLeft = currentScrollLeft - scrollAmount;

      scrollTo(content, targetScrollLeft);
    }
  };

  const scrollTo = (element, target) => {
    const numSteps = 30;
    const step = (target - element.scrollLeft) / numSteps;

    for (let i = 0; i < numSteps; i++) {
      setTimeout(() => {
        element.scrollLeft += step;
      }, i * 10);
    }
  };

  useEffect(() => {
    const filterUnlockme = productList.filter(
      (data) => data.package_title.toLowerCase() === 'unlock me'
    );
    setPlanForUnlockMe(filterUnlockme);
  }, [productList]);

  const filteredObjects = productsForNew.flatMap((obj) => {
    const filteredSubCategories = obj.sub_categories.filter((subCat) => {
      return subCat.package_id || (subCat.products && subCat.products.length > 0);
    });

    return filteredSubCategories
      .map((subCat) => {
        if (subCat.has_durations && subCat.durations.length > 0) {
          return {
            ...subCat,
            duration_id: subCat.durations[0].id,
            standard_price: subCat.durations[0].standard_price,
            special_price: subCat.durations[0].special_price
          };
        } else {
          return subCat.products ? subCat.products : subCat;
        }
      })
      .flat();
  });

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener on component mount
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [TempPOP, setTempPOP] = useState(false);
  const [TempData, setTempData] = useState();

  const callForPopup = () => {
    axios
      .get(`${process.env.REACT_APP_BASEURL}tata/website/v1/popup_active`)
      .then((response) => {
        const finalData = response.data.data[0];
        setTempPOP(finalData.has_popup);
        setTempData(finalData.popup_data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    callForPopup();
  }, []);

  return (
    <Wrapper style={{ padding: '37px 0' }}>
      {show ? (
        <Modal
          IsValid={IsValid}
          OpenMessage={OpenMessage}
          setOpenMessage={setOpenMessage}
          show={true}
          setLoading={setLoading}
          loading={loading}
          Loading={Loading}
          setModalShow={setShow}
          planName={planName}
          emailCheck={emailCheck}
          price={price}
          familyToken={token}
          product={prodId}
          durationId={durationId}
          duration={getDurationFromId(durationId)}
          productList={productList}
          setSuccess={setSuccess}
          // onClick={() => setShow(false)}
        />
      ) : null}
      <Model show={TempPOP}>
        <ModelContainer>
          <Content>
            <IoMdClose
              style={{ position: 'absolute', right: 8, top: 8, cursor: 'pointer' }}
              onClick={() => setTempPOP(false)}
            />
            <div
              style={{
                fontSize: 30,
                marginBottom: 25
              }}>
              {TempData?.title}
            </div>
            <div
              style={{
                fontSize: 18,
                marginBottom: 25
              }}>
              {TempData?.body.map((data) => (
                <div>{data}</div>
              ))}
            </div>
            <div>*Note: {TempData?.note}</div>
          </Content>
        </ModelContainer>
      </Model>
      <Model show={Success}>
        <ModelContainer>
          <Content>
            <img src="assets/done.png" />
            <div style={{ color: '#000', fontSize: 30, fontWeight: 600, marginTop: 38 }}>
              Payment Successful!
            </div>
            <div
              onClick={() => {
                setSuccess(false);
                history.push('/thankyou');
                location.reload();
              }}
              style={{
                background: '#0D85C3',
                padding: '10px 30px',
                borderRadius: 5,
                width: '70%',
                color: '#fff',
                cursor: 'pointer',
                marginTop: 20,
                display: 'flex',
                justifyContent: 'center'
              }}>
              Done
            </div>
          </Content>
        </ModelContainer>
      </Model>
      <div className="split">
        {productsForNew.map((splitData) => (
          <div
            className={`${
              SelectedCategory === splitData.category_id ? 'active-category' : 'category'
            }`}
            onClick={() => {
              setSelectedCategory(splitData.category_id);
              setSelectedNAme(splitData.category);
              setHasSubCategory(splitData.has_sub_category);
              setSubCat(
                splitData.has_sub_category ? splitData.sub_categories[0]?.sub_category_id : ''
              );
            }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
                alignItems: 'center'
              }}>
              <span className="new-align">{splitData.category}</span>
              {splitData.category === 'Blood Test' && <span className="new">New</span>}
            </div>
            {SelectedCategory === splitData.category_id && (
              <svg
                width="52"
                height="41"
                viewBox="0 0 52 41"
                className="arrow"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M51.0466 0.5L25.9807 39.574L0.914692 0.499996L51.0466 0.5Z"
                  fill="#FFFFFF"
                  stroke="#FFFFFF"
                />
              </svg>
            )}
          </div>
        ))}
      </div>
      {HasSubCategory && (
        <div className="has-sub">
          {productsForNew.map(
            (splitData) =>
              SelectedCategory === splitData.category_id &&
              splitData.sub_categories.map((category) => (
                <div
                  className={`${
                    SubCat === category.sub_category_id ? 'sub-cat-active' : 'sub-cat'
                  }`}
                  key={category.sub_category_id}
                  onClick={() => setSubCat(category.sub_category_id)}>
                  {category.sub_category}
                </div>
              ))
          )}
        </div>
      )}
      {HasSubCategory && (
        <div
          className={`${
            subCategoryLengthForBlood === 2 ? 'cards-for-blood-test-1' : 'cards-for-blood-test'
          }`}>
          {productsForNew
            .filter((splitData) => SelectedCategory === splitData.category_id)
            .flatMap((splitData) =>
              splitData.sub_categories
                .filter((category) => SubCat === category.sub_category_id)
                .flatMap((category) =>
                  category.products.map((product, index) => (
                    <div
                      key={product.package_id}
                      className={`${
                        category.products.length === 1 && screenWidth > 800
                          ? 'product-card'
                          : category.products.length === 2
                          ? 'product-card-1'
                          : 'product-card-2'
                      }`}>
                      <div className="space">
                        <div>
                          <div className="title">{product.package_title}</div>
                          <div className="descrption">{product.package_description}</div>
                          <div className="tags">
                            <ul>
                              {product.tags?.map((tags) => (
                                <li>{tags.name}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div>
                          <div
                            className={`${
                              category.products.length === 1 && screenWidth > 800
                                ? 'bottom'
                                : 'bottom-1'
                            }`}>
                            <div className="features">
                              <div className="feat">
                                <div className="round">
                                  <img src="./assets/booking.svg" alt="Easy Booking" />
                                </div>
                                Easy Booking
                              </div>
                              <div className="feat">
                                <div className="round">
                                  <img src="./assets/safe.svg" alt="Safe & Hygiene" />
                                </div>
                                100% Safe & Hygiene
                              </div>
                              <div className="feat">
                                <div className="round">
                                  <img src="./assets/view_app.svg" alt="View Report on App" />
                                </div>
                                View Report on Unlock.fit App
                              </div>
                              <div className="feat">
                                <div className="round">
                                  <img src="./assets/consultation.svg" alt="Free Consultation" />
                                </div>
                                Free Nutritionist Consultation
                              </div>
                              <div className="feat">
                                <div className="round">
                                  <img src="./assets/home.svg" alt="Home Sample Pick Up" />
                                </div>
                                Home Sample Pick Up
                              </div>
                            </div>
                            <div
                              className={`${
                                category.products.length === 1 && screenWidth > 800
                                  ? 'prices'
                                  : 'prices-1'
                              }`}>
                              <div>
                                <span className="actual">&#8377; {product.standard_price}</span>
                                <span className="actual-1">&#8377; {product.special_price}</span>
                              </div>
                              <span
                                className={`${screenWidth < 900 ? 'blues-2' : 'blues'}`}
                                onClick={() => {
                                  setProdId(product?.package_id);
                                  setPrice(product?.special_price);
                                  setPlanName(product?.package_title);
                                  setShow(true);
                                }}>
                                Buy Now
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )
            )}
        </div>
      )}
      {SelectedNAme === 'DNA Test' && (
        <div
          className={`${
            subCategoryLength === 2 ? 'cards-for-blood-test-1' : 'cards-for-blood-test'
          }`}>
          {productsForNew
            .filter((splitData) => SelectedCategory === splitData.category_id)
            .flatMap((splitData) =>
              splitData.sub_categories.map((product) => (
                <div
                  key={product.package_id}
                  className={`${
                    splitData.sub_categories.length === 1 && screenWidth > 800
                      ? 'product-card'
                      : splitData.sub_categories.length === 2
                      ? 'product-card-1'
                      : 'product-card-2'
                  }`}>
                  <div className="space">
                    <div>
                      <div className="title">{product.package_title} ®</div>
                      <div className="descrption">{product.package_description}</div>
                      <div className="tags">
                        <ul>
                          {product.tags?.map((tags) => (
                            <li>{tags.name}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div>
                      <div
                        className={`${
                          splitData.sub_categories.length === 1 && screenWidth > 800
                            ? 'bottom'
                            : 'bottom-1'
                        }`}>
                        <div className="features">
                          <div className="feat">
                            <div className="round">
                              <img src="./assets/booking.svg" alt="Easy Booking" />
                            </div>
                            Easy Booking
                          </div>
                          <div className="feat">
                            <div className="round">
                              <img src="./assets/safe.svg" alt="Safe & Hygiene" />
                            </div>
                            100% Safe & Hygiene
                          </div>
                          <div className="feat">
                            <div className="round">
                              <img src="./assets/view_app.svg" alt="View Report on App" />
                            </div>
                            View Report on Unlock.fit App
                          </div>
                          <div className="feat">
                            <div className="round">
                              <img src="./assets/consultation.svg" alt="Free Consultation" />
                            </div>
                            Free Nutritionist Consultation
                          </div>
                          <div className="feat">
                            <div className="round">
                              <img src="./assets/home.svg" alt="Home Sample Pick Up" />
                            </div>
                            Home Sample Pick Up
                          </div>
                        </div>
                        <div
                          className={`${
                            splitData.sub_categories.length === 1 && screenWidth > 800
                              ? 'prices'
                              : 'prices-1'
                          }`}>
                          <div>
                            <span className="actual">&#8377; {product.standard_price}</span>
                            <span className="actual-1">&#8377; {product.special_price}</span>
                          </div>
                          <span
                            className={`${screenWidth < 900 ? 'blues-2' : 'blues'}`}
                            onClick={() => {
                              setProdId(product?.package_id);
                              setPrice(product?.special_price);
                              setPlanName(product?.package_title);
                              setShow(true);
                            }}>
                            Buy Now
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
        </div>
      )}
      {SelectedNAme === 'Nutrition Plan' && (
        <div
          className={`${
            subCategoryLengthFornut === 2 ? 'cards-for-blood-test-1' : 'cards-for-blood-test'
          }`}>
          {productsForNew
            .filter((splitData) => SelectedCategory === splitData.category_id)
            .flatMap((splitData) =>
              splitData.sub_categories.map((product) => (
                <div
                  key={product.package_id}
                  className={`${
                    splitData.sub_categories.length === 1 && screenWidth > 800
                      ? 'product-card'
                      : splitData.sub_categories.length === 2
                      ? 'product-card-1'
                      : 'product-card-2'
                  }`}>
                  <div className="space">
                    <div>
                      <div className="title">{product.package_title}</div>
                      <div className="descrption">{product.package_description}</div>
                      <div className="tags">
                        <ul>
                          {product.tags?.map((tags) => (
                            <li>{tags.name}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div>
                      <div
                        className={`${
                          splitData.sub_categories.length === 1 && screenWidth > 800
                            ? 'bottom'
                            : 'bottom-1'
                        }`}>
                        <div className="duration">
                          <div className="margins1">
                            {product?.has_durations && (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    padding: '10px 0px',
                                    justifyContent: 'center'
                                  }}>
                                  {product?.durations.map((duration, index) => (
                                    <div
                                      style={{
                                        background:
                                          product.duration_id === duration.id ? '#0D85C3' : 'white',
                                        boxShadow: '0px 0px 34px rgba(0, 0, 0, 0.09)',
                                        borderRadius: 10,
                                        color:
                                          product.duration_id === duration.id ? '#FFFF' : '#0D85C3',
                                        margin:
                                          index === 0
                                            ? '0px 10px 0px 20px'
                                            : index === product?.durations.length - 1
                                            ? '0px 20px 0px 10px'
                                            : '0px 10px'
                                      }}>
                                      <div
                                        className="prod-list"
                                        onClick={() => {
                                          setproductsForNew((prevProducts) =>
                                            prevProducts.map((prevProduct) => {
                                              if (prevProduct.sub_categories) {
                                                const updatedSubCategories =
                                                  prevProduct.sub_categories.map((subCategory) => {
                                                    if (
                                                      subCategory.package_id ===
                                                        product.package_id &&
                                                      subCategory.has_durations
                                                    ) {
                                                      return {
                                                        ...subCategory,
                                                        duration_id: duration.id,
                                                        standard_price: duration.actual_price,
                                                        special_price: duration.special_price
                                                      };
                                                    }
                                                    return subCategory;
                                                  });
                                                return {
                                                  ...prevProduct,
                                                  sub_categories: updatedSubCategories
                                                };
                                              }
                                              return prevProduct;
                                            })
                                          );

                                          const updatedProducts = productList.map((prod) => {
                                            if (prod.package_id === product.package_id) {
                                              return {
                                                ...prod,
                                                standard_price: duration.actual_price,
                                                special_price: duration.special_price,
                                                duration: duration.life,
                                                duration_id: duration.id
                                              };
                                            }
                                            return prod;
                                          });
                                          setProductList(updatedProducts);
                                        }}>
                                        <div className="special-life">{duration.life}</div>
                                        <div className="special-type">{duration.duration_type}</div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <span className="actual" style={{ textAlign: 'center' }}>
                          &#8377; {product.standard_price} (Inclusive of all taxes)
                        </span>
                        <div
                          className={`${
                            splitData.sub_categories.length === 1 && screenWidth > 800
                              ? 'prices'
                              : 'prices-1'
                          }`}>
                          <span
                            className={`${screenWidth < 900 ? 'blues-1' : 'blues'}`}
                            onClick={() => {
                              setProdId(product?.package_id);
                              setPrice(product?.special_price);
                              setPlanName(product?.package_title);
                              setShow(true);
                              if (product?.has_durations) {
                                setDurationId(product?.duration_id);
                              }
                            }}>
                            Buy In &#8377; {product.special_price} (Inclusive of all taxes)
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
        </div>
      )}
    </Wrapper>
  );
};

export default Section6;
