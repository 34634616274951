import React from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 7vh;
  /* background-color: black; */
  background-color: #3d86c6;
  color: #ebebebc8;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  justify-content: space-around;
  a {
    font-size: 0.8rem;
    text-decoration: none;
    color: #ebebebc8;
  }
  div {
    width: 20rem;
    display: flex;
    justify-content: space-around;
  }
`;

export const TextWrapper = styled.div`
  p {
    font-size: 0.8rem;
  }
`;

const SubFooter = () => {
  return (
    <Wrapper>
      <TextWrapper>
        <p>© 2021 Tata Communications | unlock.fit. All Rights Reserved.</p>
      </TextWrapper>
      <div>
        <a href="./privacy">PRIVACY POLICY</a>
        <a href="./terms">TERMS OF USE</a>
      </div>
    </Wrapper>
  );
};

export default SubFooter;
