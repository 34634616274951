import styled from 'styled-components';

export const SuperWrapper = styled.div`
  height: 110vh;
  width: 100%;
  position: relative;
  background: url('/assets/Artboard-3-1.png') no-repeat center;
  background-size: cover;
  background-size: 57%;
  @media (max-width: 1024px) {
    background-size: 120%;
    height: 100vh;
  }
  @media (max-width: 800px) {
    background-size: 130%;
  }
  @media (max-width: 700px) {
    background-size: 150%;
  }
  @media (max-width: 600px) {
    height: 70vh;
  }
  @media (max-width: 450px) {
    background-size: 160%;
  }
`;

export const Wrapper = styled.div`
  height: 110vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(250, 250, 250, 0.97);
  @media (max-width: 1024px) {
    height: 100vh;
  }
  @media (max-width: 800px) {
    background-size: 150%;
  }
  @media (max-width: 600px) {
    background-size: 200%;
    height: 70vh;
  } ;
`;

export const Container = styled.div`
  display: flex;
  width: 80%;
  height: 100vh;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  width: 65%;
  max-width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 800px) {
    width: 90%;

    h2 {
      font-size: 1.7rem;
    }
    h3 {
      font-size: 1rem;
    }
  }
  @media (max-width: 600px) {
    width: 100%;

    h2 {
      font-size: 1.5rem;
    }
    h3 {
      font-size: 0.7rem;
    }
  }
  @media (max-width: 400px) {
    h2 {
      font-size: 1.2rem;
    }
  } ;
`;

export const HelixImage = styled.div`
  img {
    width: 6rem;
    border-radius: 100%;

    @media (max-width: 600px) {
      width: 4rem;
    }
    @media (max-width: 400px) {
      width: 3rem;
    }
  }
`;

export const LinerImage = styled.div`
  img {
    width: 0.2rem;
    height: 7rem;

    @media (max-width: 600px) {
      height: 5rem;
    }
  }
`;

export const UnlockLogo = styled.div`
  img {
    width: 6rem;
    border-radius: 100%;

    @media (max-width: 600px) {
      width: 4rem;
    }

    @media (max-width: 400px) {
      width: 3rem;
    }
  }
`;
export const PathChangerImage = styled.div`
  img {
    width: 100%;
    height: 18rem;

    @media (max-width: 800px) {
      height: 16rem;
    }
  }
`;

export const DualIconContainer = styled.div`
  width: 80%;
  display: flex;
  margin-top: 1rem;
  margin-bottom: 2rem;
  justify-content: space-between;
  @media (max-width: 1280px) {
    width: 95%;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
  @media (max-width: 650px) {
    width: 115%;
  }
  @media (max-width: 600px) {
    width: 85%;
    margin-top: 0.5rem;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
  @media (max-width: 450px) {
    width: 120%;
  }

  img {
    width: 6rem;
    border-radius: 100%;

    @media (max-width: 600px) {
      width: 4rem;
    }
    @media (max-width: 400px) {
      width: 3rem;
    }
  }
`;

export const AppleImg = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
