export const CountryCode = [
    {
        "code": "+91",
        "name": "India",
        "flag": "https://flagcdn.com/w20/in.png"
    },
    {
        "code": "+93",
        "name": "Afghanistan",
        "flag": "https://flagcdn.com/w20/af.png"
    },
    {
        "code": "+355",
        "name": "Albania",
        "flag": "https://flagcdn.com/w20/al.png"
    },
    {
        "code": "+213",
        "name": "Algeria",
        "flag": "https://flagcdn.com/w20/dz.png"
    },
    {
        "code": "+1 684",
        "name": "American Samoa",
        "flag": "https://flagcdn.com/w20/as.png"
    },
    {
        "code": "+376",
        "name": "Andorra",
        "flag": "https://flagcdn.com/w20/ad.png"
    },
    {
        "code": "+244",
        "name": "Angola",
        "flag": "https://flagcdn.com/w20/ao.png"
    },
    {
        "code": "+1 264",
        "name": "Anguilla",
        "flag": "https://flagcdn.com/w20/ai.png"
    },
    {
        "code": "+1 268",
        "name": "Antigua and Barbuda",
        "flag": "https://flagcdn.com/w20/ag.png"
    },
    {
        "code": "+54",
        "name": "Argentina",
        "flag": "https://flagcdn.com/w20/ar.png"
    },
    {
        "code": "+374",
        "name": "Armenia",
        "flag": "https://flagcdn.com/w20/am.png"
    },
    {
        "code": "+297",
        "name": "Aruba",
        "flag": "https://flagcdn.com/w20/aw.png"
    },
    {
        "code": "+61",
        "name": "Australia",
        "flag": "https://flagcdn.com/w20/au.png"
    },
    {
        "code": "+43",
        "name": "Austria",
        "flag": "https://flagcdn.com/w20/at.png"
    },
    {
        "code": "+994",
        "name": "Azerbaijan",
        "flag": "https://flagcdn.com/w20/az.png"
    },
    {
        "code": "+1 242",
        "name": "Bahamas",
        "flag": "https://flagcdn.com/w20/bs.png"
    },
    {
        "code": "+973",
        "name": "Bahrain",
        "flag": "https://flagcdn.com/w20/bh.png"
    },
    {
        "code": "+880",
        "name": "Bangladesh",
        "flag": "https://flagcdn.com/w20/bd.png"
    },
    {
        "code": "+1 246",
        "name": "Barbados",
        "flag": "https://flagcdn.com/w20/bb.png"
    },
    {
        "code": "+375",
        "name": "Belarus",
        "flag": "https://flagcdn.com/w20/by.png"
    },
    {
        "code": "+32",
        "name": "Belgium",
        "flag": "https://flagcdn.com/w20/be.png"
    },
    {
        "code": "+501",
        "name": "Belize",
        "flag": "https://flagcdn.com/w20/bz.png"
    },
    {
        "code": "+229",
        "name": "Benin",
        "flag": "https://flagcdn.com/w20/bj.png"
    },
    {
        "code": "+1 441",
        "name": "Bermuda",
        "flag": "https://flagcdn.com/w20/bm.png"
    },
    {
        "code": "+975",
        "name": "Bhutan",
        "flag": "https://flagcdn.com/w20/bt.png"
    },
    {
        "code": "+591",
        "name": "Bolivia",
        "flag": "https://flagcdn.com/w20/bo.png"
    },
    {
        "code": "+387",
        "name": "Bosnia and Herzegovina",
        "flag": "https://flagcdn.com/w20/ba.png"
    },
    {
        "code": "+267",
        "name": "Botswana",
        "flag": "https://flagcdn.com/w20/bw.png"
    },
    {
        "code": "+55",
        "name": "Brazil",
        "flag": "https://flagcdn.com/w20/br.png"
    },
    {
        "code": "+246",
        "name": "British Indian Ocean Territory",
        "flag": "https://flagcdn.com/w20/io.png"
    },
    {
        "code": "+673",
        "name": "Brunei",
        "flag": "https://flagcdn.com/w20/bn.png"
    },
    {
        "code": "+359",
        "name": "Bulgaria",
        "flag": "https://flagcdn.com/w20/bg.png"
    },
    {
        "code": "+226",
        "name": "Burkina Faso",
        "flag": "https://flagcdn.com/w20/bf.png"
    },
    {
        "code": "+257",
        "name": "Burundi",
        "flag": "https://flagcdn.com/w20/bi.png"
    },
    {
        "code": "+855",
        "name": "Cambodia",
        "flag": "https://flagcdn.com/w20/kh.png"
    },
    {
        "code": "+237",
        "name": "Cameroon",
        "flag": "https://flagcdn.com/w20/cm.png"
    },
    {
        "code": "+1",
        "name": "Canada",
        "flag": "https://flagcdn.com/w20/ca.png"
    },
    {
        "code": "+238",
        "name": "Cape Verde",
        "flag": "https://flagcdn.com/w20/cv.png"
    },
    {
        "code": "+ 345",
        "name": "Cayman Islands",
        "flag": "https://flagcdn.com/w20/ky.png"
    },
    {
        "code": "+236",
        "name": "Central African Republic",
        "flag": "https://flagcdn.com/w20/cf.png"
    },
    {
        "code": "+235",
        "name": "Chad",
        "flag": "https://flagcdn.com/w20/td.png"
    },
    {
        "code": "+56",
        "name": "Chile",
        "flag": "https://flagcdn.com/w20/cl.png"
    },
    {
        "code": "+86",
        "name": "China",
        "flag": "https://flagcdn.com/w20/cn.png"
    },
    {
        "code": "+61",
        "name": "Christmas Island",
        "flag": "https://flagcdn.com/w20/cx.png"
    },
    {
        "code": "+61",
        "name": "Cocos-Keeling Islands",
        "flag": "https://flagcdn.com/w20/cc.png"
    },
    {
        "code": "+57",
        "name": "Colombia",
        "flag": "https://flagcdn.com/w20/co.png"
    },
    {
        "code": "+269",
        "name": "Comoros",
        "flag": "https://flagcdn.com/w20/km.png"
    },
    {
        "code": "+242",
        "name": "Congo",
        "flag": "https://flagcdn.com/w20/cg.png"
    },
    {
        "code": "+243",
        "name": "Congo, Dem. Rep. of (Zaire)",
        "flag": "https://flagcdn.com/w20/cd.png"
    },
    {
        "code": "+682",
        "name": "Cook Islands",
        "flag": "https://flagcdn.com/w20/ck.png"
    },
    {
        "code": "+506",
        "name": "Costa Rica",
        "flag": "https://flagcdn.com/w20/cr.png"
    },
    {
        "code": "+385",
        "name": "Croatia",
        "flag": "https://flagcdn.com/w20/hr.png"
    },
    {
        "code": "+53",
        "name": "Cuba",
        "flag": "https://flagcdn.com/w20/cu.png"
    },
    {
        "code": "+599",
        "name": "Curacao",
        "flag": "https://flagcdn.com/w20/cw.png"
    },
    {
        "code": "+537",
        "name": "Cyprus",
        "flag": "https://flagcdn.com/w20/cy.png"
    },
    {
        "code": "+420",
        "name": "Czech Republic",
        "flag": "https://flagcdn.com/w20/cz.png"
    },
    {
        "code": "+45",
        "name": "Denmark",
        "flag": "https://flagcdn.com/w20/dk.png"
    },
    {
        "code": "+253",
        "name": "Djibouti",
        "flag": "https://flagcdn.com/w20/dj.png"
    },
    {
        "code": "+1 767",
        "name": "Dominica",
        "flag": "https://flagcdn.com/w20/dm.png"
    },
    {
        "code": "+1 809",
        "name": "Dominican Republic",
        "flag": "https://flagcdn.com/w20/do.png"
    },
    {
        "code": "+593",
        "name": "Ecuador",
        "flag": "https://flagcdn.com/w20/ec.png"
    },
    {
        "code": "+20",
        "name": "Egypt",
        "flag": "https://flagcdn.com/w20/eg.png"
    },
    {
        "code": "+503",
        "name": "El Salvador",
        "flag": "https://flagcdn.com/w20/sv.png"
    },
    {
        "code": "+240",
        "name": "Equatorial Guinea",
        "flag": "https://flagcdn.com/w20/gq.png"
    },
    {
        "code": "+291",
        "name": "Eritrea",
        "flag": "https://flagcdn.com/w20/er.png"
    },
    {
        "code": "+372",
        "name": "Estonia",
        "flag": "https://flagcdn.com/w20/ee.png"
    },
    {
        "code": "+251",
        "name": "Ethiopia",
        "flag": "https://flagcdn.com/w20/et.png"
    },
    {
        "code": "+500",
        "name": "Falkland Islands",
        "flag": "https://flagcdn.com/w20/fk.png"
    },
    {
        "code": "+298",
        "name": "Faroe Islands",
        "flag": "https://flagcdn.com/w20/fo.png"
    },
    {
        "code": "+679",
        "name": "Fiji",
        "flag": "https://flagcdn.com/w20/fj.png"
    },
    {
        "code": "+358",
        "name": "Finland",
        "flag": "https://flagcdn.com/w20/fi.png"
    },
    {
        "code": "+33",
        "name": "France",
        "flag": "https://flagcdn.com/w20/fr.png"
    },
    {
        "code": "+594",
        "name": "French Guiana",
        "flag": "https://flagcdn.com/w20/gf.png"
    },
    {
        "code": "+689",
        "name": "French Polynesia",
        "flag": "https://flagcdn.com/w20/pf.png"
    },
    {
        "code": "+241",
        "name": "Gabon",
        "flag": "https://flagcdn.com/w20/ga.png"
    },
    {
        "code": "+220",
        "name": "Gambia",
        "flag": "https://flagcdn.com/w20/gm.png"
    },
    {
        "code": "+995",
        "name": "Georgia",
        "flag": "https://flagcdn.com/w20/ge.png"
    },
    {
        "code": "+49",
        "name": "Germany",
        "flag": "https://flagcdn.com/w20/de.png"
    },
    {
        "code": "+233",
        "name": "Ghana",
        "flag": "https://flagcdn.com/w20/gh.png"
    },
    {
        "code": "+350",
        "name": "Gibraltar",
        "flag": "https://flagcdn.com/w20/gi.png"
    },
    {
        "code": "+30",
        "name": "Greece",
        "flag": "https://flagcdn.com/w20/gr.png"
    },
    {
        "code": "+299",
        "name": "Greenland",
        "flag": "https://flagcdn.com/w20/gl.png"
    },
    {
        "code": "+1 473",
        "name": "Grenada",
        "flag": "https://flagcdn.com/w20/gd.png"
    },
    {
        "code": "+590",
        "name": "Guadeloupe",
        "flag": "https://flagcdn.com/w20/gp.png"
    },
    {
        "code": "+1 671",
        "name": "Guam",
        "flag": "https://flagcdn.com/w20/gu.png"
    },
    {
        "code": "+502",
        "name": "Guatemala",
        "flag": "https://flagcdn.com/w20/gt.png"
    },
    {
        "code": "+224",
        "name": "Guinea",
        "flag": "https://flagcdn.com/w20/gn.png"
    },
    {
        "code": "+245",
        "name": "Guinea-Bissau",
        "flag": "https://flagcdn.com/w20/gw.png"
    },
    {
        "code": "+595",
        "name": "Guyana",
        "flag": "https://flagcdn.com/w20/gy.png"
    },
    {
        "code": "+509",
        "name": "Haiti",
        "flag": "https://flagcdn.com/w20/ht.png"
    },
    {
        "code": "+504",
        "name": "Honduras",
        "flag": "https://flagcdn.com/w20/hn.png"
    },
    {
        "code": "+852",
        "name": "Hong Kong SAR China",
        "flag": "https://flagcdn.com/w20/hk.png"
    },
    {
        "code": "+36",
        "name": "Hungary",
        "flag": "https://flagcdn.com/w20/hu.png"
    },
    {
        "code": "+354",
        "name": "Iceland",
        "flag": "https://flagcdn.com/w20/is.png"
    },
    {
        "code": "+62",
        "name": "Indonesia",
        "flag": "https://flagcdn.com/w20/id.png"
    },
    {
        "code": "+98",
        "name": "Iran",
        "flag": "https://flagcdn.com/w20/ir.png"
    },
    {
        "code": "+964",
        "name": "Iraq",
        "flag": "https://flagcdn.com/w20/iq.png"
    },
    {
        "code": "+353",
        "name": "Ireland",
        "flag": "https://flagcdn.com/w20/ie.png"
    },
    {
        "code": "+972",
        "name": "Israel",
        "flag": "https://flagcdn.com/w20/il.png"
    },
    {
        "code": "+39",
        "name": "Italy",
        "flag": "https://flagcdn.com/w20/it.png"
    },
    {
        "code": "+1 876",
        "name": "Jamaica",
        "flag": "https://flagcdn.com/w20/jm.png"
    },
    {
        "code": "+81",
        "name": "Japan",
        "flag": "https://flagcdn.com/w20/jp.png"
    },
    {
        "code": "+962",
        "name": "Jordan",
        "flag": "https://flagcdn.com/w20/jo.png"
    },
    {
        "code": "+7 7",
        "name": "Kazakhstan",
        "flag": "https://flagcdn.com/w20/kz.png"
    },
    {
        "code": "+254",
        "name": "Kenya",
        "flag": "https://flagcdn.com/w20/ke.png"
    },
    {
        "code": "+686",
        "name": "Kiribati",
        "flag": "https://flagcdn.com/w20/ki.png"
    },
    {
        "code": "+965",
        "name": "Kuwait",
        "flag": "https://flagcdn.com/w20/kw.png"
    },
    {
        "code": "+996",
        "name": "Kyrgyzstan",
        "flag": "https://flagcdn.com/w20/kg.png"
    },
    {
        "code": "+371",
        "name": "Latvia",
        "flag": "https://flagcdn.com/w20/lv.png"
    },
    {
        "code": "+961",
        "name": "Lebanon",
        "flag": "https://flagcdn.com/w20/lb.png"
    },
    {
        "code": "+266",
        "name": "Lesotho",
        "flag": "https://flagcdn.com/w20/ls.png"
    },
    {
        "code": "+218",
        "name": "Libya",
        "flag": "https://flagcdn.com/w20/ly.png"
    },
    {
        "code": "+423",
        "name": "Liechtenstein",
        "flag": "https://flagcdn.com/w20/li.png"
    },
    {
        "code": "+370",
        "name": "Lithuania",
        "flag": "https://flagcdn.com/w20/lt.png"
    },
    {
        "code": "+352",
        "name": "Luxembourg",
        "flag": "https://flagcdn.com/w20/lu.png"
    },
    {
        "code": "+389",
        "name": "Macedonia",
        "flag": "https://flagcdn.com/w20/mk.png"
    },
    {
        "code": "+261",
        "name": "Madagascar",
        "flag": "https://flagcdn.com/w20/mg.png"
    },
    {
        "code": "+265",
        "name": "Malawi",
        "flag": "https://flagcdn.com/w20/mw.png"
    },
    {
        "code": "+60",
        "name": "Malaysia",
        "flag": "https://flagcdn.com/w20/my.png"
    },
    {
        "code": "+960",
        "name": "Maldives",
        "flag": "https://flagcdn.com/w20/mv.png"
    },
    {
        "code": "+223",
        "name": "Mali",
        "flag": "https://flagcdn.com/w20/ml.png"
    },
    {
        "code": "+356",
        "name": "Malta",
        "flag": "https://flagcdn.com/w20/mt.png"
    },
    {
        "code": "+692",
        "name": "Marshall Islands",
        "flag": "https://flagcdn.com/w20/mh.png"
    },
    {
        "code": "+596",
        "name": "Martinique",
        "flag": "https://flagcdn.com/w20/mq.png"
    },
    {
        "code": "+222",
        "name": "Mauritania",
        "flag": "https://flagcdn.com/w20/mr.png"
    },
    {
        "code": "+230",
        "name": "Mauritius",
        "flag": "https://flagcdn.com/w20/mu.png"
    },
    {
        "code": "+262",
        "name": "Mayotte",
        "flag": "https://flagcdn.com/w20/yt.png"
    },
    {
        "code": "+52",
        "name": "Mexico",
        "flag": "https://flagcdn.com/w20/mx.png"
    },
    {
        "code": "+373",
        "name": "Moldova",
        "flag": "https://flagcdn.com/w20/md.png"
    },
    {
        "code": "+377",
        "name": "Monaco",
        "flag": "https://flagcdn.com/w20/mc.png"
    },
    {
        "code": "+976",
        "name": "Mongolia",
        "flag": "https://flagcdn.com/w20/mn.png"
    },
    {
        "code": "+382",
        "name": "Montenegro",
        "flag": "https://flagcdn.com/w20/me.png"
    },
    {
        "code": "+1664",
        "name": "Montserrat",
        "flag": "https://flagcdn.com/w20/ms.png"
    },
    {
        "code": "+212",
        "name": "Morocco",
        "flag": "https://flagcdn.com/w20/ma.png"
    },
    {
        "code": "+95",
        "name": "Myanmar",
        "flag": "https://flagcdn.com/w20/mm.png"
    },
    {
        "code": "+264",
        "name": "Namibia",
        "flag": "https://flagcdn.com/w20/na.png"
    },
    {
        "code": "+674",
        "name": "Nauru",
        "flag": "https://flagcdn.com/w20/nr.png"
    },
    {
        "code": "+977",
        "name": "Nepal",
        "flag": "https://flagcdn.com/w20/np.png"
    },
    {
        "code": "+31",
        "name": "Netherlands",
        "flag": "https://flagcdn.com/w20/nl.png"
    },
    {
        "code": "+599",
        "name": "Netherlands Antilles",
        "flag": "https://flagcdn.com/w20/an.png"
    },
    {
        "code": "+687",
        "name": "New Caledonia",
        "flag": "https://flagcdn.com/w20/nc.png"
    },
    {
        "code": "+64",
        "name": "New Zealand",
        "flag": "https://flagcdn.com/w20/nz.png"
    },
    {
        "code": "+505",
        "name": "Nicaragua",
        "flag": "https://flagcdn.com/w20/ni.png"
    },
    {
        "code": "+227",
        "name": "Niger",
        "flag": "https://flagcdn.com/w20/ne.png"
    },
    {
        "code": "+234",
        "name": "Nigeria",
        "flag": "https://flagcdn.com/w20/ng.png"
    },
    {
        "code": "+683",
        "name": "Niue",
        "flag": "https://flagcdn.com/w20/nu.png"
    },
    {
        "code": "+672",
        "name": "Norfolk Island",
        "flag": "https://flagcdn.com/w20/nf.png"
    },
    {
        "code": "+47",
        "name": "Norway",
        "flag": "https://flagcdn.com/w20/no.png"
    },
    {
        "code": "+968",
        "name": "Oman",
        "flag": "https://flagcdn.com/w20/om.png"
    },
    {
        "code": "+92",
        "name": "Pakistan",
        "flag": "https://flagcdn.com/w20/pk.png"
    },
    {
        "code": "+680",
        "name": "Palau",
        "flag": "https://flagcdn.com/w20/pw.png"
    },
    {
        "code": "+970",
        "name": "Palestinian Territory",
        "flag": "https://flagcdn.com/w20/ps.png"
    },
    {
        "code": "+507",
        "name": "Panama",
        "flag": "https://flagcdn.com/w20/pa.png"
    },
    {
        "code": "+675",
        "name": "Papua New Guinea",
        "flag": "https://flagcdn.com/w20/pg.png"
    },
    {
        "code": "+595",
        "name": "Paraguay",
        "flag": "https://flagcdn.com/w20/py.png"
    },
    {
        "code": "+51",
        "name": "Peru",
        "flag": "https://flagcdn.com/w20/pe.png"
    },
    {
        "code": "+63",
        "name": "Philippines",
        "flag": "https://flagcdn.com/w20/ph.png"
    },
    {
        "code": "+48",
        "name": "Poland",
        "flag": "https://flagcdn.com/w20/pl.png"
    },
    {
        "code": "+351",
        "name": "Portugal",
        "flag": "https://flagcdn.com/w20/pt.png"
    },
    {
        "code": "+1 787",
        "name": "Puerto Rico",
        "flag": "https://flagcdn.com/w20/pr.png"
    },
    {
        "code": "+974",
        "name": "Qatar",
        "flag": "https://flagcdn.com/w20/qa.png"
    },
    {
        "code": "+262",
        "name": "Reunion",
        "flag": "https://flagcdn.com/w20/re.png"
    },
    {
        "code": "+40",
        "name": "Romania",
        "flag": "https://flagcdn.com/w20/ro.png"
    },
    {
        "code": "+7",
        "name": "Russia",
        "flag": "https://flagcdn.com/w20/ru.png"
    },
    {
        "code": "+250",
        "name": "Rwanda",
        "flag": "https://flagcdn.com/w20/rw.png"
    },
    {
        "code": "+685",
        "name": "Samoa",
        "flag": "https://flagcdn.com/w20/ws.png"
    },
    {
        "code": "+378",
        "name": "San Marino",
        "flag": "https://flagcdn.com/w20/sm.png"
    },
    {
        "code": "+966",
        "name": "Saudi Arabia",
        "flag": "https://flagcdn.com/w20/sa.png"
    },
    {
        "code": "+221",
        "name": "Senegal",
        "flag": "https://flagcdn.com/w20/sn.png"
    },
    {
        "code": "+381",
        "name": "Serbia",
        "flag": "https://flagcdn.com/w20/rs.png"
    },
    {
        "code": "+248",
        "name": "Seychelles",
        "flag": "https://flagcdn.com/w20/sc.png"
    },
    {
        "code": "+232",
        "name": "Sierra Leone",
        "flag": "https://flagcdn.com/w20/sl.png"
    },
    {
        "code": "+65",
        "name": "Singapore",
        "flag": "https://flagcdn.com/w20/sg.png"
    },
    {
        "code": "+421",
        "name": "Slovakia",
        "flag": "https://flagcdn.com/w20/sk.png"
    },
    {
        "code": "+386",
        "name": "Slovenia",
        "flag": "https://flagcdn.com/w20/si.png"
    },
    {
        "code": "+677",
        "name": "Solomon Islands",
        "flag": "https://flagcdn.com/w20/sb.png"
    },
    {
        "code": "+27",
        "name": "South Africa",
        "flag": "https://flagcdn.com/w20/za.png"
    },
    {
        "code": "+500",
        "name": "South Georgia and the South Sandwich Islands",
        "flag": "https://flagcdn.com/w20/gs.png"
    },
    {
        "code": "+34",
        "name": "Spain",
        "flag": "https://flagcdn.com/w20/es.png"
    },
    {
        "code": "+94",
        "name": "Sri Lanka",
        "flag": "https://flagcdn.com/w20/lk.png"
    },
    {
        "code": "+249",
        "name": "Sudan",
        "flag": "https://flagcdn.com/w20/sd.png"
    },
    {
        "code": "+597",
        "name": "Suriname",
        "flag": "https://flagcdn.com/w20/sr.png"
    },
    {
        "code": "+268",
        "name": "Swaziland",
        "flag": "https://flagcdn.com/w20/sz.png"
    },
    {
        "code": "+46",
        "name": "Sweden",
        "flag": "https://flagcdn.com/w20/se.png"
    },
    {
        "code": "+41",
        "name": "Switzerland",
        "flag": "https://flagcdn.com/w20/ch.png"
    },
    {
        "code": "+963",
        "name": "Syria",
        "flag": "https://flagcdn.com/w20/sy.png"
    },
    {
        "code": "+886",
        "name": "Taiwan",
        "flag": "https://flagcdn.com/w20/tw.png"
    },
    {
        "code": "+992",
        "name": "Tajikistan",
        "flag": "https://flagcdn.com/w20/tj.png"
    },
    {
        "code": "+255",
        "name": "Tanzania",
        "flag": "https://flagcdn.com/w20/tz.png"
    },
    {
        "code": "+66",
        "name": "Thailand",
        "flag": "https://flagcdn.com/w20/th.png"
    },
    {
        "code": "+670",
        "name": "Timor Leste",
        "flag": "https://flagcdn.com/w20/tl.png"
    },
    {
        "code": "+228",
        "name": "Togo",
        "flag": "https://flagcdn.com/w20/tg.png"
    },
    {
        "code": "+690",
        "name": "Tokelau",
        "flag": "https://flagcdn.com/w20/tk.png"
    },
    {
        "code": "+676",
        "name": "Tonga",
        "flag": "https://flagcdn.com/w20/to.png"
    },
    {
        "code": "+1 868",
        "name": "Trinidad and Tobago",
        "flag": "https://flagcdn.com/w20/tt.png"
    },
    {
        "code": "+216",
        "name": "Tunisia",
        "flag": "https://flagcdn.com/w20/tn.png"
    },
    {
        "code": "+90",
        "name": "Turkey",
        "flag": "https://flagcdn.com/w20/tr.png"
    },
    {
        "code": "+993",
        "name": "Turkmenistan",
        "flag": "https://flagcdn.com/w20/tm.png"
    },
    {
        "code": "+1 649",
        "name": "Turks and Caicos Islands",
        "flag": "https://flagcdn.com/w20/tc.png"
    },
    {
        "code": "+688",
        "name": "Tuvalu",
        "flag": "https://flagcdn.com/w20/tv.png"
    },
    {
        "code": "+1 340",
        "name": "U.S. Virgin Islands",
        "flag": "https://flagcdn.com/w20/vi.png"
    },
    {
        "code": "+256",
        "name": "Uganda",
        "flag": "https://flagcdn.com/w20/ug.png"
    },
    {
        "code": "+380",
        "name": "Ukraine",
        "flag": "https://flagcdn.com/w20/ua.png"
    },
    {
        "code": "+971",
        "name": "United Arab Emirates",
        "flag": "https://flagcdn.com/w20/ae.png"
    },
    {
        "code": "+44",
        "name": "United Kingdom",
        "flag": "https://flagcdn.com/w20/gb.png"
    },
    {
        "code": "+1",
        "name": "United States",
        "flag": "https://flagcdn.com/w20/us.png"
    },
    {
        "code": "+598",
        "name": "Uruguay",
        "flag": "https://flagcdn.com/w20/uy.png"
    },
    {
        "code": "+998",
        "name": "Uzbekistan",
        "flag": "https://flagcdn.com/w20/uz.png"
    },
    {
        "code": "+678",
        "name": "Vanuatu",
        "flag": "https://flagcdn.com/w20/vu.png"
    },
    {
        "code": "+58",
        "name": "Venezuela",
        "flag": "https://flagcdn.com/w20/ve.png"
    },
    {
        "code": "+84",
        "name": "Vietnam",
        "flag": "https://flagcdn.com/w20/vn.png"
    },
    {
        "code": "+681",
        "name": "Wallis and Futuna",
        "flag": "https://flagcdn.com/w20/wf.png"
    },
    {
        "code": "+967",
        "name": "Yemen",
        "flag": "https://flagcdn.com/w20/ye.png"
    },
    {
        "code": "+260",
        "name": "Zambia",
        "flag": "https://flagcdn.com/w20/zm.png"
    },
    {
        "code": "+263",
        "name": "Zimbabwe",
        "flag": "https://flagcdn.com/w20/zw.png"
    }
]