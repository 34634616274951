import React from 'react';
import { Model, ModelContainer, Content, Logo, ContactBox } from './styles';
import { Button } from '../../components';
// import { FaWhatsapp } from 'react-icons/fa';

const ContactModal = ({ ...restProps }) => {
  // const [show, setShow] = useState(restProps.show);
  // console.log(show, restProps.show);

  return (
    <Model show={restProps.show}>
      <ModelContainer>
        <Content>
          <Button
            style={{
              borderRadius: '20px',
              position: 'absolute',
              top: '0',
              right: '1rem',
              height: '2.3rem',
              width: '2.3rem',
              color: 'black',
              backgroundColor: 'white',
              padding: ' 0.4px 0.5px'

              // fontSize: '0.7rem'
            }}
            onClick={() => restProps.setModalShow(false)}>
            X
          </Button>
          <Logo src="/assets/Tata comm-unlock fit.png" alt="" />
          <ContactBox
            target="_blank"
            rel="noopener noreferrer"
            href="https://api.whatsapp.com/send/?phone=9818875990&text&type=phone_number&app_absent=0">
            <img src={'/assets/whatsapp.png'} alt="" /> Chat with us on Whatsapp
          </ContactBox>
          <ContactBox href="tel:9818875990">
            <img src={'/assets/phone-call.png'} alt="" /> Talk to us +91 9818-875-990
          </ContactBox>
          <ContactBox href="mailto:support@unlock.fit">
            <img src={'/assets/mail.png'} alt="" />
            Write to us at support@unlock.fit
          </ContactBox>
        </Content>
      </ModelContainer>
    </Model>
  );
};

export default ContactModal;
