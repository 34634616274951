import React from 'react';

import { IntroSection } from './styles';
import Button from '../../components/button';
// import { useParams } from 'react-router-dom';
const Homepage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  let name = queryParams.get('name');

  return (
    <IntroSection.SupperWrapper>
      <IntroSection.Wrapper>
        <IntroSection.LeftWrapper>
          <img src="./assets/Mock_Creative.png" alt="Mock_Creative" />
        </IntroSection.LeftWrapper>
        <IntroSection.RightWrapper>
          <h1>
            Hi , <span style={{ color: '#0055ff', textTransform: 'capitalize' }}>{name}</span>
          </h1>
          {/* <br /> */}
          <h1>Are you ready to unlock your true health potential?</h1>
          <p>
            Your genetic code contains all the information you need to optimise your health,
            &nbsp;nutrition and fitness. Your DNA is talking to you, are you listening?
          </p>
          {/* <br /> */}
          <Button>
            <a href="./#dnatest">Learn more</a>
          </Button>
        </IntroSection.RightWrapper>
      </IntroSection.Wrapper>
    </IntroSection.SupperWrapper>
  );
};

export default Homepage;
