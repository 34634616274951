import React, { useState } from 'react';
import axios from 'axios';

import {
  Wrapper,
  InnerWrapper,
  Card,
  // LeftWrapper,
  // RightWrapper,
  // Img,
  FormWrapper,
  // ButtonWrapper,
  Form,
  Input,
  // Input1,
  Input2,
  // Heading,
  // Button
  Side,
  Textarea
} from './styles';
import { Button } from '../../components';
const initialstate = {
  full_name: '',
  phone: '',
  email: '',
  permanent_address: '',
  permanent_state: '',
  permanent_city: '',
  permanent_post_code: '',
  billing_address: '',
  billing_state: '',
  billing_city: '',
  billing_post_code: ''
};
var Promise = require('promise');

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
// const __DEV__ = window.document.domain === 'localhost';
const ContactUs = (props) => {
  const [state, setState] = useState(initialstate);
  const [show] = useState(true);
  // const [submit, setSubmit] = useState(true);
  const [checked, setChecked] = useState(false);
  // const [billing_address, setBillingaddress] = useState(state.permanent_address);
  // const [billingstate, setBillingstate] = useState(state.permanent_state);
  // const [billingcity, setBillingcity] = useState(state.permanent_city);
  // const [billingpincode, setBillingpincode] = useState(state.billing_post_code);

  // const [secondform, setSecondform] = useState(false);
  console.log(props.hello);
  const handleInput = (e) => {
    const inputName = e.currentTarget.name;
    const value = e.currentTarget.value;

    setState((prev) => ({ ...prev, [inputName]: value }));
  };

  const click = () => {
    setChecked(!checked);
    state.billing_address = state.permanent_address;
    state.billing_state = state.permanent_state;
    state.billing_city = state.permanent_city;
    state.billing_post_code = state.permanent_post_code;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // setShow(false);
    console.log('submitted!');
    console.log(state);
    // if (setChecked(false)) {
    // }

    axios.post(`https://adamgenetics.herokuapp.com/users/detail`, state).then(
      (res) =>
        console.log(res) || res.data.status === 200 ? displayRazorpay() : alert('data not saved')

      //     ? setSubmit(false) || console.log(res) || setState('') || setShow(false)
      //     : // swal('Form Submitted', 'Data saved', 'success')
      //       console.log(res)
      // // swal('Server error', '...Refresh the page and fill the form once again')
    );
  };

  async function displayRazorpay() {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }
    const data = { product_id: '611aa02157d4e80016577518' };
    const result = await axios.post(
      `https://adamgenetics.herokuapp.com/transactions/razorpay-payment`,
      data
    );
    // .then((res) => res.json());

    console.log('razorpay');
    console.log(result);
    // console.log(result.data.data.);
    // question
    const options = {
      // key: process.env.React_APP_A0PI_KEY,
      currency: result.data.currency,
      // amount:z result.data.data.amount.toString(),
      amount: result.data.data.amount,
      order_id: result.data.data.id,
      // phone: result.data.phone,

      // image: 'http://localhost:2337/logo.svg',
      handler: async function (response) {
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
        alert('Successfully Paid to Adam-Genetics');
        // alert(this.phone);
        console.log('successfull!!');
        try {
          console.log('hello');
          const data = {
            product_id: '611aa02157d4e80016577518',
            user_id: '',
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            coupon_id: '',
            amount: ''
          };
          console.log('2nd result');
          console.log(data, 'datatest');
          const result = await axios.post(
            `https://adamgenetics.herokuapp.com/transactions/new`,
            data
          );
          console.log(result);
          // const paymentId = response.razorpay_payment_id;
          // const captureURL = `http://localhost:2337/capture/${paymentId}`;
          // const captureResponse = await Axios.post(captureURL, { amount: totalPrice });
          // eslint-disable-next-line no-unused-vars
          // const captureResponse = await fetch(captureURL + '?value=' + value, {
          //   method: 'POST'
          // }).then((t) => t.json());
        } catch (err) {
          console.log(err);
        }
      },
      prefill: {
        name
      }
    };
    console.log(options.product_id);

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    console.log(paymentObject);
  }

  // axios.get('https://api.postalpincode.in/pincode/800001').then((res) => {console.log(res)};
  // });
  return (
    <Wrapper bg={checked ? 'up' : ''}>
      {/* {submit ? ( */}
      <InnerWrapper>
        {/* <LeftWrapper> */}
        <Card>
          <FormWrapper>
            <Form onSubmit={handleSubmit}>
              <h1>User Details</h1>
              <label htmlFor="name">What should we call you?</label>
              <Input
                type="text"
                name="full_name"
                onChange={handleInput}
                value={show ? state.full_name : ''}
                placeholder="Full Name"
                required
              />
              <label htmlFor="number">Your phone number</label>
              <Input
                type="tel"
                name="phone"
                onChange={handleInput}
                value={show ? state.phone : ''}
                placeholder="Phone Number"
                pattern="[0-9]{10}"
                maxlength="10"
                required
              />
              <label htmlFor="email">Enter your email</label>
              <Input
                type="email"
                name="email"
                onChange={handleInput}
                value={show ? state.email : ''}
                placeholder="John@mail.com"
                required
              />
              <label htmlFor="name">Where should we deliver the product to you?</label>
              <Textarea
                type="text"
                name="permanent_address"
                onChange={handleInput}
                value={show ? state.permanent_address : ''}
                placeholder="Address"
                rows="5"
                cols="5"
                required
              />
              {/* <Input1
                  type="text"
                  name="permanent_address"
                  onChange={handleInput}
                  value={show ? state.permanent_address : ''}
                  placeholder="Address"
                  rows="20"
                  required
                /> */}
              <label htmlFor="pincode"> Pincode</label>
              <Input
                type="pin number"
                name="permanent_post_code"
                onChange={handleInput}
                pattern="[0-9]{6}"
                // pattern="^[1-9][0-9]{5}$"
                maxlength="6"
                minLength="6"
                value={show ? state.permanent_post_code : ''}
                placeholder="Pincode"
                required
              />

              <Side>
                <label htmlFor="city">
                  City
                  <Input2
                    type="text"
                    name="permanent_city"
                    onChange={handleInput}
                    value={show ? state.permanent_city : ''}
                    placeholder="City"
                    required
                  />
                </label>

                <label htmlFor="state">
                  State
                  <Input
                    type="text"
                    name="permanent_state"
                    onChange={handleInput}
                    value={show ? state.permanent_state : ''}
                    placeholder="State"
                    required
                  />
                </label>
              </Side>
              <input
                type="checkbox"
                name="check"
                value="terms"
                // onClick={clicked}
                // checked
                // required
                defaultChecked={checked}
                // checked="checked"
                // onChange={() => setChecked(!checked)}
                onChange={click}
              />
              <label htmlFor="billing">Billing Address same as above Address</label>
              <br />
              <br />
              {checked ? null : (
                <>
                  <label htmlFor="name">Billing Address</label>
                  <Textarea
                    type="text"
                    name="billing_address"
                    onChange={handleInput}
                    value={show ? state.billing_address : ''}
                    placeholder="Billing Address"
                    rows="5"
                    cols="5"
                    required
                  />

                  <label htmlFor="name"> Postcode</label>

                  <Input
                    type="pin number"
                    name="billing_post_code"
                    onChange={handleInput}
                    pattern="[0-9]{6}"
                    maxlength="6"
                    value={show ? state.billing_post_code : ''}
                    placeholder="Pincode"
                    required
                  />

                  <Side>
                    <label htmlFor="city">
                      City
                      <Input2
                        type="name"
                        name="billing_city"
                        onChange={handleInput}
                        value={show ? state.billing_city : ''}
                        placeholder="City"
                        required
                      />
                    </label>

                    <label htmlFor="postcode">
                      State
                      <Input
                        type="text"
                        name="billing_state"
                        onChange={handleInput}
                        value={show ? state.billing_state : ''}
                        placeholder="State"
                        required
                      />
                    </label>
                  </Side>
                </>
              )}

              <Button>Submit</Button>
              {/* )} */}
            </Form>
          </FormWrapper>
        </Card>
        {/* </LeftWrapper> */}
        {/* <RightWrapper>
          <Img bg={checked ? 'up' : ''}>
            <img src="./assets/formvector2.svg" alt=" formvector2"></img>
          </Img>
        </RightWrapper> */}
      </InnerWrapper>
    </Wrapper>
  );
};

export default ContactUs;
