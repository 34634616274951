import styled from 'styled-components/macro';

export const IntroSection = {};

IntroSection.SupperWrapper = styled.div`
  width: 100%;
  background-color: wheat;
`;
IntroSection.MainWrapper = styled.div`
  /* bg image */
  background: url('/assets/wellness.png') no-repeat;
  background-size: cover;
  background-position: 72%;

  /* @media (max-width: 800px) {
    background-position: 72%;
  } */
  @media (max-width: 600px) {
    /* background-position: 75%; */
    /* background-size: cover; */
    background: url('/assets/wellness1.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: left center; */
  }
`;
IntroSection.Wrapper = styled.div`
  display: flex;
  width: 90%;
  margin: 0 auto;
`;
IntroSection.ContentWrapper = styled.div`
  width: 55%;
  padding: 3rem 0;
  @media (max-width: 600px) {
    width: 80%;
  }
  h1 {
    font-size: 45px;
    font-weight: 800;
    color: #19191b;
    text-transform: uppercase;
    @media (max-width: 800px) {
      font-size: 30px;
    }
    @media (max-width: 600px) {
      font-size: 24px;
    }
  }
  Button {
    font-size: 18px;
    /* width: 90%; */
    font-weight: 700;
    margin-top: 2rem;
    background: #d2a614;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    color: #fff;
    padding: 1rem 1rem;
    :hover {
      background-color: #e4be02;
      transform: scale(1.06);
    }
    @media (max-width: 600px) {
      font-size: 18px;
    }
  }
`;
