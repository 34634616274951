import React from 'react';
import styled from 'styled-components/macro';
import { FaWhatsapp } from 'react-icons/fa';
import { MdOutlinePhoneAndroid } from 'react-icons/md';
import { HiOutlineMail } from 'react-icons/hi';

export const Wrapper = styled.div`
  width: 100%;
`;

export const MainWrapper = styled.div`
  max-width: 80%;
  padding: 5rem;
  margin: auto;
  display: flex;

  @media (max-width: 800px) {
    padding: 0;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    padding: 2rem 0;
  }

  h1 {
    margin-top: 10rem;
    text-align: center;
    @media (max-width: 600px) {
      margin-top: 0;
    }
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  margin: auto;

  @media (max-width: 600px) {
    margin: 3rem auto;
    /* padding: 5rem; */
  }

  img {
    margin: auto;
    width: 70%;
    /* padding: 5rem ; */
    @media (max-width: 600px) {
      width: 18rem;
    }
    @media (max-width: 450px) {
      width: 12rem;
      /* padding: 3rem; */
    }
  }
`;

const TextContainer = styled.div`
  flex: 1;

  h1 {
    text-align: left;
    height: auto;
    line-height: 1.7;
    font-size: 1.7rem;

    @media (max-width: 450px) {
      font-size: 1rem;
    }
    a {
      color: #d2a614;
    }
  }
  h2 {
    margin: 1.5rem 0;

    @media (max-width: 450px) {
      font-size: 1rem;
    }
  }
  h3 {
    font-weight: 500;
    font-size: 1.38rem;
    padding-bottom: 1rem;
    line-height: 1.5;
    width: 80%;

    @media (max-width: 450px) {
      font-size: 1rem;
    }
    a {
      text-decoration: none;
      color: #5c8be7;
      letter-spacing: 1px;
      margin: 0 0.4rem;
      :hover {
        color: teal;
      }
    }
  }
`;

const Contact = styled.div`
display: flex;
  flex-direction: column;

  h2 {
        font-size: 1rem;
        line-height:2;
        width:40%;
        padding-top: 1rem ;
        margin: 0;
        @media (max-width: 1024px) {
          font-size: 0.85rem;
        }
        
        @media (max-width: 450px) {
            width:100%;
        }
        
      }
      p {
        font-size: 0.9rem;
        letter-spacing:1px;
        color:grey;
        margin: 0;
        padding: 10px 0;

        @media (max-width: 800px) {
          font-size: 0.8rem;
        }
    }
    
    
  }
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 1rem 0;
  width: 40%;
  justify-content: space-between;
  /* @media (max-width: 1024px) {
    width: 50%;
  }
  @media (max-width: 800px) {
    width: 60%;
    margin: 0 auto;
  } */
`;
const Icon = styled.div`
  font-size: 1.5rem;
  /* margin: auto; */
  a {
    text-decoration: none;
    color: #000;
    :hover {
      /* font-size: 2rem; */
      color: #d2a614;
      transition: 0.2s ease-in-out 0s;
    }
  }
  :hover {
    animation: step 0.7s 1;
    animation-direction: alternate;
    @keyframes step {
    0% {  
      transform: rotate(-20deg);
    }
    50% {
      transform: rotate(0deg);
    }
    
    100% {
      transform: rotate(20deg);
    }
  }
`;

const Down = () => {
  return (
    <Wrapper>
      <MainWrapper>
        <ImageContainer>
          <img src="/assets/barcode.svg" alt={'tata'} />
          {/* <img src="/assets/phone_light.png" /> */}
        </ImageContainer>
        <TextContainer>
          <div>
            <h1>
              <a>Congratulations, </a>
              you are now ready to start your wellness journey
            </h1>
            <h2>How to sign in? </h2>
            <h3>1. Scan the QR code to download the unlock.fit mobile app.</h3>
            {/* <h3>
              1 . Download unlock.fit mobile app from here
              <a
                href="https://play.google.com/store/apps/details?id=com.unlock.fit"
                target="_blank"
                rel="noopener noreferrer">
                Android
              </a>
              |
              <a
                href="https://apps.apple.com/in/app/unlock-fit-dna-based-wellness/id1573454926"
                target="_blank"
                rel="noopener noreferrer">
                Apple
              </a>
            </h3> */}
            <h3>2. Sign in with your official email id and credentials received in email.</h3>
            <h3>3. Enjoy DNA based personalised wellness in your pocket.</h3>
          </div>
          <Contact>
            <h2>Facing any trouble</h2>
            <p>We love you as much as we love health and wellness.</p>
            <IconWrapper>
              <Icon>
                <a href="https://api.whatsapp.com/send/?phone=9818875990&text&type=phone_number&app_absent=0">
                  <FaWhatsapp />
                </a>
              </Icon>
              <Icon>
                <a href="tel:+919818875990">{<MdOutlinePhoneAndroid />}</a>
              </Icon>
              <Icon>
                <a href="mailto: support@unlock.fit">{<HiOutlineMail />}</a>
              </Icon>
            </IconWrapper>
          </Contact>
        </TextContainer>
      </MainWrapper>
    </Wrapper>
  );
};

export default Down;
