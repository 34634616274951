/* eslint-disable */
// prettier-ignore
import React, { useEffect, useRef, useState } from 'react';
import './home.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// import { Card, Wrapper, MainWrapper, CardContainer } from './style';
import axios from 'axios';
import { Content, Model, ModelContainer } from './style';

const Client = () => {
  const [Items, setItems] = useState([]);
  const [DataForPop, setDataForPop] = useState({});
  const [OpenMessage, setOpenMessage] = useState(false);
  const swiperRef = useRef();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASEURL}tata/website/v1/testimonials`)
      .then((response) => {
        setItems(response.data.testimonials);
      })
      .catch((error) => {
        console.error('Error fetching testimonials:', error);
      });
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener on component mount
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ marginTop: screenWidth > 800 ? 60 : 30 }}>
      <div className="absoo">
        <div className="header">TC Employees Feedback</div>
        <div className="sub-header">Trust Your Colleagues</div>
        <div style={{ position: 'absolute', bottom: 15, right: 15 }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
            className="left"
            style={{ cursor: 'pointer', marginRight: 15 }}
            onClick={() => swiperRef.current.slidePrev()}>
            <path
              d="M15.2083 11.3333L10.0417 16.5M10.0417 16.5L15.2083 21.6667M10.0417 16.5L22.9583 16.5M1 16.5C1 14.4645 1.40092 12.449 2.17987 10.5684C2.95882 8.68786 4.10054 6.97915 5.53985 5.53984C6.97916 4.10054 8.68786 2.95881 10.5684 2.17986C12.449 1.40092 14.4645 0.999999 16.5 0.999999C18.5355 0.999999 20.551 1.40092 22.4316 2.17987C24.3121 2.95881 26.0208 4.10054 27.4602 5.53985C28.8995 6.97915 30.0412 8.68786 30.8201 10.5684C31.5991 12.449 32 14.4645 32 16.5C32 20.6109 30.367 24.5533 27.4602 27.4602C24.5533 30.367 20.6109 32 16.5 32C12.3891 32 8.44666 30.367 5.53985 27.4602C2.63303 24.5533 1 20.6109 1 16.5Z"
              stroke="#4A4A4A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
            className="right"
            style={{ cursor: 'pointer' }}
            onClick={() => swiperRef.current.slideNext()}>
            <path
              d="M17.7917 21.6667L22.9583 16.5M22.9583 16.5L17.7917 11.3333M22.9583 16.5H10.0417M32 16.5C32 18.5355 31.5991 20.551 30.8201 22.4316C30.0412 24.3121 28.8995 26.0208 27.4602 27.4602C26.0208 28.8995 24.3121 30.0412 22.4316 30.8201C20.551 31.5991 18.5355 32 16.5 32C14.4645 32 12.449 31.5991 10.5684 30.8201C8.68786 30.0412 6.97915 28.8995 5.53984 27.4602C4.10054 26.0208 2.95881 24.3121 2.17987 22.4316C1.40092 20.551 1 18.5355 1 16.5C1 12.3891 2.63303 8.44666 5.53984 5.53984C8.44666 2.63303 12.3891 1 16.5 1C20.6109 1 24.5533 2.63303 27.4602 5.53984C30.367 8.44666 32 12.3891 32 16.5Z"
              stroke="#4A4A4A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      {Items.length > 0 && (
        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={screenWidth < 800 ? 10 : 50}
          slidesPerView={screenWidth < 800 ? 1.4 : 1.8}
          centeredSlides={true}
          loop
          className="mySwiper"
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}>
          {Items.map((data, index) => {
            const maxWords = screenWidth > 800 ? 220 : screenWidth >= 400 ? 200 : 300;
            const truncatedMessage = data.testimonial.slice(0, maxWords);

            console.log(screenWidth >= 800 ? 250 : screenWidth >= 400 ? 210 : 300);

            return (
              <SwiperSlide
                key={index}
                onMouseEnter={() => swiperRef.current.autoplay.stop()}
                onMouseLeave={() => swiperRef.current.autoplay.start()}>
                <div key={index} className="cards">
                  <img src="assets/upquote.svg" alt="test" className="up" />
                  <img src="assets/downquote.svg" alt="test" className="down" />
                  <div className="content">
                    <div className="message">
                      {truncatedMessage}
                      {data.testimonial.length > maxWords && (
                        <span style={{ whiteSpace: 'nowrap' }}>
                          ...{' '}
                          <span
                            className="read-more"
                            onClick={() => {
                              setDataForPop(data);
                              setOpenMessage(true);
                              swiperRef.current.autoplay.stop();
                            }}>
                            Read More
                          </span>
                        </span>
                      )}
                    </div>
                    <div className="profile">
                      <div className="resize">
                        {data.profile ? (
                          <div style={{ height: '100%', width: '100%' }}>
                            <img src={data.profile} alt="test" className="circle-picture" />
                          </div>
                        ) : data.is_male ? (
                          <div style={{ height: '100%', width: '100%' }}>
                            <img src="assets/male.png" alt="test" className="circle-icon" />
                          </div>
                        ) : (
                          <div style={{ height: '100%', width: '100%' }}>
                            <img src="assets/female.png" alt="test" className="circle-icon" />
                          </div>
                        )}
                      </div>
                      <div className="total">
                        <div className="name">{data.name}</div>
                        <div className="designation">{data.designation}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
      <Model show={OpenMessage}>
        <ModelContainer>
          <Content>
            <div
              style={{ position: 'absolute', right: 5, top: 5, cursor: 'pointer' }}
              onClick={() => {
                setOpenMessage(false);
              }}>
              <img src="assets/close.png" alt="test" className="circle-icon" />
            </div>
            <div className="content">
              <div className="pop-circle">
                {DataForPop.profile ? (
                  <div style={{ height: '100%', width: '100%' }}>
                    <img src={DataForPop.profile} alt="test" className="circle-picture" />
                  </div>
                ) : DataForPop.is_male ? (
                  <div style={{ height: '100%', width: '100%' }}>
                    <img src="assets/male.png" alt="test" className="circle-icon" />
                  </div>
                ) : (
                  <div style={{ height: '100%', width: '100%' }}>
                    <img src="assets/female.png" alt="test" className="circle-icon" />
                  </div>
                )}
              </div>
              <div className="pop-name">{DataForPop.name}</div>
              <div className="pop-des">{DataForPop.designation}</div>
              <div className="pop-mess">{DataForPop.testimonial}</div>
            </div>
          </Content>
        </ModelContainer>
      </Model>
    </div>
  );
};

export default Client;
