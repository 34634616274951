import styled from 'styled-components/macro';

export const IntroSection = {};

IntroSection.Wrapper = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  align-items: center;
  background-color: #fafafa;
  // background-color: #eee;
  padding-bottom: 2rem;
  @media (max-width: 800px) {
    // height: 90vh;
    padding-bottom: 0;
  }
  @media (max-width: 600px) {
    min-height: 55vh;
    padding-top: 1.5rem;
    padding-bottom: 3rem;
  }
`;

IntroSection.MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 80vw;
  height: auto;
  /* background-color: grey; */
  // justify-content: center;
  @media (max-width: 1024px) {
    // height: 80vh;
  }
  @media (max-width: 600px) {
    min-height: 55vh;
  }
`;
IntroSection.ImageWrapper = styled.div`
  img {
    width: 470px;
    height: 450px;

    @media (max-width: 1400px) {
      width: 420px;
      height: 400px;
    }
    @media (max-width: 800px) {
      width: 350px;
      height: 340px;
    }
    @media (max-width: 600px) {
      width: 240px;
      height: 230px;
    }
  }
`;
IntroSection.ContentWrapper = styled.div`
  display: flex;
  /* height: auto; */
  width: 80%;
  flex-direction: column;
  align-items: center;
  margin: 2rem auto;
  h1 {
    text-align: center;
    @media (max-width: 1400px) {
      font-size: 2.5rem;
    }
    @media (max-width: 1024px) {
      font-size: 2rem;
    }
    @media (max-width: 800px) {
      font-size: 1.5rem;
    }
    @media (max-width: 600px) {
      font-size: 1.5rem;
    }
  }
  p {
    width: 70%;
    color: grey;
    font-weight: 100;
    width: 90%;
    text-align: center;
    margin-bottom: 1rem;
    @media (max-width: 1400px) {
      width: 90%;
    }
    @media (max-width: 1024px) {
      font-size: 1rem;
    }
    @media (max-width: 800px) {
      // font-size: 0.5rem;
    }
  }
`;

IntroSection.BottomWrapper = styled.div`
  img {
    width: 650px;
    height: 210px;
    @media (max-width: 1024px) {
      width: 500px;
      height: 170px;
    }
    @media (max-width: 600px) {
      width: 350px;
      height: 120px;
    }
    @media (max-width: 400px) {
      width: 250px;
      height: 85px;
    }
  }
`;
