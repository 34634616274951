import styled from 'styled-components';
export const IntroSection = {};
IntroSection.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 25vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
  }
  @media (max-width: 600px) {
    height: 20vh;
  }
  @media (max-width: 450px) {
    height: 15vh;
  }
`;
IntroSection.Contact = styled.div`
  display: flex;
  width: 20rem;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  h2 {
    text-align: center;
    font-size: 2rem;
    font-weight: 100;
    line-height: 2;
    width: 56%;
    // border-bottom :0.5px solid #d2a614;
    @media (max-width: 1024px) {
      font-size: 1.5rem;
    }

    @media (max-width: 450px) {
      width: 100%;
      text-align: center;
      font-size: 1rem;
    }
  }
`;
IntroSection.IconWrapper = styled.div`
  display: flex;
  padding: 1rem 0;
  width: 70%;
  justify-content: space-around;

  @media (max-width: 1024px) {
    width: 50%;
  }
  @media (max-width: 800px) {
    width: 60%;
    margin: 0 auto;
  }
`;
IntroSection.Icon = styled.div`
  font-size: 2.5rem;
  // padding: 0 0.5rem;
  @media (max-width: 600px) {
    font-size: 1.7rem;
  }
  @media (max-width: 450px) {
    font-size: 1.2rem;
  }
  a {
    text-decoration: none;
    color: #000;
    :hover {
      font-size: 3rem;
      color: #d2a614;
     
    }
  }
  :hover {
    animation: step 0.7s 1;
    animation-direction: alternate;
  
  // @keyframes step {
  //   0% {
  //     transform: scaleX(1);
  //   }
  //   50% {
  //     transform: scaleX(-1);
  //   }
  //   100% {
  //     transform: scaleX(1);
  //   }
  // }

  @keyframes step {
    0% {  
      transform: rotate(-20deg);
    }
    50% {
      transform: rotate(0deg);
    }
    
    100% {
      transform: rotate(20deg);
    }
  }
`;
