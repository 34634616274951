import { React, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { IntroSection } from './styles';
import Button from '../../components/button';
const Sec4 = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  return (
    <IntroSection.SupperWrapper>
      <IntroSection.MainWrapper>
        <IntroSection.Wrapper>
          <IntroSection.ContentWrapper>
            <h1 data-aos="fade-down">
              YOUR <br /> WELLNESS <br /> POTENTIAL
            </h1>
            <a href="./#product">
              <Button>START TODAY</Button>
            </a>
          </IntroSection.ContentWrapper>
        </IntroSection.Wrapper>
      </IntroSection.MainWrapper>
    </IntroSection.SupperWrapper>
  );
};

export default Sec4;
