import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  // margin-bottom: 3rem;
  justify-content: center;
  // @media (max-width: 1150px) {
  //   width: auto;
  //   height: auto;
  // }
  // @media (max-width: 1150px) {
  //   height: 100%;
  // }
  // @media (max-width: 800px) {
  //   height: 80%;
  // }
  // @media (max-width: 600px) {
  //   height: 70%;
  // }
  // @media (max-width: 400px) {
  //   height: 50%;
  // }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const VideoWrapper = styled.video`
  background-size: contain;
  width: 100%;
  @media (max-width: 1440px) {
    // height: 80vh;
  }
  @media (max-width: 1150px) {
    // height: 60vh;
  }
  @media (max-width: 800px) {
    // height: 45vh;
  }
  @media (max-width: 600px) {
    // height: 30vh;
  }
  @media (max-width: 400px) {
    // width: 100%;
  }
`;
