import React from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #34a7a7;
  align-items: center;
  justify-content: center;
  img {
    width: 40%;
  }
  h2 {
    animation: color-change 4s infinite;
    max-width: 50%;
  }
  @keyframes color-change {
    0% {
      color: #ff4009;
    }
    15% {
      color: #e7ff34;
    }
    30% {
      color: #2fff2e;
    }
    45% {
      color: #46fffa;
    }

    60% {
      color: #2535ff;
    }
    75% {
      color: #d800f0;
    }
    100% {
      color: #f5013a;
    }
  }
`;

const PageNotFound = () => {
  return (
    <Wrapper>
      <img src="https://i.imgur.com/qIufhof.png" alt="hello" />
      <h2>Page Not found 404</h2>
    </Wrapper>
  );
};

export default PageNotFound;
