/* eslint-disable */
import React, { useState, useEffect } from 'react';
import './styles/Slider.css';

const AutoInfiniteSlider = ({
  images,
  setDurationId,
  setPlanName,
  setShow,
  setProdId,
  setPrice,
  PlanForUnlockMe,
  callOffers,
  productsForNew,
  setproductsForNew,
  SelectedCategory,
  setSelectedCategory,
  SelectedNAme,
  setSelectedNAme,
  HasSubCategory,
  setHasSubCategory,
  SubCat,
  setSubCat
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [images.length]);

  const DNA = productsForNew.find((item) => item.category.toLowerCase() === 'dna test');
  const Blood = productsForNew.find((item) => item.category.toLowerCase() === 'blood test');
  const findThyroid = Blood?.sub_categories.find(
    (item) => item.sub_category.toLowerCase() === 'thyroid'
  );
  const findchole = Blood?.sub_categories.find(
    (item) => item.sub_category.toLowerCase() === 'cholesterol'
  );
  const finddiab = Blood?.sub_categories.find(
    (item) => item?.sub_category.toLowerCase() === 'diabetes'
  );

  return (
    <div className="abso">
      <a href="./#product">
        <div
          className="slider-container"
          // onClick={() => {
          //   const divide = PlanForUnlockMe[0];
          //   setDurationId(divide.duration_id);
          //   setPlanName(divide.package_title);
          //   setShow(true);
          //   setProdId(divide.package_id);
          //   setPrice(divide.special_price);
          //   callOffers();
          // }}
        >
          <div
            className="slider-wrapper"
            style={{ transform: `translateX(${-currentIndex * 100}%)` }}>
            {images.map((image, index) => (
              <div key={index} className="slider-item">
                <img
                  src={image.link}
                  alt={`Slide ${index}`}
                  style={{ width: '-webkit-fill-available' }}
                  onClick={() => {
                    if (index === 0) {
                      setSelectedCategory(Blood.category_id);
                      setSelectedNAme(Blood.category);
                      setHasSubCategory(Blood.has_sub_category);
                      setSubCat(findchole.sub_category_id);
                    }
                    if (index === 1) {
                      setSelectedCategory(Blood.category_id);
                      setSelectedNAme(Blood.category);
                      setHasSubCategory(Blood.has_sub_category);
                      setSubCat(finddiab.sub_category_id);
                    }
                    if (index === 2 || index === 3) {
                      setSelectedCategory(Blood.category_id);
                      setSelectedNAme(Blood.category);
                      setHasSubCategory(Blood.has_sub_category);
                      setSubCat(findThyroid.sub_category_id);
                    }
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </a>
      <div className="icon-container">
        {images.map((_, index) => (
          <div
            key={index}
            style={{ cursor: 'pointer' }}
            onClick={() => setCurrentIndex(index)}
            className={`icon ${currentIndex === index ? 'active' : ''}`}
          />
        ))}
      </div>
    </div>
  );
};

export default AutoInfiniteSlider;
