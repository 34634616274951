/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components';

const MainWrapper = styled.div`
  width: 100%;
  background-color: white;
`;

const Wrapper = styled.div`
  width: 80%;
  margin: 7rem auto;
  color: #555555;

  @media (max-width: 765px) {
    width: 90%;
    margin: 5rem auto;
  }

  p {
    line-height: 1.5;
    font-size: 18px;
    font-weight: 400;
  }
  h1 {
    text-align: center;
    color: #d2a614;
  }
`;

const About = () => {
  return (
    <>
      <MainWrapper>
        <Wrapper>
          <h1>About Us</h1>
          <p>
            Our team has over a decade of experience leveraging genetics to improve fitness and
            performance of some of the world’s leading athletes. We have been researching the field
            of genetics and discovering the miracles of this science at Adam’s Genetics(
            <a
              style={{ color: '#d2a614', textDecoration: 'none' }}
              href="https://www.adamsgenetics.com/"
              target="_blank"
              rel="noopener noreferrer">
              www.adamsgenetics.com
            </a>
            ) for our clients from Sports, Bollywood, and Corporates.
            <br />
            <br />
            From helping an international sports star lose weight to supporting India’s leading
            business owner defeat a chronic stomach problem, we have used DNA Test to accurately
            solve the toughest wellness issues of our clients. These and many more such happy
            stories keep us going!
            <br />
            <br></br>
            When Covid-19 hit humanity in 2019, we at Adam’s Genetics started to think the best way
            to bring our wellness solutions to the world. We realised that ‘digital’ was the way to
            go for us. Our desire was to deliver our wellness solutions through a digital platform
            but without compromising the quality that we attained at Adam’s Genetics over the years
            and one that our clients always vouched for. That’s when we decided to launch unlock.fit
            that offers a deeply personalised wellness experience for anyone in the world.
            <br />
            <br />
            unlock.fit uses the most advanced DNA Testing to personalise nutrition and training for
            our clients. We are currently partnering with organisations and corporates to help them
            achieve the optimum wellness for their people. If you wish to explore how unlock.fit can
            help you and your organisation, please reach out to us at support@unlock.fit. We look
            forward to accompanying you in your wellness journey.
          </p>
        </Wrapper>
      </MainWrapper>
    </>
  );
};

export default About;
