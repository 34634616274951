import styled from 'styled-components/macro';

export const Model = styled.div`
  z-index: ${({ show }) => (show ? 100 : -1)};
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  top: 0rem;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* display: flex;
align-items: center;
justify-content: center; */
`;

export const ModelContainer = styled.div`
  /* background-color: red; */
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 1.5rem;
  margin-top: 1rem;
`;

export const Content = styled.div`
  position: relative;
  min-width: 20rem;
  width: auto;
  /* overflow: scroll; */
  z-index: 2;
  padding: 2rem;
  /* min-height: 12rem; */
  height: auto;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 60%;
  overflow: auto;
  Button {
    margin 0 0.5rem;
    background-color: #c59e2d;
    color: white;
    font-size: 20px;
    margin-top: 1rem;
    border-radius: 30px;
    background: #d2a614;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    /* padding: 12px; */
    padding: 0.5rem 2rem;
    border-radius: 50px;
    background: #d2a614;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
    @media (max-width: 800px) {
      font-size: 15px;
    }
    :hover {
      background: #d2a614;
      scale: 1.1;
      cursor: pointer;
    }
  }

  h2 {
    font-size: 1.7rem;
    margin-top: 1rem;
    color: #c59e2d;
    border-bottom: 1px solid grey;
    margin-bottom: 1rem;
  }

  form {
    width: 80%;
    font-size: 1rem;
    input {
      width: 100%;
      padding: 5px 10px;
    }
  }

  @media (max-width: 600px) {
    /* min-width: 12rem; */
    /* width: 90%; */
    /* height: 40%; */
  }

  .custom-input {
    border-radius: 5px;
    outline: none;
    padding: 11px 10px;
    margin: 5px 0px;
    font-size: 15px;
    color: #808080;
    border: 1px solid;
    font-family: 'Poppins', 'DM Sans', sans-serif;
  }
`;
