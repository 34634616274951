import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100:200;400;500&display=swap');

  body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins','DM Sans', sans-serif;
    }
    html {
    scroll-behavior: smooth;
  }

  h1{
      font-size: 3rem;
      margin: 0;
      color: #19191b;
      @media(max-width:800px){
        font-size: 2.3rem;
      }
      @media(max-width:600px){
        font-size: 1.9rem;
      }
  }
  h2{
      font-size: 2.2rem;
      margin: 0;
      color: #19191b;
  }

  h3 {
      font-size:1.3rem;
      margin: 0;
      color: #19191b;
  }

  h5{
    margin:0;
    color: #19191b;
  }

  p {
      font-size:1.5rem;
      line-height: 1.3;
      letter-spacing: 0.7px;
      padding:1.5rem 0;
      color: #19191b;
      @media(max-width:600px){
        font-size: .8rem;
      }
  }
  .navbar{
	background-color: white;
  @media(max-width:700px){
    background-color: white;
  }
  }
}

.navbar.colorChange{
background: #fff;
box-shadow: 8px -8px 16px rgba(0,0,0,0.3);
transition: all 0.3s ease-in;
}
`;

export default GlobalStyle;
