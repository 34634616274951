import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  /* background-color: #18c; */
  justify-content: center;
`;

export const MainWrapper = styled.div`
  display: flex;
  background: url('/assets/BlueBanner.png');
  background-size: cover;
  width: 100%;
`;

export const InnerWrapper = styled.div`
  /* background: url('./assets/Asset-9.png') no-repeat center; */
  background-size: cover;
  width: 100%;
  display: flex;
  width: 100%;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  color: white;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  padding: 2rem 0;

  @media (max-width: 450px) {
    width: 90%;
  }

  h1 {
    font-size: 3rem;
    margin: 0;
    color: white;
  }

  p {
    letter-spacing: 1px;
    font-size: 1.3rem;
    font-weight: 400;
    padding: 0rem 0;
    color: white;
    @media (max-width: 450px) {
      /* font-size: 20px; */
    }
  }

  @media (max-width: 1024px) {
    h1 {
      font-size: 2rem;
    }
    p {
      font-size: 1rem;
    }
  }
  @media (max-width: 800px) {
    h1 {
      font-size: 1.7rem;
    }
    p {
      font-size: 0.8rem;
    }
  }
  @media (max-width: 600px) {
    h1 {
      font-size: 1.5rem;
    }
    p {
      font-size: 20px;
      font-weight: 400;
    }
  }
  @media (max-width: 400px) {
    h1 {
      font-size: 22px;
    }
    p {
      /* font-size: 0.5rem; */
      font-size: 20px;
      width: 90%;
      font-weight: 400;
    }
  }
`;
