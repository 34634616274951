import styled from 'styled-components';

export const MainWrapper = styled.div`
  /* background-color: rgb(198, 156, 18); */
  border-top: 5px solid rgb(198, 156, 18);
  border-bottom: 5px solid rgb(198, 156, 18);
  /* border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 22rem;
  border-top-right-radius: 22rem;
  border-top-left-radius: 2rem; */
`;

export const Wrapper = styled.div`
  max-width: 95%;
  margin: auto;
  padding: 5rem 0rem 0 0;
  text-align: center;
  justify-content: center;
  /* background: url('/assets/benefit.png') center no-repeat; */
  background-size: cover;
  h1 {
    color: #19191b;
    width:100%;
  }
  @media (max-width: 768px) {
    padding-top: 5rem;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 1rem;
`;

export const Card = styled.div`
  width: 320px;
  padding-top: 1rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  margin-top: 1rem;
  p {
    color: #19191b;
    padding: 0;
    /* margin-bottom: 0.5rem; */
    /* padding: none; */
  }
  img {
    width: 56%;
  }

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    width: 350px;
    margin-top: 1rem;
  }
`;
