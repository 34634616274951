import React from 'react';
import { IntroSection } from './styles';

import { FaWhatsapp } from 'react-icons/fa';
import { HiPhone } from 'react-icons/hi';
import { HiOutlineMail } from 'react-icons/hi';
import { useEffect } from 'react';

const Contact = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, []);

  return (
    <IntroSection.Wrapper>
      <IntroSection.Contact>
        <h2>Contact Us</h2>
        <IntroSection.IconWrapper>
          <IntroSection.Icon>
            <a
              href="https://api.whatsapp.com/send/?phone=9818875990&text&type=phone_number&app_absent=0"
              target="_blank"
              rel="noopener noreferrer">
              <FaWhatsapp />
            </a>
          </IntroSection.Icon>
          <IntroSection.Icon>
            <a href="tel:+919818875990">
              <HiPhone />
            </a>
          </IntroSection.Icon>
          <IntroSection.Icon>
            <a href="mailto: support@unlock.fit">
              <HiOutlineMail />
            </a>
          </IntroSection.Icon>
        </IntroSection.IconWrapper>
      </IntroSection.Contact>
    </IntroSection.Wrapper>
  );
};

export default Contact;
